import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'

export default function ({open, setOpen, title, content, submitText, submit}) {

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    handleClose()
    submit()
  }

  return (
    <Dialog
      open = {open}
      onClose = {handleClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography type = 'body1'>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color = 'primary' onClick = {handleSubmit}>
          {submitText}
        </Button>
        <Button color = 'primary' onClick = {handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
