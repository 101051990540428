import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import StorageList from './StorageList';
import AdminList from './AdminList';
import MyTeam from './MyTeam';
import { useAuth } from "../../../context/auth";
import ListIcon from '@material-ui/icons/List';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import { Link } from 'react-router-dom';
import TutorialStep from '../../display/TutorialStep'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function MainList({ open, setOpen}) {
  const classes = useStyles();
  const { userCategory, team_id, tutorialStep, setTutorialStep } = useAuth();
  const listingRef = useRef()
  const inquiryRef = useRef()
  const profileRef = useRef()

  const advanceTutorial = () => {
    if (tutorialStep === 4 || tutorialStep === 6 || tutorialStep === 30)
      setTutorialStep(tutorialStep + 1)
  }

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItemLink button disabled = {tutorialStep !== 0} component={Link} to='/'>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemLink>
        <ListItemLink button disabled = {tutorialStep !== 0 && tutorialStep !== 4} onClick = {advanceTutorial} component={Link} to='/listing'>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Listings" ref = {listingRef}/>
        </ListItemLink>
        <ListItemLink button disabled = {tutorialStep !== 0 && tutorialStep !== 6} onClick = {advanceTutorial} component={Link} to='/inquiry'>
          <ListItemIcon>
            <BatteryUnknownIcon />
          </ListItemIcon>
          <ListItemText primary="Inquiries" ref = {inquiryRef}/>
        </ListItemLink>
        {(userCategory !== 'Viewer') && <StorageList open={open} setOpen={setOpen}/>}
        {(team_id !== null) && <MyTeam open={open} setOpen={setOpen}/>}
        {(userCategory === 'Admin') && <AdminList open={open} setOpen={setOpen}/>}
        <ListItemLink button disabled = {tutorialStep !== 0 && tutorialStep !== 30} onClick = {advanceTutorial} component={Link} to='/profile'>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" ref = {profileRef}/>
        </ListItemLink>
      </List>
      {tutorialStep === 4 &&
        <TutorialStep text = 'click here' refPosition = {listingRef.current}/>
      }
      {tutorialStep === 6 &&
        <TutorialStep text = 'click here' refPosition = {inquiryRef.current}/>
      }
      {tutorialStep === 30 &&
        <TutorialStep text = 'click here' refPosition = {profileRef.current}/>
      }
    </div>

  );
}
