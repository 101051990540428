import React, { useState } from 'react';
import { useAuth } from "../../context/auth";
import ErrorBoundary from '../error/ErrorBoundary'
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MyPlacedBidsTable from './MyPlacedBidsTable';
import AllListingsTable from '../listing/AllListingsTable';
import AllInquiriesTable from '../inquiry/AllInquiriesTable';
import MyPlacedInquiryBidsTable from '../inquiry/MyPlacedInquiryBidsTable';

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: theme.palette.secondary.secondary,

    },
    tabSelect: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        textColor: theme.palette.secondary.main,
      },
    },
    paper: {
      'marginTop':theme.spacing(15),
    }
}));


function TabPanel(props) {
  const { children, value, index, columns, receivedBids, userCategory, classes, setReceivedLoading, setMessage, setFetchReceivedBids, received_loading, fetchReceivedBids, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
      </div>
  );
}

export default function Bids(){
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const { userCategory } = useAuth();
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };


  return(
    <div>
      <ErrorBoundary>
        <Paper>
          <Tabs
            indicatorColor = 'primary'
            textColor = 'primary'
            variant = 'fullWidth'
            onChange = {handleChange}
            value = {value}
            className = {classes.tab}
            >
              <Tab label = 'Your Bids on Listings' id = {0} className = {value!==0 ? classes.tabSelect : classes.tab}/>
              <Tab label = 'Available Listings' id = {1} className = {value!==1 ? classes.tabSelect : classes.tab}/>
            </Tabs>
          </Paper>
          <TabPanel value={value} index={0}>
            <MyPlacedBidsTable />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AllListingsTable/>
          </TabPanel>

          {(userCategory === 'Admin' || userCategory === 'Terminal') &&
          <div>
            <Paper className = {classes.paper}>
              <Tabs
                indicatorColor = 'primary'
                textColor = 'primary'
                variant = 'fullWidth'
                onChange = {handleChange2}
                value = {value2}
                className = {classes.tab}
                >
                  <Tab label = 'Your Bids on Inquiries' id = {0} className = {value2!==0 ? classes.tabSelect : classes.tab}/>
                  <Tab label = 'Available Inquiries' id = {1} className = {value2!==1 ? classes.tabSelect : classes.tab}/>
                </Tabs>
              </Paper>
              <TabPanel value={value2} index={0}>
                <MyPlacedInquiryBidsTable/>
              </TabPanel>
              <TabPanel value={value2} index={1}>
                <AllInquiriesTable/>
              </TabPanel>
            </div>
          }
        </ErrorBoundary>
      </div>
    )
}
