import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MTableToolbar from 'material-table/dist/components/m-table-toolbar'
import MaterialTable from 'material-table';
import TextField from "@material-ui/core/TextField"
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh'
import InfoIcon from '@material-ui/icons/Info'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import RenewalForm from './RenewalForm'
import InfoDialog from '../display/InfoDialog'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

const useStyles = makeStyles((theme) => ({
  userInfo: {
    margin: theme.spacing(3),
    width: '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 10,
  },
}));

export default function TraderBooking(){
  const classes = useStyles();
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [bounds, setBounds] = useState({})
  const [type, setType] = useState('')
  const [id, setId] = useState(0)
  const [info, setInfo] = useState(false)
  const [toggle, fetchData] = useState(false)
  const [renew, setRenew] = useState(false)
  const [hideClosed, setHideClosed] = useState(false)

  useEffect(() => {
    fetch(`/api/booking/trader?hideClosed=${hideClosed}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch bookings on your offers.'})
      console.error('Error:', error);
    })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[toggle, setMessage, hideClosed]);

  const handleClose = () => {
    setInfo(false)
    setType('')
    setId(0)
  }

  const switchChange = (event) => {
    setHideClosed(event.target.checked)
  }

  return (
    <div>
      <MaterialTable
        title = "Bookings on Your Offers"
        columns = {[
          {
            'title' : 'Status',
            'field' : 'status'
          },
          {
            'title' : 'Location',
            'field' : 'address'
          },
          {
            'title' : 'Price (USD/BBL)',
            'field' : 'price',
            'bounds': bounds.price,
            'customFilterAndSearch': rangeFilter('price'),
            'step': .01
          },
          {
            'title' : 'Product',
            'field' : 'product'
          },
          {
            'title' : 'Volume (MBBL)',
            'field' : 'volume',
            'bounds': bounds.volume,
            'customFilterAndSearch': rangeFilter('volume'),
            'step': 1
          },
          {
            'title' : 'Date Booked',
            'field' : 'date_booked',
            'type' : 'date',
            'customFilterAndSearch' : dateRangeFilter('date_booked'),
            customSort: (a,b) => {
              var date1 = new Date(a.date_booked)
              var date2 = new Date(b.date_booked)

              return date1 - date2
            },
            render: rowData => <p>{(new Date(rowData.date_booked)).toLocaleDateString()}</p>
          },
          {
            'title' : 'Start Date',
            'field' : 'start_date',
            'type' : 'date',
            'customFilterAndSearch' : dateRangeFilter('start_date'),
            customSort: (a,b) => {
              var date1 = new Date(a.start_date)
              var date2 = new Date(b.start_date)

              return date1 - date2
            },
            render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
          },
          {
            'title' : 'Term (Months)',
            'field' : 'term',
            'type': 'numeric',
            'bounds': bounds.term,
            'customFilterAndSearch': rangeFilter('term')
          }
        ]}
        data = {data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        actions = {[
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            'isFreeAction': true,
            onClick: (event) => fetchData(!toggle)
          },
          {
            icon: () => <InfoIcon color = 'primary'/>,
            tooltip: 'More Info',
            onClick: (event, rowData) => {
              setType(rowData.type)
              if (rowData.type === 'renewal'){
                setId(rowData.booking_id)
              } else{
                setId(rowData.bid_id)
              }
              setInfo(true)
            }
          },
          {
            icon: () => <AutorenewIcon color = 'primary'/>,
            'tooltip': 'Renew Booking',
            onClick: (event, rowData) => {
              setType(rowData.type)
              setId(rowData.booking_id)
              setRenew(true)
            }
          }
        ]}
        detailPanel = {[
          {tooltip: 'Contact Info',
          render : rowData => {
            return(
              <Grid container spacing = {2} className = {classes.userInfo}>
                <Grid item xs>
                  <TextField
                    id="First_Name"
                    label="First Name"
                    value = {rowData.terminal_first_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Last_Name"
                    label="Last Name"
                    value = {rowData.terminal_last_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Company"
                    label="Company"
                    value = {rowData.terminal_company}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item sm>
                  <TextField
                    id="email"
                    label="Email"
                    value = {rowData.terminal_email}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Office_Phone"
                    label="Office Phone"
                    value = {rowData.terminal_office_phone}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Cell_Phone"
                    label="Cell Phone"
                    value = {rowData.terminal_cell_phone}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            )
          }}]
        }
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        options={{
          filtering: true,
          draggable: false,
        }}
        components={
          {
            FilterRow: props => <CustomFilter {...props} />,
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <FormControlLabel
                  control = {
                    <Switch
                      checked = {hideClosed}
                      onChange = {switchChange}
                      color = 'primary'
                      size = 'small'
                    />
                  }
                  label = 'Hide closed'
                  labelPlacement = 'start'
                />
              </div>
            )
          }
        }
      />
      <InfoDialog
        open = {info}
        onClose = {handleClose}
        type = {type}
        id = {id}
      />
      <RenewalForm open = {renew} setOpen = {setRenew} id = {id} type = {type}/>
    </div>

  )
}
