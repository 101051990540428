import React, { useState } from 'react';
import { DateRangePicker } from 'materialui-daterange-picker';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateRangeIcon from '@material-ui/icons/DateRange';

export default function DateRangeFilter({ value, onChange, definedRanges }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  }

  const toggle = () => setOpen(!open);

  const today = new Date();

  if (!definedRanges) {
    definedRanges = [
      {
        label: 'Available Now',
        startDate: new Date(2015, 0, 1),
        endDate: today
      },
      {
        label: 'This Month',
        startDate: new Date(today.getFullYear(), today.getMonth(), 1),
        endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0)
      },
      {
        label: 'Next Month',
        startDate: new Date(today.getFullYear(), today.getMonth() + 1, 1),
        endDate: new Date(today.getFullYear(), today.getMonth() + 2, 0)
      },
      {
        label: 'This Quarter',
        startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4) * 3, 1),
        endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 0)
      },
      {
        label: 'Next Quarter',
        startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 1),
        endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 2) * 3, 0)
      },
      {
        label: 'This Year',
        startDate: new Date(today.getFullYear(), 0, 1),
        endDate: new Date(today.getFullYear(), 12, 0)
      },
      {
        label: 'Next Year',
        startDate: new Date(today.getFullYear() + 1, 0, 1),
        endDate: new Date(today.getFullYear() + 1, 12, 0)
      },
      {
        label: 'Reset',
        startDate: null,
        endDate: null
      },
    ]
  }

  return (
    <div>
      <TextField
        value={value.startDate ? `${value.startDate.toLocaleDateString()} - ${value.endDate.toLocaleDateString()}` : ''}
        onClick={handleClick}
        InputProps={{startAdornment: (<InputAdornment position='start'><DateRangeIcon/></InputAdornment>)}}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        >
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={range => onChange(range)}
            definedRanges={definedRanges}
          />
        </Popover>
    </div>
  );
}
