import React, { useState } from 'react';
import EditableTable from '../inputs/EditableTable';
import TextField from '@material-ui/core/TextField';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BlockIcon from '@material-ui/icons/Block'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import { useAuth } from "../../context/auth";
import { useMessage } from "../../context/message";
import ReportsTable from '../display/ReportsTable';
import AlertDialog from '../inputs/AlertDialog'
import BidAskForm from './BidAskForm';
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

export default function BidAskTable() {
  const { setMessage } = useMessage();
  const { setUsername, setUser_id, setTeam_id, auth } = useAuth();
  const [toggle, fetchData] = useState(false)
  //add user dialog
  const [open, setOpen] = useState(false)
  const [bid_ask_id, setBid_ask_id] = useState(0)
  const [deleteDialog, setDelete] = useState(false)

  const columns = [
    {
      title: 'ID',
      field: 'bid_ask_id',
      editable: 'never'
    },
    {
      title: 'Region',
      field: 'region',
    },
    {
      title: 'Product Category',
      field: 'product_category',
    },
    {
      title: 'Price',
      field: 'price',
    },
    {
      title: 'Volume',
      field: 'volume',
    },
    {
      title: 'Term',
      field: 'term',
    },
    {
      title: 'Bid/Ask',
      field: 'bid_ask',
    },
    {
      title: 'Start Date',
      field: 'start_date',
      customSort: (a,b) => {
        var date1 = new Date(a.start_date)
        var date2 = new Date(b.start_date)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
    },
    {
      title: 'Date Placed',
      field: 'date_placed',
      customSort: (a,b) => {
        var date1 = new Date(a.date_placed)
        var date2 = new Date(b.date_placed)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.date_placed)).toLocaleDateString()}</p>
    }
  ];

  const deleteBid = () => {
    fetch(`/api/bid_ask/${bid_ask_id}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to delete bid'})
      console.error('Error:', error);
    })
    setBid_ask_id(0)
  }

  return (
    <div>
      <BidAskForm
        open = {open}
        setOpen = {setOpen}
        bid_ask_id = {bid_ask_id}
        setBid_ask_id = {setBid_ask_id}
        fetchData = {fetchData}
        toggle = {toggle}
      />
      <AlertDialog
        open = {deleteDialog}
        setOpen = {setDelete}
        title = 'Delete Bid'
        content = 'Are you sure you want to delete this bid?'
        submitText = 'Delete'
        submit = {deleteBid}
      />
      <EditableTable
        title='Bid/Ask'
        table='bid_ask'
        toggle = {toggle}
        fetchData = {fetchData}
        columns={columns}
        ownership='all'
        actions={[
          {
            icon: () => <AddBoxIcon color = 'primary'/>,
            tooltip: 'Add bid/ask',
            isFreeAction: true,
            onClick: () => {
              setOpen(true)
            }
          },
          {
            icon: () => <EditIcon color = 'primary'/>,
            tooltip: 'Edit bid/ask',
            onClick: (e, rowData) => {
              setBid_ask_id(rowData.bid_ask_id)
              setOpen(true)
            }
          },
          {
            icon: () => <DeleteOutlineIcon color = 'primary'/>,
            tooltip: 'Delete Bid',
            onClick: (e, rowData) => {
              setBid_ask_id(rowData.bid_ask_id)
              setDelete(true)
            }
          }
        ]}
      />
    </div>

  );
}
