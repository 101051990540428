import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 210,
  },
}));

export default function AccessMode({ index, mode, access_modes, setAccess_modes }) {
  const classes = useStyles();

  const updateField = e => {
    let array = [...access_modes];
    array[index] = {...array[index], [e.target.name]: e.target.value};
    setAccess_modes(array);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <TextField
          required
          className={classes.formControl}
          name='mode_name'
          label='Access Mode Name'
          variant='outlined'
          value={mode.mode_name}
          onChange={updateField}
          />
      </Grid>
      <Grid item>
        <FormControl required variant='outlined' className={classes.formControl}>
          <InputLabel>Inbound / Outbound</InputLabel>
          <Select
            name='inbound_outbound'
            value={mode.inbound_outbound}
            onChange={updateField}
            label='Inbound / Outbound'
          >
            <MenuItem value={'inbound'}>Inbound</MenuItem>
            <MenuItem value={'outbound'}>Outbound</MenuItem>
            <MenuItem value={'both'}>Both</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl required variant='outlined' className={classes.formControl}>
          <InputLabel>Type</InputLabel>
          <Select
            name='type'
            value={mode.type}
            onChange={updateField}
            label='Type'
          >
            <MenuItem value={'Barge'}>Barge</MenuItem>
            <MenuItem value={'Pipeline'}>Pipeline</MenuItem>
            <MenuItem value={'Rail'}>Rail</MenuItem>
            <MenuItem value={'Truck'}>Truck</MenuItem>
            <MenuItem value={'Vessel'}>Vessel</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          required
          className={classes.formControl}
          name='pump_over_rate'
          label='Transfer Fee'
          type='number'
          variant='outlined'
          value={mode.pump_over_rate}
          onChange={updateField}
          />
      </Grid>
      {(mode.type === 'Pipeline') &&
        <Grid item>
          <TextField
            className={classes.formControl}
            name='connection_name'
            label='Connection'
            variant='outlined'
            value={mode.connection_name}
            onChange={updateField}
            />
        </Grid>}
      {(mode.type === 'Vessel' || mode.type === 'Barge') &&
        <Grid item>
          <TextField
            className={classes.formControl}
            name='dock_draft'
            label='Dock Draft (ft)'
            type='number'
            variant='outlined'
            value={mode.dock_draft}
            onChange={updateField}
            />
        </Grid>}
        {(mode.type === 'Vessel' || mode.type === 'Barge') &&
          <Grid item >
            <TextField
              className={classes.formControl}
              name='length_overall'
              label='LOA (ft)'
              type='number'
              variant='outlined'
              value={mode.length_overall}
              onChange={updateField}
              />
          </Grid>}
        {(mode.type === 'Rail') &&
        <Grid item>
          <FormControl required variant='outlined' className={classes.formControl}>
            <InputLabel>Class One Rail</InputLabel>
            <Select
              name='class_one_rail'
              value={mode.class_one_rail}
              onChange={updateField}
              label='Type'
            >
              <MenuItem value={'CN'}>CN</MenuItem>
              <MenuItem value={'CP'}>CP</MenuItem>
              <MenuItem value={'CSX'}>CSX</MenuItem>
              <MenuItem value={'NS'}>NS</MenuItem>
              <MenuItem value={'UP'}>UP</MenuItem>
              <MenuItem value={'BNSF'}>BNSF</MenuItem>
              <MenuItem value={'KCS'}>KCS</MenuItem>
            </Select>
          </FormControl>
        </Grid>}
        {(mode.type === 'Rail') &&
          <Grid item >
            <TextField
              className={classes.formControl}
              name='rail_spots'
              label='Rail Spots'
              variant='outlined'
              type='number'
              value={mode.rail_spots}
              onChange={updateField}
              />
          </Grid>}
    </Grid>
  );
};
