import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FloatAnchor from 'react-float-anchor'
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp'

export default function TutorialStep({text, refPosition}) {
  return (
    <div>
      {refPosition &&
          <FloatAnchor
            anchor = {refPosition || null}
            float = {
              <div>
                <Paper style = {{position: 'relative', top: 20, backgroundColor: '#fc6c05'}}>
                  <Typography style = {{padding: 3}}>
                    {text}
                  </Typography>
                </Paper>
                <ArrowDropDownSharpIcon style = {{position: 'relative', float: 'right', top: 10,'zIndex':5, color: '#fc6c05'}}/>
              </div>
            }
            zIndex = {100000}
          />
      }

    </div>
  )
}
