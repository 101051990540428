import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer
} from 'recharts'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import SimpleSelect from '../inputs/SimpleSelect'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DateRangeFilter from '../inputs/DateRangeFilter';
import { useMessage } from '../../context/message';
import { useAuth } from '../../context/auth';
import { useField } from '../../context/context'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const today = new Date();

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 630
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filter: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}));

const defaultData = [
  {date: 1609459200000, region1: 100, region2: 70},
  {date: 1612137600000, region1: 100, region2: 70},
  {date: 1614556800000, region1: 100, region2: 70},
  {date: 1617235200000, region1: 100, region2: 80},
  {date: 1619827200000, region1: 50, region2: 80},
  {date: 1622505600000, region1: 50, region2: 40},
  {date: 1625097600000, region1: 50, region2: 40},
  {date: 1627776000000, region1: 40, region2: 40},
  {date: 1630454400000, region1: 40, region2: 70},
  {date: 1633046400000, region1: 90, region2: 70},
  {date: 1635724800000, region1: 90, region2: 90},
  {date: 1638316800000, region1: 120, region2: 100}
]

export default function ComparisonChart(){
  const theme = useTheme()
  const classes = useStyles()
  const { regions, product_categories, fetchFields, setFetchFields } = useField()
  const [product_category, setProduct_category] = useState('')
  const [region1, setRegion1] = useState('')
  const [region2, setRegion2] = useState('')
  const [dateRange, setDateRange] = useState({startDate: new Date('Sun Dec 01 2020 14:43:47 GMT-0500 (Eastern Standard Time)'), endDate: new Date('Mon Dec 01 2021 14:43:47 GMT-0500 (Eastern Standard Time)')});
  const [type, setType] = useState('available_capacity')
  const [data, setData] = useState(defaultData)
  const { setMessage } = useMessage();
  const { auth, dataSub } = useAuth();
  const [regions_list, setRegions_list] = useState([])
  const [product_categories_list, setProduct_categories_list] = useState([])

  useEffect(() => {
    if (regions){
      setRegions_list(regions.map(function(item) {return {value: item.region, label: item.region}}))
    } else {
      setFetchFields(!fetchFields)
    }
    if (product_categories){
      setProduct_categories_list(product_categories.map(function(item) {return {value: item.product_category, label: item.product_category}}))
    } else {
      setFetchFields(!fetchFields)
    }
    if (regions && product_categories && dataSub === 0) {
      setRegion1('PADD 1B')
      setRegion2('PADD 3')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[regions, product_categories])

  useEffect(() => {
    fetch(`/api/comparison?region1=${region1}&region2=${region2}&product_category=${product_category}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&type=${type}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if(!response.message)
        setData(response);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch volume data.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[region1, region2, product_category, type, dateRange])

  const available_capacity = () => {
    setType('available_capacity')
  }

  const open_interest = () => {
    setType('open_interest')
  }

  return (
    <Paper className = {classes.root}>
      {/*Header */}
      <Grid container justify = 'center'>
        <Typography variant = 'button' style = {{fontSize: 16, marginTop: 15}}>
          Volume Comparisons by Region
        </Typography>
      </Grid>
      {/*selects and filters*/}
      <Grid container justify = 'center'>
        <SimpleSelect value={region1} setValue={setRegion1} label='Region 1' options={regions_list} />
        <SimpleSelect value={region2} setValue={setRegion2} label='Region 2' options={regions_list} />
        <SimpleSelect value={product_category} setValue={setProduct_category} label='Product Category' options={product_categories_list} />
        <ButtonGroup color = 'secondary' style = {{height: 55, marginLeft: 8, marginTop: 8, width: 300}}>
          <Button id='available_capacity' onClick = {available_capacity} style = {type === 'available_capacity' ? {backgroundColor:theme.palette.primary.main} : {color:theme.palette.secondary.secondary}}>
            Available Capacity
          </Button>
          <Button onClick = {open_interest} style = {type === 'open_interest' ? {backgroundColor:theme.palette.primary.main} : {color:theme.palette.secondary.secondary}}>
            Open Interest
          </Button>
        </ButtonGroup>
        <FormControlLabel
          className={classes.filter}
          control={<DateRangeFilter
            value = {dateRange}
            onChange = {setDateRange}
            definedRanges = {[
              {
                label: 'YTD',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: today
              },
              {
                label: 'This Month',
                startDate: new Date(today.getFullYear(), today.getMonth(), 1),
                endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0)
              },
              {
                label: 'Next Month',
                startDate: new Date(today.getFullYear(), today.getMonth() + 1, 1),
                endDate: new Date(today.getFullYear(), today.getMonth() + 2, 0)
              },
              {
                label: 'This Quarter',
                startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4) * 3, 1),
                endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 0)
              },
              {
                label: 'Next Quarter',
                startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 1),
                endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 2) * 3, 0)
              },
              {
                label: 'This Year',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: new Date(today.getFullYear(), 12, 0)
              },
              {
                label: 'Next Year',
                startDate: new Date(today.getFullYear() + 1, 0, 1),
                endDate: new Date(today.getFullYear() + 1, 12, 0)
              },
              {
                label: 'Reset',
                startDate: addDays(today, -183),
                endDate: addDays(today, 182)
              },
            ]}
          />}
        />
      </Grid>
      <ResponsiveContainer
        height = '72%'
      >
        <BarChart
          data = {data}
          margin={{
            top: 16,
            right: 16,
            bottom: 16,
            left: 24,
          }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={(unixTime) => moment(unixTime).format('MMM YY')}
            stroke={theme.palette.text.secondary}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
          >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}

            >
              {type==='available_capacity' ? 'Available Capacity (MBBL)': 'Open Interest (MBBL)'}
            </Label>
          </YAxis>
          <Tooltip cursor={false}/>
          <Legend />
          <Bar name={region1 ? region1 : 'None'} dataKey = 'region1' fill={theme.palette.primary.main}/>
          <Bar name={region2 ? region2: 'None'} dataKey = 'region2' fill = {'#03fc77'}/>
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  )
}
