import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TermsText from './TermsText'
import PrivacyText from './PrivacyText'

export default function Terms({open, setOpen, agree}){
  const [openPrivacy, setOpenPrivacy] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleClosePrivacy = () => {
    setOpenPrivacy(false)
  }

  return (
    <div>
      <Dialog open = {openPrivacy} onClose = {handleClosePrivacy}>
        <DialogTitle>
          <IconButton aria-label="close"
            style = {{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
            onClick = {handleClosePrivacy}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PrivacyText child = {true} close = {handleClosePrivacy}/>
        </DialogContent>
        <DialogActions>
          <Grid container justify = 'center' direction = 'row'>
            <Grid item>
              <Button style = {{marginRight: 10}} color = 'primary' variant = 'contained' onClick = {handleClosePrivacy}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open = {open} onClose = {handleClose}>
        <DialogTitle>
          <IconButton aria-label="close"
            style = {{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
            onClick = {handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TermsText setToggle = {setOpenPrivacy}/>

        </DialogContent>
        <DialogActions>
          <Grid container justify = 'center' direction = 'row'>
            <Grid item>
              <Button style = {{marginRight: 10}} color = 'primary' variant = 'contained' onClick = {handleClose}>
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button color = 'primary' variant = 'contained' onClick = {agree}>
                Agree
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>

  )
}
