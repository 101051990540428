import React, { useState, useEffect } from 'react'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import GoogleMaps from '../inputs/GoogleMaps'
import { useField } from '../../context/context'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AccessMode from '../inputs/AccessMode'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import BidFileSelect from '../inputs/BidFileSelect'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: 300
  },
  title: {
    marginLeft: 5,
    marginTop: 5,
    display: 'flex',
    'flex-direction': 'row'
  },
  attach: {
    height: 30,
    width: 30,
    top: -5,
    left: 5,
  },
  label: {
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(1.5)
  },
  heading: {
    fontSize: 16
  },
  select: {
    width: 300
  },
  button: {
    margin: theme.spacing(2),
  },
  remove: {
    marginTop: theme.spacing(2)
  },
  textfield: {
    width: 300
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  }
}));

const datePlusOneYear = (date) => {
  let temp = new Date(date)
  temp.setMonth(temp.getMonth() + 12)
  return temp
}

export default function AddInquiry({open, setOpen, toggle, fetchData}) {
  const classes = useStyles()
  const { auth } = useAuth()
  const { setMessage } = useMessage()
  const { products } = useField()
  const [loading, setLoading] = useState(false)
  const [ product_list, setProduct_list] = useState([])
  const [access_modes, setAccess_modes] = useState([])
  const [location, setLocation] = useState('')
  const [expiration, setExpiration] = useState(true)
  const [inquiry, setInquiry] = useState({
      bid_price: '',
      comment: '',
      formatted_address: {},
      max_volume: '',
      min_volume: '',
      product: '',
      radius: '',
      start_date: Date(0),
      expiration: datePlusOneYear(Date(0)),
      term: '',
      'name': '',
      'file': ''
  })
  //file attach
  const [fileAttach, setFileAttach] = useState(false)
  const [byteArrays, setByteArrays] = useState([])
  const [previewURLs, setPreviewURLs] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [fileToggle, setFileToggle] = useState(false)

  const submit = () => {
    if(!loading){
      setLoading(true)
      fetch(`/api/inquiry`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...inquiry,
          access_modes: access_modes,
          formatted_address: location
        })
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
          fetchData(!toggle)
        }
        setMessage(response.message);
        setLoading(false)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to create inquiry'});
        setLoading(false)
        console.error('Error:', error);
      })
    }

  }

  useEffect(() => {
    let temp = {}
    temp = products.map(item => item.product)
    temp.push('Empty')
    setProduct_list(temp);
  }, [products])

  const handleClose = () => {
    setExpiration(true)
    setAccess_modes([])
    setLocation('')
    setInquiry({
      bid_price: '',
      comment: '',
      formatted_address: {},
      max_volume: '',
      min_volume: '',
      product: '',
      radius: '',
      start_date: Date(0),
      expiration: datePlusOneYear(Date(0)),
      term: '',
      'name': '',
      'file': '',
    })
    setByteArrays([])
    setPreviewURLs([])
    setFileNames([])
    setOpen(false)
  }

  const updateField = e => {
    setInquiry({
      ...inquiry,
      [e.target.name]: e.target.value
    });
  };

  const chipDelete = (index) => {
    fileNames.splice(index,1)
    byteArrays.splice(index,1)
    previewURLs.splice(index,1)
    setFileToggle(!fileToggle)
  }

  const attach = () => {
    setInquiry({
      ...inquiry,
      'name': fileNames,
      'file': byteArrays,
    })
  }

  const addMode = () => {
    let mode = {
      mode_name:'',
      inbound_outbound:'',
      type:'',
      pump_over_rate:'',
      dock_draft:'',
      length_overall:'',
      class_one_rail:'',
      rail_spots:''
    }
    setAccess_modes([...access_modes, mode])
  };

  const removeMode = (index) => {
    let array = [...access_modes];
    array.splice(index, 1);
    setAccess_modes(array);
  };

  return(
    <div>
      <BidFileSelect
        open = {fileAttach}
        setOpen = {setFileAttach}
        byteArrays = {byteArrays} setByteArrays = {setByteArrays}
        previewURLs = {previewURLs} setPreviewURLs = {setPreviewURLs}
        fileNames = {fileNames} setFileNames = {setFileNames}
        attach = {attach}
      />
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='lg'
      >
        <DialogTitle>
          <Grid container spacing = {2} className = {classes.title}>
              <Typography>
                Add Inquiry
              </Typography>
              <Tooltip title='Attach Files'>
                <IconButton
                  onClick = {() => setFileAttach(true)}
                  className = {classes.attach}
                >
                  <AttachFileIcon color = 'primary'/>
                </IconButton>
              </Tooltip>
              <Grid item xs = {12}>
                {fileNames.length > 0 && fileNames.map((name, index) =>
                  <Chip color='primary' key = {index} label={name} onDelete={() => chipDelete(index)} style={{margin:2.5}}/>
                )}
              </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item className={classes.root}>
              <GoogleMaps
                location={location}
                setLocation={setLocation}
              />
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                className={classes.textfield}
                required
                name = "radius"
                label = "Radius (mi)"
                value =  {inquiry.radius}
                onChange={updateField}
                variant="outlined"
                type='number'
              />
            </Grid>
            <Grid item className={classes.root}>
              <Autocomplete
                options = {product_list}
                onChange={(e, input) => setInquiry({...inquiry, product: input})}
                renderInput={(params) => <TextField {...params} value={inquiry.product} variant = 'outlined' label = 'Product'/>}
              />
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'bid_price'
                label = 'Bid Price (USD/BBL)'
                value =  {inquiry.bid_price}
                onChange={updateField}
                variant="outlined"
                type = 'number'
              />
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'min_volume'
                label = 'Min Volume (MBBL)'
                value =  {inquiry.min_volume}
                onChange={updateField}
                variant="outlined"
                type = 'number'
              />
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'max_volume'
                label = 'Max Volume (MBBL)'
                value =  {inquiry.max_volume}
                onChange={updateField}
                variant="outlined"
                type = 'number'
              />
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'term'
                label = 'Term (Months)'
                value =  {inquiry.term}
                onChange={updateField}
                variant="outlined"
                type = 'number'
              />
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className = {classes.root}
                format='MM/dd/yyyy'
                margin = "normal"
                label = "Start Date"
                name = "start_date"
                value = {inquiry.start_date}
                onChange = {(long, short) => {
                  if (expiration){
                    setInquiry({...inquiry, start_date: short, expiration: datePlusOneYear(short)})
                  } else {
                    setInquiry({...inquiry, start_date: short})
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant = 'outlined'
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className = {classes.root}
                format = 'MM/dd/yyyy'
                margin = 'normal'
                label = 'Expiration Date'
                name = 'expiration'
                value = {inquiry.expiration}
                onChange = {(long, short) => {
                  setInquiry({...inquiry, expiration: short})
                  setExpiration(false)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant = 'outlined'
              />
            </MuiPickersUtilsProvider>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'comment'
                label = 'Comment'
                value =  {inquiry.comment}
                onChange={updateField}
                variant="outlined"
              />
            </Grid>
          </Grid>
            {/*Access Modes*/}
            <Typography style = {{marginTop: 10, marginBottom: 10}}>
              Access Modes
            </Typography>
            <Grid container>
                  <IconButton onClick={addMode}>
                    <AddIcon />
                  </IconButton>
                  {access_modes.map((mode, index) => (
                    <Grid container key = {index}>
                      <Grid item className = {classes.remove}>
                        <IconButton onClick={() => removeMode(index)}>
                          <RemoveIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs>
                        <AccessMode
                          index={index}
                          mode={mode}
                          access_modes={access_modes}
                          setAccess_modes={setAccess_modes}
                        />
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
        </DialogContent>
        <DialogActions>
          <div className = {classes.wrapper}>
            <Button onClick={submit} color="primary">
              Add
            </Button>
            {loading &&
              <CircularProgress size={32} className = {classes.loading}/>
            }
          </div>
          <Button onClick = {handleClose} color = 'primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
