import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AccessModeTable from '../display/AccessModeTable';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import FileViewer from '../display/FileViewer'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import AlertDialog from '../inputs/AlertDialog'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    marginTop: 20,
    marginBottom:10,
  },
  attachments: {
    top: 20,
    height: 30,
    width: 30
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 20,
    marginLeft: 20,
    marginRight:10,

    color: theme.palette.primary.main
  },
  reason: {
    marginTop: 20,
    marginLeft: 40,
    marginBottom:10,
  },
  text: {
    marginLeft : 20,
    marginTop : 20,
  },
  multiline: {
    marginLeft : 20,
    marginTop : 20,
    width : '77%'
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  },
}));

export default function InquiryBid({id, hideStatus, disable}){
  const [fetchPlacedBids,setFetchPlacedBids] = useState(false);
  const classes = useStyles();
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState({reason:''});
  const [rejectBid, setRejectBid] = useState(false);
  const {setMessage} = useMessage();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false)
  //AlertDialog for accept_bid
  const [open, setOpen] = useState(false)
  //file viewer
  const [viewFile, setViewFile] = useState(false)
  const [file_id, setFileId] = useState(0)
  const [type, setType] = useState('bid')

  const [bidData, setBidData] = useState({
    //Bid
    'inquiry_bids_id': '',
    'volume': '',
    'date_available': '',
    'storage_term': '',
    'ask_price': '',
    'bid_comment': '',
    //Inquiry
    'inquiry_id': '',
    'inquiry_formatted_address': '',
    'radius': '',
    'region': '',
    'bid_price': '',
    'product': '',
    'min_volume': '',
    'max_volume': '',
    'term': '',
    'start_date': '',
    'comment': '',
    //Storage
    'locality': '',
    'administrative_area_level_1': '',
    'country': '',
    'total_capacity': '',
    'product_category': '',
    'tank_type': '',
    'tank_features': '',
    'tank_material': '',
    'heel_product': '',
    'heel_requirement': '',
    'blending_capability': '',
    'heating_capability': '',
    'commingled': '',
    'number_of_tanks': ''
  });

  useEffect(() => {
      fetch(`/api/inquiry_bids/${id}`, {
        method: 'GET',
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        }
      })
      .then((response) => auth(response))
      .then((response) => {
        if (response.data){
          setStatus(response.data['status'])
          if(!response.data['comment']){
            response.data['comment'] = ''
          }
          if(!response.data['bid_comment']){
            response.data['bid_comment'] = ''
          }
          setBidData(response.data)
        }
        else
          setStatus('Does not exist!')
      })
      .catch((error) => {
        setMessage({'type': 'error', 'text': 'Error! Unable to fetch bid information on inquiry.'})
        console.error('Error:', error);
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fetchPlacedBids, id, setMessage]);

    const accept = () => {
      setOpen(true)
    }

    const accept_bid = () => {
        setOpen(false)
        fetch(`/api/inquiry_booking/${id}`,{
          method: 'POST',
          headers: {
            'x-access-token': sessionStorage.getItem('token')
          }
        })
        .then((response) => auth(response))
        .then(data => {
          setMessage(data.message);
          setFetchPlacedBids(!fetchPlacedBids)

        })
        .catch((error) => {
          setMessage({'type': 'error', 'text': 'Error! Unable to accept bid on inquiry.'})
          console.error('Error:', error)
        });
    }

    const reject_bid = () => {
      setRejectBid(true)
    }

    const handleClose = () => {
      setRejectBid(false)
      setReason({reason:''})
    }

    const handleSubmit = () => {
      if (!loading) {
        setLoading(true)
        fetch(`/api/inquiry_bids/${id}/reject`,{
          method: 'DELETE',
          headers: {
            'x-access-token': sessionStorage.getItem('token'),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(reason),
        })
        .then((response) => auth(response))
        .then(data => {
          setMessage(data.message);
          setFetchPlacedBids(!fetchPlacedBids)
          setLoading(false)
        })
        .catch((error) => {
          setMessage({'type': 'error', 'text': 'Error! Unable to reject bid on inquiry.'})
          setLoading(false)
          console.error('Error:', error)
        });
        handleClose()
      }
    }

  return(
    <div>
      <AlertDialog
        open = {open}
        setOpen = {setOpen}
        title = 'Accept Bid'
        content = 'Are you sure you want to accept this bid?'
        submitText = 'Accept'
        submit = {accept_bid}
      />
      <FileViewer
        open = {viewFile}
        setOpen = {setViewFile}
        id = {file_id}
        type = {type}
      />
    <Dialog
    open = {rejectBid}
    onClose = {handleClose}
    maxWidth = 'sm'
    fullWidth = {true}
    >
      <DialogTitle> Reject Bid </DialogTitle>
        <DialogContent>
          <Typography>
            Reason for rejection
          </Typography>
          <br/>
            <TextField
              fullWidth
              required
              multiline
              rows = {4}
              id = "reason"
              name = "reason"
              label = "Reject Bid"
              variant="outlined"
              value =  {reason['reason']}
              onChange={(e) => setReason({reason:e.target.value})}
            />
        </DialogContent>
        <DialogActions>
          <div className = {classes.wrapper}>
            <Button onClick={handleSubmit} color="primary">
              Reject
            </Button>
            {loading &&
              <CircularProgress size={32} className = {classes.loading}/>
            }
          </div>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {!hideStatus &&
        <div>
          <Grid container spacing = {1}>
            <Typography className = {classes.heading} align= "right">
              Status : {status}
            </Typography>
          </Grid>
          <Grid container spacing = {1}>
              {status === 'Rejected' &&
                <Typography className = {classes.reason}>
                  Reason : {bidData['reason']}
                </Typography>
              }
          </Grid>
        </div>
      }
      <Grid container spacing = {1}>
        <Typography className = {classes.heading} align = "left">
          Bid Info
        </Typography>
        <Tooltip title='View Attachments'>
          <IconButton
            className = {classes.attachments}
            onClick = {(event) => {
              setType('inquiryBid')
              setFileId(bidData['inquiry_bids_id'])
              setViewFile(true)
            }}
          >
            <AttachFileIcon color = 'primary'/>
          </IconButton>
        </Tooltip>
      </Grid>
        <Grid container spacing = {3}>
          <Grid item>
            <TextField
              value = {bidData['inquiry_bids_id']}
              label = 'Inquiry Bid Id'
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              className = {classes.text}
            />
          </Grid>
          <Grid item>
            <TextField
              value = {bidData['volume']}
              label = 'Volume (MBBL)'
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              className = {classes.text}
            />
          </Grid>
          <Grid item>
            <TextField
              value = {bidData['ask_price']}
              label = 'Ask Price (USD/BBL)'
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              className = {classes.text}
            />
          </Grid>
          <Grid item>
            <TextField
              value = {bidData['date_available']}
              label = 'Date Available'
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              className = {classes.text}
            />
            </Grid>
            <Grid item>
              <TextField
                value = {bidData['storage_term']}
                label = 'Term (Months)'
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                className = {classes.text}
              />
          </Grid>
          <Grid item xs = {6}>
            <TextField
              value = {bidData['bid_comment']}
              label = 'Comments'
              variant="outlined"
              multiline
              InputProps={{
                readOnly: true,
              }}
              className = {classes.multiline}
            />
          </Grid>
      </Grid>
    <Grid container spacing = {3}>
      <Grid item xs = {12}>
        <Typography className = {classes.heading}>
          Storage Info
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['locality']}
          label = 'Storage City'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['administrative_area_level_1']}
          label = 'Storage State'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['country']}
          label = 'Storage Country'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      {/* Storage Info */}

      <Grid item>
        <TextField
          value = {bidData['total_capacity']}
          label = 'Total Capacity (MBBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['product_category']}
          label = 'Product Category'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['tank_type']}
          label = 'Tank Type'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['tank_features'] || ''}
          label = 'Tank Features'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['tank_material']}
          label = 'Tank Material'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['heel_requirement']}
          label = 'Heel Requirement (BBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['blending_capability']}
          label = 'Blendable (Y/N)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['heating_capability']}
          label = 'Heated (Y/N)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['commingled']}
          label = 'Commingled'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['number_of_tanks']}
          label = 'Number of Tanks'
          variant = 'outlined'
          InputProps = {{
            readOnly: true
          }}
          className = {classes.text}
        />
      </Grid>
    </Grid>

    {/* Access Modes */}
    {bidData['access_modes'] && bidData['access_modes'].length > 0 && (
      <Grid container spacing = {1}>
        <Grid item xs = {12}>
          <Typography className = {classes.heading}>
            Access Modes
          </Typography>
        </Grid>
        <Grid item>
          <AccessModeTable access_modes = {bidData['access_modes']}/>
        </Grid>
      </Grid>
    )}

    <Grid container spacing = {1}>
      <Typography className = {classes.heading} align = "left">
        Inquiry Info
      </Typography>
      <Tooltip title='View Attachments'>
        <IconButton
          className = {classes.attachments}
          onClick = {(event) => {
            setType('inquiry')
            setFileId(bidData['inquiry_id'])
            setViewFile(true)
          }}
        >
          <AttachFileIcon color = 'primary'/>
        </IconButton>
      </Tooltip>
    </Grid>
      <Grid container spacing = {3}>
        <Grid item>
          <TextField
            value = {bidData['inquiry_id']}
            label = 'Inquiry Id'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        {bidData['place_id'] !== '' &&
        <React.Fragment>
        <Grid item>
          <TextField
            value = {bidData['inquiry_formatted_address']}
            label = 'Location'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {bidData['radius']}
            label = 'Radius (mi)'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
      </React.Fragment>
        }

        {bidData['place_id'] === '' &&
        <Grid item>
          <TextField
            value = {bidData['region']}
            label = 'Region'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
      }
        <Grid item>
          <TextField
            value = {bidData['bid_price'] || ''}
            label = 'Bid Price (USD/BBL)'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {bidData['product']}
            label = 'Product'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {bidData['min_volume']}
            label = 'Minimum Volume (MBBL)'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {bidData['term']}
            label = 'Term (Months)'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {bidData['start_date']}
            label = 'Start Date'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item xs = {6}>
          <TextField
            value = {bidData['comment']}
            label = 'Comments'
            variant="outlined"
            multiline
            InputProps={{
              readOnly: true,
            }}
            className = {classes.multiline}
          />
        </Grid>
    </Grid>

    {/*Inquiry Access Modes */}
    {bidData['inquiry_access_modes'] && bidData['inquiry_access_modes'].length > 0 && <AccessModeTable access_modes = {bidData['inquiry_access_modes']}/>}
    {/* Actions */}

    {(status === 'Open') && !disable &&
      <Grid container spacing = {3} justify='center'>
        <Grid item>
          <Button variant="contained" color="primary" className = {classes.button} onClick = {accept}>
            Accept Bid
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" className = {classes.button} onClick = {reject_bid}>
            Reject Bid
          </Button>
        </Grid>
      </Grid>
    }
    </div>
  )
}
