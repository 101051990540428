import React, { useState, useEffect } from 'react';
import { useAuth } from "../../context/auth";
import { useMessage } from '../../context/message';
import TeamAdminTable from './TeamAdminTable';
import MemberTable from './MemberTable';

export default function MyTeamPage() {
  const { auth } = useAuth();
  const { setMessage } = useMessage();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetch('/api/team/check_admin', {
      methods: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setIsAdmin(response.isAdmin);
    })
    .catch(error => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch team info'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage])

  if (isAdmin) {
    return (<TeamAdminTable/>);
  } else {
    return (<MemberTable/>);
  }
}
