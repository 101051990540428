import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import { useAuth } from "../../context/auth";


const columns = [
  {
    'title' : 'Report ID',
    'field' : 'report_id'
  },
  {
    'title' : 'Reported User ID',
    'field' : 'reported_id'
  },
  {
    'title' : 'Reason',
    'field' : 'reason'
  },
  {
    'title' : 'Description',
    'field' : 'description'
  },
  {
    'title' : 'Created',
    'field' : 'created',
    customSort: (a,b) => {
      var date1 = new Date(a.created)
      var date2 = new Date(b.created)

      return date1 - date2
    },
  },
  {
    'title' : 'Reporter User ID',
    'field' : 'reporter_id'
  }
]

export default function Reports(){
  const [reports, setReports] = useState([])
  const { auth } = useAuth();

  useEffect(() => {
    fetch('/api/report', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
      },
    })
    .then((response) => auth(response))
    .then((response) => {
      setReports(response.data)
    })
    .catch((error) => {
      console.error('Error:', error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <MaterialTable
      title = 'Reports'
      columns = {columns}
      data = {reports}
      options={{
        filtering: true,
        draggable: false,
      }}
    />
  )
}
