import React, { useEffect, useState } from 'react';
import { useMessage } from '../../context/message';
import { useAuth } from "../../context/auth";
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import AddStorage from '../storage/AddStorage'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  select: {
    width: 200,
  },
  sublet: {
    width: 125
  },
  label: {
    margin: theme.spacing(1)
  }
}));

export default function InquiryBidForm({ bid, updateField, setBid }) {
  const classes = useStyles();
  const { setMessage } = useMessage()
  const { auth } = useAuth()
  const [storage, setStorage] = useState([])
  const [fetchStorage, setFetchStorage] = useState(false)
  const [addStorage, setAddStorage] = useState(false)

  useEffect(() => {
    fetch('/api/storage/my', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setStorage(response.data || []);
    })
    .catch((error) => {
      setMessage({type: 'error', text: 'Error! Unable to fetch storage'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStorage])

  return (
    <Container>
      <AddStorage
        open = {addStorage}
        setOpen = {setAddStorage}
        fetchData = {setFetchStorage}
        storage_id = {0}
        toggle = {fetchStorage}
      />
      <FormControl className = {classes.root}>
        <InputLabel>Storage</InputLabel>
        <Select
          className={classes.select}
          name = 'storage_id'
          label='Storage'
          value={bid.storage_id}
          onChange={updateField}
        >
            <MenuItem onClick = {()=>setAddStorage(true)}><Link>Add Storage</Link></MenuItem>
          {storage.map((item) => (
            <MenuItem key={item.storage_id} value={item.storage_id}>{item.storage_name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        required
        className={classes.root}
        name = "ask_price"
        label = "Ask Price (USD/BBL)"
        value =  {bid.ask_price}
        onChange={updateField}
      />
      <TextField
        required
        className={classes.root}
        name = "volume"
        label = "Volume (MBBL)"
        value =  {bid.volume}
        onChange={updateField}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.root}
          format = "MM/dd/yyyy"
          margin = "normal"
          label = "Start Date"
          name = "date_available"
          value = {bid.date_available}
          onChange = {(long, short) => setBid({...bid, date_available: short})}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      <TextField
        required
        className={classes.root}
        name = "term"
        label = "Term (Months)"
        value =  {bid.term}
        onChange={updateField}
      />
      <TextField
        required
        className={classes.root}
        name = "comment"
        label = "Comment"
        value =  {bid.comment}
        onChange={updateField}
      />
      <FormControl className={classes.root}>
        <InputLabel>Allow Sublet</InputLabel>
        <Select
          className={classes.sublet}
          name = 'allow_sublet'
          label='Allow Sublet'
          value={bid.allow_sublet}
          onChange={updateField}
        >
          <MenuItem value = {1}>Y</MenuItem>
          <MenuItem value = {0}>N</MenuItem>
        </Select>
      </FormControl>

    </Container>
  )
}
