import React, { useState, useEffect, useCallback } from 'react'
import { useAuth } from "../../context/auth";
import GoogleMapReact from 'google-map-react'
import IconButton from '@material-ui/core/IconButton'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import HTMLTooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import InfoDialog from '../display/InfoDialog'
import PipelineComponent from './PipelineComponent'
import PADDComponent from './PADDComponent'
import RailComponent from './RailComponent'
import MarineComponent from './MarineComponent'
import TerminalComponent from './TerminalComponent'

const inquiryTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    }
  }
})

function MapComponent ({id, volume, price, product, value}) {
  const [info, setInfo] = useState(false)

  const handleCloseInfo = () => {
    setInfo(false)
  }


  return (
    <div style = {{'zIndex':6}}>
      <InfoDialog
        open = {info}
        onClose = {handleCloseInfo}
        type = 'listing_info'
        id = {id}
      />
      <HTMLTooltip
        title = {
              <Typography>
                Price : {price} ($/BBL)<br/>
                Product : {product} <br/>
                Volume : {volume} (MBBL)<br/>
              </Typography>
        }
        onClick = {() => {setInfo(true)}}>
        <IconButton
          size = 'small'
        >
            <LocationOnIcon color = 'primary'/>
        </IconButton>
      </HTMLTooltip>
    </div>
  )
}


function InquiryMapComponent ({id, min_volume, max_volume, price, product, value}) {
  const [info, setInfo] = useState(false)
  const handleCloseInfo = () => {
    setInfo(false)
  }

  return (
    <div style = {{'zIndex':6}}>
      <InfoDialog
        open = {info}
        onClose = {handleCloseInfo}
        type = 'inquiry_info'
        id = {id}
      />
      <HTMLTooltip
        title = {
              <Typography>
                Price : {price} ($/BBL)<br/>
                Product : {product} <br/>
                Volume Range : {min_volume} - {max_volume} (MBBL)<br/>
              </Typography>

        }
        onClick = {() => setInfo(true)}>
        <IconButton
          size = 'small'
        >
          <ThemeProvider theme = {inquiryTheme}>
            <LocationOnIcon color = 'primary'/>
          </ThemeProvider>
        </IconButton>
      </HTMLTooltip>
    </div>

  )
}

export default function ListingMap({listings, inquirys, showInquirys, showListings, overlay, subOverlay}){
  const defaultMapOptions = {
    fullscreenControl: false,
    styles: [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: 'poi',
        stylers: [{visibility: 'off'}]
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{visibility: 'off'}]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ]
  };

  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  //data
  const [crudePipeline, setCrudePipeline] = useState(null);
  const [refinedPipeline, setRefinedPipeline] = useState(null);
  const [padd, setPadd] = useState(null);
  const [productTerminals, setProductTerminals] = useState(null)
  const [railBNSF, setRailBNSF] = useState(null)
  const [railCSX, setRailCSX] = useState(null)
  const [railUP, setRailUP] = useState(null)
  const [railNS, setRailNS] = useState(null)
  const [marine, setMarine] = useState(null)
  const [intermodal, setIntermodal] = useState(null)
  const [intermodalAll, setIntermodalAll] = useState(null)
  const { overlayAccess } = useAuth()


  const fetchAllRecords = async (url, outFields = '*', additionalFilters='') => {
    let allRecords = [];
    let offset = 0;
    const limit = 1000;
    let hasMoreRecords = true;

    while (hasMoreRecords) {
      const response = await fetch(`${url}?outFields=${outFields}&where=1%3D1${additionalFilters}&f=geojson&resultOffset=${offset}&resultRecordCount=${limit}`);
      const data = await response.json();

      if (data.features && data.features.length > 0) {
        allRecords = allRecords.concat(data.features);
        offset += limit;
      } else {
        hasMoreRecords = false;
      }
  }

  return allRecords;
};

const fetchData = async () => {
  try {
    const crudePipelineData = await fetchAllRecords('https://services7.arcgis.com/FGr1D95XCGALKXqM/arcgis/rest/services/CrudeOil_Pipelines_US_EIA/FeatureServer/0/query');
    setCrudePipeline({ type: 'FeatureCollection', features: crudePipelineData });
  } catch (error) {
    console.error('Error fetching crudePipeline data:', error);
  }

  try {
    const refinedPipelineData = await fetchAllRecords('https://services7.arcgis.com/FGr1D95XCGALKXqM/arcgis/rest/services/PetroleumProduct_Pipelines_US_EIA/FeatureServer/0/query');
    setRefinedPipeline({ type: 'FeatureCollection', features: refinedPipelineData });
  } catch (error) {
    console.error('Error fetching refinedPipeline data:', error);
  }

  try {
    const paddData = await fetchAllRecords('https://services7.arcgis.com/FGr1D95XCGALKXqM/arcgis/rest/services/PADD_EIA/FeatureServer/0/query');
    setPadd({ features: paddData });
  } catch (error) {
    console.error('Error fetching padd data:', error);
  }

  try {
    const productTerminalsData = await fetchAllRecords('https://services7.arcgis.com/FGr1D95XCGALKXqM/arcgis/rest/services/PetroleumProduct_Terminals_US_EIA/FeatureServer/36/query');
    setProductTerminals(productTerminalsData);
  } catch (error) {
    console.error('Error fetching productTerminals data:', error);
  }

  try {
    const railBNSFData = await fetchAllRecords('https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_North_American_Rail_Network_Lines_BNSF/FeatureServer/0/query');
    setRailBNSF({ type: 'FeatureCollection', features: railBNSFData });
  } catch (error) {
    console.error('Error fetching railBNSF data:', error);
  }

  try {
    const railCSXData = await fetchAllRecords('https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_North_American_Rail_Network_Lines_CSXT/FeatureServer/0/query');
    setRailCSX({ type: 'FeatureCollection', features: railCSXData });
  } catch (error) {
    console.error('Error fetching railCSX data:', error);
  }

  try {
    const railUPData = await fetchAllRecords('https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_North_American_Rail_Network_Lines_UP/FeatureServer/0/query');
    setRailUP({ type: 'FeatureCollection', features: railUPData });
  } catch (error) {
    console.error('Error fetching railUP data:', error);
  }

  try {
    const railNSData = await fetchAllRecords('https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_North_American_Rail_Network_Lines_NS/FeatureServer/0/query');
    setRailNS({ type: 'FeatureCollection', features: railNSData });
  } catch (error) {
    console.error('Error fetching railNS data:', error);
  }

  try {
    const marineData = await fetchAllRecords('https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_Marine_Highways/FeatureServer/0/query');
    setMarine({ type: 'FeatureCollection', features: marineData });
  } catch (error) {
    console.error('Error fetching marine data:', error);
  }

  try {
    const intermodalData = await fetchAllRecords('https://services.arcgis.com/xOi1kZaI0eWDREZv/arcgis/rest/services/NTAD_Intermodal_Freight_Facilities_Pipeline_Terminals/FeatureServer/0/query');
    setIntermodal(intermodalData);
    setIntermodalAll(intermodalData)
  } catch (error) {
    console.error('Error fetching intermodal data:', error);
  }
};

useEffect(() => {
  const filtered = filterTerminals(intermodalAll, subOverlay)
  setIntermodal(filtered)
},[subOverlay,intermodalAll])

const filterTerminals = (terminals, filters) => {
    if (terminals){
      // Check if all product and transport filters are unchecked (all false)
      const allProductFiltersUnchecked = Object.values(filters).slice(3).every(filter => filter === false);
      const allTransportFiltersUnchecked = Object.values(filters).slice(0,3).every(filter => filter === false);
      // If no filters are selected, return all terminals
      if (allProductFiltersUnchecked && allTransportFiltersUnchecked) return terminals;
      return terminals.filter(terminal => {
        const {crude, refined, pet_chem, ngl, gasoline, distillate, biodiesel, jetfuel, ethanol,
          truck, rail, water
        } = filters

      //Check if at least one product filter matches
        const productMatches =
          (crude && terminal.properties.CRUDE_OIL === 'Y') ||
          (refined && terminal.properties.REFINED === 'Y') ||
          (pet_chem && terminal.properties.PET_CHEM === 'Y') ||
          (ngl && terminal.properties.NGL === 'Y') ||
          (gasoline && terminal.properties.GASOLINE === 'Y') ||
          (distillate && terminal.properties.DISTILLATE === 'Y') ||
          (biodiesel && terminal.properties.BIODIESEL === 'Y') ||
          (jetfuel && terminal.properties.JETFUEL === 'Y') ||
          (ethanol && terminal.properties.ETHANOL === 'Y')

        // Check if at least one transport filter matches
        const transportMatches =
          (truck && terminal.properties.TRUCK === 'Y') ||
          (rail && terminal.properties.RAIL === 'Y') ||
          (water && terminal.properties.WATER === 'Y');

        // If there are no product filters or transport filters selected, include terminal
        const includeByProduct = allProductFiltersUnchecked || productMatches;
        const includeByTransport = allTransportFiltersUnchecked || transportMatches;

        // Return true if both the product and transport filters match (if selected)
        return includeByProduct && includeByTransport;
    })
  }
}

useEffect(() => {
  if(overlayAccess === 1){
      fetchData();
  }
}, [overlayAccess,]);

  const handleApiLoaded = (mapInstance, mapsInstance) => {
    setMap(mapInstance);
    setMaps(mapsInstance);
  };

  return(
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{key:'AIzaSyBTp8nnXh_LBIFetGatTUUEzjsT58DCg9A'}}
        center={{lat:37,lng:-95}}
        zoom={4}
        hoverDistance={0.1}
        options={defaultMapOptions}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {showListings && listings.map((listing) => (
          <MapComponent
            key = {listing['listing_id']}
            lat = {parseFloat(listing['newLatitude'])}
            lng = {parseFloat(listing['newLongitude'])}
            id = {listing['listing_id']}
            value = {listing['listing_id']}
            volume = {listing['working_capacity']}
            price = {listing['ask_price']}
            product = {listing['product_category']}
          />
        ))
        }
        {showInquirys && inquirys.map((inquiry) => (inquiry['latitude'] &&
          <InquiryMapComponent
            key = {inquiry['inquiry_id']}
            lat = {parseFloat(inquiry['newLatitude'])}
            lng = {parseFloat(inquiry['newLongitude'])}
            id = {inquiry['inquiry_id']}
            value = {inquiry['inquiry_id']}
            min_volume = {inquiry['min_volume']}
            max_volume = {inquiry['max_volume']}
            price = {inquiry['bid_price']}
            product = {inquiry['product']}
          />
        ))
        }
        {overlay.product && productTerminals && productTerminals.map((feature, index)=> (
            <TerminalComponent
              key={index}
              lat={feature.geometry.coordinates[1]}
              lng={feature.geometry.coordinates[0]}
              feature={feature}
              type='product'
            />
        ))
        }
        {overlay.intermodal && intermodal && intermodal.map((feature, index) => (
          <TerminalComponent
            key={index}
            lat = {feature.geometry.coordinates[1]}
            lng = {feature.geometry.coordinates[0]}
            feature={feature}
            type = 'intermodal'
          />
        ))

        }
        <PipelineComponent map={map}
          maps={maps}
          pipelineData={crudePipeline}
          strokeColor = {'#8B0000'}
          visible = {overlay.crude}
        />
        <PipelineComponent
          map={map}
          maps={maps}
          pipelineData={refinedPipeline}
          strokeColor = {'#00008B'}
          visible = {overlay.refined}
        />
        <RailComponent
          map={map}
          maps={maps}
          railData={railBNSF}
          strokeColor={'#2F4F4F'}
          visible = {overlay.bnsf}
        />
        <RailComponent
          map={map}
          maps={maps}
          railData={railUP}
          strokeColor={'#000000'}
          visible = {overlay.up}
        />
        <RailComponent
          map={map}
          maps={maps}
          railData={railCSX}
          strokeColor={'#006400'}
          visible = {overlay.csx}
        />
        <RailComponent
          map={map}
          maps={maps}
          railData={railNS}
          strokeColor={'#8B4513'}
          visible = {overlay.ns}
        />
        <MarineComponent
          map={map}
          maps={maps}
          marineData={marine}
          strokeColor={'#456898'}
          visible = {overlay.marine}
        />
        <PADDComponent
            map={map}
            maps={maps}
            paddData={padd}
            strokeColor="#FF0000"
            fillColor="#FF0000"
            visible={overlay.padd}
          />
      </GoogleMapReact>
    </div>
  )
}
