import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { useAuth } from "../../context/auth";
import TextField from "@material-ui/core/TextField"
import { useMessage } from "../../context/message";
import CustomFilter from '../CustomFilter';
import Typography from '@material-ui/core/Typography'
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh'
import InfoIcon from '@material-ui/icons/Info'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MTableToolbar from 'material-table/dist/components/m-table-toolbar'
import InfoDialog from '../display/InfoDialog'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 5,
    marginLeft: 10,
    marginRight:10,

    color: theme.palette.primary.main
  },
  userInfo: {
    margin: theme.spacing(3),
    width: '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 10,
  },
}));

export default function AdminBooking(){
  const classes = useStyles();
  const { userCategory } = useAuth();
  const [data, setData] = useState([]);
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [bounds, setBounds] = useState({})
  const [info, setInfo] = useState(false)
  const [id, setId] = useState(0)
  const [type, setType] = useState('')
  const [toggle, fetchData] = useState(false)
  const [hideClosed, setHideClosed] = useState(false)

  useEffect(() => {
    fetch(`/api/booking?hideClosed=${hideClosed}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch bookings on your storage.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ setMessage, hideClosed]);

  const handleClose = () => {
    setInfo(false)
    setId(0)
    setType('')
  }

  const switchChange = (event) => {
    setHideClosed(event.target.checked)
  }

  return (
    <div>
      {((userCategory === 'Admin') && bounds) &&
        <MaterialTable
          title = "All Bookings"
          columns = {[
            {
              'title' : 'Status',
              'field' : 'status'
            },
            {
              'title' : 'Location',
              'field' : 'address'
            },
            {
              'title' : 'Price (USD/BBL)',
              'field' : 'price',
              'type' : 'numeric',
              'bounds': bounds.price,
              'customFilterAndSearch': rangeFilter('price'),
              'step': .1
            },
            {
              'title' : 'Product',
              'field' : 'product'
            },
            {
              'title' : 'Volume (MBBL)',
              'field' : 'volume',
              'type' : 'numeric',
              'bounds': bounds.volume,
              'customFilterAndSearch': rangeFilter('volume'),
              'step': 1
            },
            {
              'title' : 'Date Booked',
              'field' : 'date_booked',
              'type' : 'date',
              'customFilterAndSearch' : dateRangeFilter('date_booked'),
              customSort: (a,b) => {
                var date1 = new Date(a.date_booked)
                var date2 = new Date(b.date_booked)

                return date1 - date2
              },
              render: rowData => <p>{(new Date(rowData.date_booked)).toLocaleDateString()}</p>
            },
            {
              'title' : 'Start Date',
              'field' : 'start_date',
              'type' : 'date',
              'customFilterAndSearch' : dateRangeFilter('start_date'),
              customSort: (a,b) => {
                var date1 = new Date(a.start_date)
                var date2 = new Date(b.start_date)

                return date1 - date2
              },
              render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
            },
            {
              'title' : 'Term (Months)',
              'field' : 'term',
              'type' : 'numeric',
              'bounds': bounds.term,
              'customFilterAndSearch': rangeFilter('term')
            }
          ]}
          data = {data}
          icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
          actions = {[
            {
              icon: () => <RefreshIcon />,
              tooltip: 'Refresh',
              'isFreeAction': true,
              onClick: (event) => fetchData(!toggle)
            },
            {
              icon: () => <InfoIcon color = 'primary' />,
              tooltip: 'More Info',
              onClick: (event, rowData) => {
                setId(rowData.bid_id)
                setType(rowData.type)
                setInfo(true)
              }
            }
          ]}
          detailPanel = {
            rowData => {
              return(
                <div>
                  <Typography className= {classes.heading}>
                    Trader Info
                  </Typography>
                  <Grid container spacing = {2} className = {classes.userInfo}>
                    <Grid item xs>
                      <TextField
                        label="Username"
                        value = {rowData.trader_username}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        label="First Name"
                        value = {rowData.trader_first_name}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        label="Last Name"
                        value = {rowData.trader_last_name}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        label="Company"
                        value = {rowData.trader_company}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm>
                      <TextField
                        label="Email"
                        value = {rowData.trader_email}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        label="Office Phone"
                        value = {rowData.trader_office_phone}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        label="Cell Phone"
                        value = {rowData.trader_cell_phone}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                </Grid>
                <Typography className= {classes.heading}>
                  Terminal Info
                </Typography>
                <Grid container spacing = {2} className = {classes.userInfo}>
                  <Grid item xs>
                    <TextField
                      label="Username"
                      value = {rowData.terminal_username}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="First Name"
                      value = {rowData.terminal_first_name}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Last Name"
                      value = {rowData.terminal_last_name}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Company"
                      value = {rowData.terminal_company}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm>
                    <TextField
                      label="Email"
                      value = {rowData.terminal_email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Office Phone"
                      value = {rowData.terminal_office_phone}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Cell Phone"
                      value = {rowData.terminal_cell_phone}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              )
            }
          }
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          options={{
            filtering: true,
            draggable: false,
            actionsColumnIndex: 0
          }}
          components={
            {
              FilterRow: props => <CustomFilter {...props} />,
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <FormControlLabel
                    control = {
                      <Switch
                        checked = {hideClosed}
                        onChange = {switchChange}
                        color = 'primary'
                        size = 'small'
                      />
                    }
                    label = 'Hide closed'
                    labelPlacement = 'start'
                  />
                </div>
              )
            }
          }
        />
      }
      <InfoDialog
        open = {info}
        onClose = {handleClose}
        type = {type}
        id = {id}
      />
    </div>
  )
}
