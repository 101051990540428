import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useField } from '../../context/context'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  select: {
    margin: theme.spacing(2),
    width: 200
  },
  button: {
    margin: theme.spacing(2),
  },
}));

export default function Preference() {
  const classes = useStyles();
  const { auth, tutorialStep, setTutorialStep } = useAuth();
  const { regions, product_categories, fetchFields, setFetchFields } = useField()
  const { setMessage } = useMessage();
  const [products_list, setProducts_list] = useState([]);
  const [regions_list, setRegions_list] = useState([]);
  const [preferredProducts, setPreferredProducts] = useState([]);
  const [preferredRegions, setPreferredRegions] = useState([]);
  const [toggle, fetchData] = useState(false);

  useEffect(() => {
    if(product_categories){
      setProducts_list(product_categories.map((item) => item.product_category));
    } else {
      setFetchFields(!fetchFields)
    }
    if(regions){
      setRegions_list(regions.map((item) => item.region));
    } else{
      setFetchFields(!fetchFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[regions, product_categories])

  useEffect(() => {
    fetch('/api/preference', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
      }
    })
    .then(response => auth(response))
    .then(response => {
      setPreferredProducts(response.products.map((item) => item.product_category));
      setPreferredRegions(response.regions.map((item) => item.region));
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch preferences'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage, toggle])

  const handleSubmit = () => {
    fetch('/api/preference', {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({products: preferredProducts, regions: preferredRegions})
    })
    .then(response => auth(response))
    .then(response => {
      if (response.message.type === 'success') {
        fetchData(!toggle);
        if (tutorialStep === 33) {
          setTutorialStep(34)
        }
      }
      setMessage(response.message);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to set preferences'});
      console.error('Error:', error);
    })
  }

  const clearPreferences = () => {
    if (preferredRegions.length !== 0 || preferredProducts.length !== 0) {
      setPreferredRegions([])
      setPreferredProducts([])
      fetch('/api/preference', {
        method: 'DELETE',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
      },
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          fetchData(!toggle);
        }
        setMessage(response.message);
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to set preferences'});
        console.error('Error:', error);
      })
    }
  }

  return (
    <Paper>
      <Grid container>
        <Typography color='primary' className={classes.heading}>Preferences</Typography>
      </Grid>
      <FormControl className={classes.select}>
        <InputLabel>Products</InputLabel>
        <Select
          multiple
          value={preferredProducts}
          onChange={e => setPreferredProducts(e.target.value)}
          renderValue={(preferredProducts) => (Array.isArray(preferredProducts) && preferredProducts.join(', '))}
        >
          {products_list.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked = {preferredProducts.indexOf(item) > -1} />
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.select}>
        <InputLabel>Regions</InputLabel>
        <Select
          multiple
          value={preferredRegions}
          onChange={e => setPreferredRegions(e.target.value)}
          renderValue={(preferredRegions) => (Array.isArray(preferredRegions) && preferredRegions.join(', '))}
        >
          {regions_list.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked = {preferredRegions.indexOf(item) > -1} />
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          >
            Save
          </Button>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={() => fetchData(!toggle)}
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={clearPreferences}
            disabled = {tutorialStep === 33}
            >
              Clear
            </Button>
      </Grid>
    </Paper>
  );
}
