import React, { useState} from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

function TerminalComponent({feature, type}){
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClickOpen = (event) => {
    event.stopPropagation()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderProperties = () => {
    return Object.entries(feature.properties).map(([key, value]) => (
      <Typography key={key}>
        <strong>{key}:</strong> {value !== null ? value : 'N/A'}
      </Typography>
    ));
  };

  const renderTooltip = () => {
    if (type === 'docks'){
      return (
        <Typography>
          NAV_UNIT_NAME: {feature.properties.NAV_UNIT_NAME}
        </Typography>
      )
    } else if (type === 'product') {
      return (
        <Typography>
          Company: {feature.properties.Company}
        </Typography>
      )
    } else if (type === 'intermodal') {
      return (
        <Typography>
          TERM_NAME: {feature.properties.TERM_NAME}
        </Typography>
      )
    }else {
      return;
    }
  }

  const getMarkerStyle = () => {
    let backgroundColor;
    switch(type){
      case 'docks':
        backgroundColor = '#1E90FF' // blue
        break;
      case 'product':
        backgroundColor = '#32CD32' //green
        break;
      case 'intermodal':
        backgroundColor = '#FFA500' // orange
        break;
      default:
        backgroundColor = '#1E90FF'
    }
    return {
      backgroundColor,
      width: isHovered ? '14px' : '7px',
      height: isHovered ? '14px' : '7px',
      display: 'inline-block',
      borderRadius: '50%',
      zIndex: 4,
      transition: 'width 0.3s, height 0.3s, background-color 0.3s',
      cursor: 'pointer',
      border: '1px solid #B9B4B3',
      boxShadow: isHovered ? '0 0 8px rgba(0,0,0,0.3)' : 'none',
    }
  }

  return (
    <div>
      <Tooltip
        title={
          renderTooltip()
        }
      >
        <div style={getMarkerStyle()}
          onClick = {handleClickOpen}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
        </div>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>More Information</DialogTitle>
        <DialogContent>{renderProperties()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
function areEqual(prevProps, nextProps){
  return prevProps.id === nextProps.id && prevProps.lat === nextProps.lat && prevProps.lng === nextProps.lng;
}
export default React.memo(TerminalComponent, areEqual)
