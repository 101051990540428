import React from 'react'
import {
  Redirect
} from "react-router-dom";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false};
    this.state = {error: ''}
    this.state = {info: ''}
  }

  componentDidCatch(error, info){
    this.setState({hasError: true});
    this.setState({error: {error}});
    this.setState({info: {info}});
    //log error
  }
  render() {
    if (this.state.hasError) {
      return <Redirect to={'/ERROR/'.concat(this.state.error.error.toString().concat('/').concat(this.state.info.info.componentStack))}/>
    }
    return this.props.children;
  }
}
