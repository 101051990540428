import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useChat } from '../../context/chat'
import { useField } from '../../context/context'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import BidForm from '../bid/BidForm';
import Button from '@material-ui/core/Button';
import AccessModeTable from '../display/AccessModeTable';
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import Typography from '@material-ui/core/Typography'
import AlertDialog from '../inputs/AlertDialog'
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Tooltip from '@material-ui/core/Tooltip';
import BidFileSelect from '../inputs/BidFileSelect'
import CircularProgress from '@material-ui/core/CircularProgress'
import FAQ from '../display/FAQ'
import HelpIcon from '@material-ui/icons/Help'
import MessageIcon from '@material-ui/icons/Message'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  place_bid: {
    marginLeft: 5,
    marginTop: 5,
    display: 'flex',
    'flex-direction': 'row'
  },
  attach: {
    height: 30,
    width: 30,
    top: -5,
    left: 5,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export default function AllListingsTable() {
  const { setMessage } = useMessage();
  const classes = useStyles()
  const { auth } = useAuth();
  const { setChatroom, setCurrentChat, setOpenConversations, openConversations } = useChat()
  const { products, regions, fetchFields, setFetchFields } = useField()
  const [bid_products, setBid_products] = useState([]);
  const [product_lookup, setProduct_lookup] = useState({});
  const [region_lookup, setRegion_lookup] = useState({})
  const [data, setData] = useState([]);
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(false)
  const [inquire, setInquire] = useState(false);
  const [info, setInfo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [listingId, setListingId] = useState()
  const [bid, setBid] = useState({
    listing_id: '',
    product: '',
    bid_price: '',
    volume: '',
    comment: '',
    start_date: new Date(),
    term: '',
    name:'',
    file:'',
    modes: [],
    mode_type: '',
    vessel_size: '',
    vessel_name: ''
  });
  //file attach
  const [bidAttach, setBidAttach] = useState(false)
  const [byteArrays, setByteArrays] = useState([])
  const [previewURLs, setPreviewURLs] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [bidToggle, setBidToggle] = useState(false)

  const handleClose = () => {
    setOpen(false);
    fetchData(!toggle);
    setByteArrays([])
    setPreviewURLs([])
    setFileNames([])
    //reset bid product and modes
    setBid({...bid,
      product: '',
      modes: []
    })
  };

  const closeInquire = () => {
    setInquire(false)
  }

  const handleCloseInfo = () => {
    setInfo(false)
  }

  useEffect(() => {
    if(products){
      let temp = {};
      products && products.map(item => temp[item.product] = item.product);
      setProduct_lookup(temp);
    } else {
      setFetchFields(!fetchFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  useEffect(() => {
    if(regions) {
      let temp = {}
      regions && regions.map(item => temp[item.region] = item.region)
      setRegion_lookup(temp)
    } else {
      setFetchFields(!fetchFields)
    }
  }, [regions])

  const submitInquiry = () => {
    fetch(`/api/message/inquire/listing/${listingId}`, {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      },
    })
    .then(response => auth(response))
    .then(response => {
      if (response.message.type === 'success'){
        openConversations.push(response.data)
        setOpenConversations([...openConversations])
        setCurrentChat(response.data)
        setChatroom(true)
      }
      setMessage(response.message)
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to start inquiry.'})
      console.error('Error:', error);
    })
    closeInquire()
  }

  const updateField = e => {
    setBid({
      ...bid,
      [e.target.name]: e.target.value
    });
  };

  const attach = () => {
    setBid({
      ...bid,
      'name': fileNames,
      'file': byteArrays,
    })
  }

  const handleSubmit = () => {
    if (!loading) {
      setLoading(true)
      fetch(`/api/bid/${bid.listing_id}`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bid)
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        setLoading(false)
        setMessage(response.message);
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to submit bid'});
        setLoading(false)
        console.error('Error:', error);
      })
    }
  };

  useEffect(() => {
    fetch('/api/listing/all', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({type: 'error', text: 'Error! Unable to fetch listings'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage])

  const columns = [
    {
      title: 'Listing Id',
      field: 'listing_id',
    },
    {
      title: 'Working Capacity (MBBL)',
      field: 'working_capacity',
      type: 'numeric',
      bounds: bounds.working_capacity,
      customFilterAndSearch: rangeFilter('working_capacity'),
    },
    {
      title: 'Shell Price (USD/BBL)',
      field: 'ask_price',
      type: 'numeric',
      step: .10,
      bounds: bounds.ask_price,
      customFilterAndSearch: rangeFilter('ask_price', bounds.ask_price),
    },
    {
      title: 'Term (Months)',
      field: 'term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Availability after Commitment (Months)',
      field: 'delay_after_commit',
      type: 'numeric',
      bounds: bounds.delay_after_commit,
      customFilterAndSearch: rangeFilter('delay_after_commit'),
    },
    {
      title: 'Product Types',
      field: 'product_category',
      render: rowData => (
        rowData.product_category && (rowData.product_category.length > 30 ? rowData.product_category.substring(0,30) + '...' : rowData.product_category)
      )
    },
    {
      title: 'Start Date',
      field: 'date_available',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('date_available'),
      customSort: (a,b) => {
        var date1 = new Date(a.date_available)
        var date2 = new Date(b.date_available)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.date_available)).toLocaleDateString()}</p>
    },
    {
      title: 'Region',
      field: 'region',
      lookup: region_lookup
    },
    {
      title: 'City',
      field: 'locality',
    },
    {
      title: 'State',
      field: 'administrative_area_level_1'
    },
    {
      title: 'Country',
      field: 'country'
    },
    {
      title: 'Heel Product',
      field: 'heel_product',
      lookup: product_lookup,
    },
    {
      title: 'Total Capacity (MBBL)',
      field: 'total_capacity',
      type: 'numeric',
      bounds: bounds.total_capacity,
      customFilterAndSearch: rangeFilter('total_capacity'),
    },
    {
      title: 'Tank Material',
      field: 'tank_material',
      lookup: {
        'Carbon Steel': 'Carbon Steel',
        'Stainless Steel': 'Stainless Steel',
        'Carbon Steel Lined': 'Carbon Steel Lined',
        'Mild Steel': 'Mild Steel',
        'Coated Steel': 'Coated Steel',
        'Other': 'Other'
      },
    },
    {
      title: 'Tank Type',
      field: 'tank_type',
      lookup: {
        'Internal Floating Roof': 'Internal Floating Roof',
        'External Floating Roof': 'External Floating Roof',
        'Coned Roof': 'Coned Roof',
        'Cavern': 'Cavern',
        'Sphere': 'Sphere',
        'Other': 'Other'
      },
    },
    {
      title: 'Tank Features',
      field: 'tank_features',
      render: rowData => (
        rowData.tank_features && (rowData.tank_features.length > 30 ? rowData.tank_features.substring(0,30) + '...' : rowData.tank_features)
      )
    },
    {
      title: 'Heel Requirement (BBL)',
      field: 'heel_requirement',
      type: 'numeric',
      bounds: bounds.heel_requirement,
      customFilterAndSearch: rangeFilter('heel_requirement'),
    },
    {
      title: 'Blendable (Y/N)',
      field: 'blending_capability',
      lookup: {
        1: 'Y',
        0: 'N',
      },
    },
    {
      title: 'Heated (Y/N)',
      field: 'heating_capability',
      lookup: {
        1: 'Y',
        0: 'N',
      },
    },
    {
      title: 'Com or Seg',
      field: 'commingled',
      lookup: {
        1: 'Commingled',
        0: 'Segregated',
      },
    },
    {
      title: 'Number of Tanks',
      field: 'number_of_tanks',
      type: 'numeric',
      bounds: bounds.number_of_tanks,
      customFilterAndSearch: rangeFilter('number_of_tanks'),
    },
    {
      title: 'Comment',
      field: 'comment',
      render: rowData => (
        rowData.comment && (rowData.comment.length > 30 ? rowData.comment.substring(0,30) + '...' : rowData.comment)
      )
    },
    {
      title: 'Allow Sublet',
      field: 'allow_sublet',
      lookup: {
        0: 'N',
        1: 'Y'
      }
    }
  ];

  const accessModes = (rowData) => {
    return (
      <AccessModeTable listing_id={rowData.listing_id} />
    )
  }

  const chipDelete = (index) => {
    fileNames.splice(index,1)
    byteArrays.splice(index,1)
    previewURLs.splice(index,1)
    setBidToggle(!bidToggle)
  }

  return (
    <div>
      <FAQ open = {openFAQ} setOpen = {setOpenFAQ}/>
      <MaterialTable
        title='Listings'
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        columns={columns}
        data={data}
        options = {{
          filtering: true,
          draggable: false,
          exportButton: false,
          actionsColumnIndex: 0
        }}
        actions = {[
          {
            icon: () => <HelpIcon color = 'primary'/>,
            tooltip: 'FAQ',
            isFreeAction: true,
            onClick: (event) => setOpenFAQ(true)
          },
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            isFreeAction: true,
            tooltip: 'Refresh',
            onClick: (event) => fetchData(!toggle)
          },
          {
            icon: () => <MonetizationOnIcon color = 'primary'/>,
            tooltip: 'Place Bid',
            onClick: (event, rowData) => {
              setOpen(true);
              setBid({ ...bid, listing_id: rowData.listing_id });
              fetch(`/api/bid/${rowData.listing_id}/products`, {
                method: 'GET',
                headers: {
                  'x-access-token': sessionStorage.getItem('token'),
                },
              })
              .then(response => auth(response))
              .then(response => {
                setBid_products(response.data.map(item => item.product));
              })
              .catch((error) => {
                setMessage({'type': 'error', 'text': 'Error! Unable to fetch products.'})
                console.error('Error:', error);
              });
            }
          },
          {
            icon: () => <MessageIcon color = 'primary'/>,
            tooltip: 'Send Message',
            onClick: (event, rowData) => {
              setListingId(rowData.listing_id)
              setInquire(true)
            }
          },
          {
            icon: () => <InfoIcon color = 'primary'/>,
            tooltip: 'More Info',
            onClick: (event, rowData) => {
              setListingId(rowData.listing_id)
              setInfo(true)
            }
          }
        ]}
        components= {{
          FilterRow: props => <CustomFilter {...props} />
        }}
        detailPanel={accessModes}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
      <AlertDialog
        open = {inquire}
        setOpen = {setInquire}
        title = 'Start Chat'
        content = 'Are you sure you wish to start a chat with the owner of this listing?'
        submitText = 'Submit'
        submit = {submitInquiry}
      />
      <InfoDialog
        open = {info}
        onClose = {handleCloseInfo}
        type = 'listing_info'
        id = {listingId}
      />
      <Dialog
        maxWidth='md'
        fullWidth={true}
        open={open}
        >
        <DialogContent>
          <Grid container spacing = {2} className = {classes.place_bid}>
            <Typography>
              Place Bid
            </Typography>
            <Tooltip title='Attach Files'>
              <IconButton
                onClick = {() => setBidAttach(true)}
                className = {classes.attach}
              >
                <AttachFileIcon color = 'primary'/>
              </IconButton>
            </Tooltip>
            <Grid item xs = {12}>
              {fileNames.length > 0 && fileNames.map((name, index) =>
                <Chip color='primary' key = {index} label={name} onDelete={() => chipDelete(index)}/>
              )}
            </Grid>
          </Grid>
          <BidFileSelect
            open = {bidAttach}
            setOpen = {setBidAttach}
            type = 'bid'
            byteArrays = {byteArrays} setByteArrays = {setByteArrays}
            previewURLs = {previewURLs} setPreviewURLs = {setPreviewURLs}
            fileNames = {fileNames} setFileNames = {setFileNames}
            attach = {attach}
          />
          <BidForm
            bid={bid}
            products={bid_products}
            updateField={updateField}
            setBid={setBid}
          />
        </DialogContent>
        <DialogActions>
            <div className = {classes.wrapper}>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
              {loading &&
                <CircularProgress size={32} className = {classes.loading}/>
              }
            </div>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
