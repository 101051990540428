import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { useMessage } from '../../context/message';
import { useAuth } from '../../context/auth';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 400
  },
}));

export default function BookedPieChart() {
  const theme = useTheme();
  const classes = useStyles();
  const { auth } = useAuth();
  const { setMessage } = useMessage();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/api/booking/booked_capacity/my', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setData(response)
    })
    .catch(error => {
      setMessage({'type': 'error', 'text': 'Error! Unable to booked capacity data.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage])

  return (
    <Paper className={classes.root}>
      <React.Fragment>
        <Grid container justify = 'center'>
          <Typography variant = 'button' style = {{fontSize: 16, marginTop: 10}}>
            My Capacity
          </Typography>
        </Grid>
        <ResponsiveContainer width="100%" height="90%">
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              nameKey='name'
              data={data}
              fill={theme.palette.primary.main}
              outerRadius={80}
              label
            >
              <Cell fill='#03fc77'/>
              <Cell fill={theme.palette.primary.main}/>
            </Pie>
            <Legend verticalAlign='top' />
          </PieChart>
        </ResponsiveContainer>
      </React.Fragment>
    </Paper>
  )
}
