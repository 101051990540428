import React, { useState, useEffect } from 'react'
import Chart from 'react-google-charts'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DateRangeFilter from '../inputs/DateRangeFilter'
import SimpleSelect from '../inputs/SimpleSelect'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useField } from '../../context/context'
import DataCover from '../display/DataCover'

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(5)
  },
  tab: {
    backgroundColor: theme.palette.secondary.secondary,
    },
  tabSelect: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      textColor: theme.palette.secondary.main,
    },
  },
  paper: {
    'marginTop':theme.spacing(15),
  },
  slider: {
    marginTop: 5
  },
  switch: {
    marginTop: 20
  },
  header: {
    flexGrow: 1,
    backgroundColor: '#545454',
  },
  map: {
    marginBottom: theme.spacing(8),
  },
  button: {
    backgroundColor: '#954807',
    fontSize: 12,
    width:160,
    '&:hover' : {
      backgroundColor: '#fc6c05'
    }
  },
  selected: {
    fontSize: 12,
    width: 160,
    '&:hover' : {
      backgroundColor: '#fc6c05'
    }
  }
}))

export default function GeoChart() {
  const classes = useStyles()
  const { setMessage } = useMessage();
  const { auth, dataSub } = useAuth();
  const { product_categories, fetchFields, setFetchFields } = useField()
  const [product_categories_list, setProduct_categories_list] = useState([]);
  const [product_category, setProductCategory] = useState('')
  const [showListings, setShowListings] = useState(true)
  const [showInquirys, setShowInquirys] = useState(true)
  const [dateRange, setDateRange] = useState({})
  const [showHistory, setShowHistory] = useState(false)
  const [data, setData] = useState([])
  const [volume, setVolume] = useState('volume')

  useEffect(() => {
    if(product_categories){
      setProduct_categories_list(product_categories.map(function(item) {return {value: item.product_category, label: item.product_category}}))
    } else {
      setFetchFields(!fetchFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[product_categories])

  useEffect(() => {
    let type = 'none'
    if (showListings){
      if(showInquirys)
        type = 'both'
      else
        type = 'listing'
    }
    else if (showInquirys) {
        type = 'inquiry'
    }
    //listing/inquiries volume grouped by state
    fetch(`/api/heatmap?product_category=${product_category}&type=${type}&showHistory=${showHistory}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&volume=${volume}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      parseData(response)
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch listings.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_category, dateRange, showHistory, showInquirys, showListings, volume])

    const parseData = (response) => {
      let temp = []
      if (volume === 'volume'){
        temp = [['State', 'Volume']]
      }

      else {
        temp = [['State', 'Price']]
      }
      for(const entry in response){
        let point = []
        if (volume === 'volume'){
          point = [
            response[entry].state,
            response[entry].volume
          ]
        } else {
          point = [
            response[entry].state,
            response[entry].price
          ]
        }
        temp.push(point)
      }
      setData(temp)
    }

    const handleChange = (event) => {
      setShowHistory(event.target.checked)
    }

    const handleListingCheck = (event) => {
      setShowListings(event.target.checked)
    }

    const handleInquiryCheck = (event) => {
      setShowInquirys(event.target.checked)
    }

    const handleVolume = () => {
      setVolume('volume')
    }

    const handlePrice = () => {
      setVolume('price')
    }

  return (
    <div style = {{position: 'relative', top: 12}}>
      {dataSub !== 1 &&
        <DataCover/>
      }
      <Grid container direction = 'row' spacing = {3}>
        <Grid item className = {classes.root}>
          <SimpleSelect value = {product_category} setValue={setProductCategory} label='Product Category' options={product_categories_list}/>
        </Grid>
        <Grid item className = {classes.switch}>
          <ButtonGroup variant = 'contained' color = 'primary'>
            <Button onClick = {handleVolume} className = {volume === 'volume' ? classes.selected : classes.button}>
              Volume
            </Button>
            <Button onClick = {handlePrice} className = {volume === 'price' ? classes.selected : classes.button}>
              Price
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item className = {classes.slider}>
          <Typography>
            Date Range
          </Typography>
          <DateRangeFilter value = {dateRange} onChange = {setDateRange}/>
        </Grid>
        {volume === 'volume' &&
          <Grid item className = {classes.switch}>
            <FormControlLabel
              control = {
                <Checkbox
                  checked = {showListings}
                  onChange = {handleListingCheck}
                  color = 'primary'
                />
              }
              label = 'Listings'
            />
            <FormControlLabel
              control = {
                <Checkbox
                  checked = {showInquirys}
                  onChange = {handleInquiryCheck}
                  color = 'primary'
                />
              }
              label = 'Inquiries'
            />
          </Grid>
        }
        {volume === 'volume' &&
          <Grid item className = {classes.switch}>
            <FormControlLabel
              control = {
                <Switch
                  checked = {showHistory}
                  onChange = {handleChange}
                  color = 'primary'
                />
              }
              label = 'Show History'
            />
          </Grid>
        }
      </Grid>
      <Chart
        width={'100%'}
        height={'50vh'}
        chartType='GeoChart'
        data={data}
        options={{
          region: 'US',
          resolution: 'provinces',
          backgroundColor: '#17263c',
          colorAxis: { colors: ['#F7D7A0','#fc6c05'] }
        }}

        rootProps={{ 'data-testid': '4' }}
      />
    </div>
  )
}
