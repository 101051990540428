import React from 'react';
import {
  useParams
} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Bid from './Bid'
import Listing from './Listing'
import Inquiry from './Inquiry'
import InquiryBid from './InquiryBid'
import ErrorBoundary from '../error/ErrorBoundary'
export default function Landing(){
  let { table, id } = useParams();

  return(
    <ErrorBoundary>
      <Paper>
        {table === 'bid' && <Bid id = {id}/>}
        {table === 'listing' && <Listing id = {id}/>}
        {table === 'inquiry' && <Inquiry id = {id}/>}
        {table === 'inquiry_bid' && <InquiryBid id = {id}/>}
      </Paper>
    </ErrorBoundary>
  )
}
