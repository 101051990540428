import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useAuth } from '../../context/auth'

function PriceChange({ change }) {
    const isPriceUp = change > 0;
    const color = isPriceUp ? 'green' : 'red';
  
    return (
      <span
        style={change ? { color: color, display: 'inline' } : {display: 'inline'}}
        component="span"
      >
        {change ? ` (${isPriceUp ? '+' : ''}${change})%` : ' (—)%'}
      </span>
    );
  }

function PriceIndex() {
    const { auth } = useAuth()
    const [data, setData] = useState([
        {"region":"Canada","product_category":"Agricultural Products","price":0.95,"prev_price":1.53,"change":-38.0},
        {"region":"Canada","product_category":"Distillate","price":0.95,"prev_price":0.9,"change":6.0},
        {"region":"Canada","product_category":"Ethanol","price":1.31,"prev_price":1.21,"change":8.0},
        {"region":"Canada","product_category":"Finished Gasoline","price":0.95,"prev_price":null,"change":null},
        {"region":"Canada","product_category":"Heavy Crude","price":0.95,"prev_price":null,"change":null},
        {"region":"Canada","product_category":"Heavy Products","price":1.68,"prev_price":null,"change":null},
        {"region":"PADD 1A","product_category":"Agricultural Products","price":0.4,"prev_price":null,"change":null},
        {"region":"PADD 1A","product_category":"Chemical","price":3.0,"prev_price":null,"change":null},
        {"region":"PADD 1A","product_category":"Distillate","price":0.4,"prev_price":null,"change":null},
        {"region":"PADD 1A","product_category":"Heavy Products","price":1.02,"prev_price":null,"change":null},
        {"region":"PADD 1A","product_category":"NGL\/LPG","price":4.25,"prev_price":4.0,"change":6.0},
        {"region":"PADD 1B","product_category":"Agricultural Products","price":1.3,"prev_price":1.1,"change":18.0},
        {"region":"PADD 1B","product_category":"Aromatics","price":2.0,"prev_price":1.1,"change":82.0},
        {"region":"PADD 1B","product_category":"Chemical","price":1.65,"prev_price":1.15,"change":43.0},
        {"region":"PADD 1B","product_category":"Distillate","price":0.75,"prev_price":0.43,"change":74.0},
        {"region":"PADD 1B","product_category":"Ethanol","price":0.9,"prev_price":null,"change":null},
        {"region":"PADD 1B","product_category":"Gasoline Components","price":0.69,"prev_price":0.75,"change":-8.0},
        {"region":"PADD 1C","product_category":"Agricultural Products","price":1.1,"prev_price":0.98,"change":12.0},
        {"region":"PADD 1C","product_category":"Chemical","price":1.35,"prev_price":1.07,"change":26.0},
        {"region":"PADD 1C","product_category":"Distillate","price":0.59,"prev_price":0.78,"change":-24.0},
        {"region":"PADD 1C","product_category":"Gasoline Components","price":1.1,"prev_price":1.0,"change":10.0},
        {"region":"PADD 1C","product_category":"Heavy Products","price":1.0,"prev_price":0.88,"change":14.0},
        {"region":"PADD 2","product_category":"Agricultural Products","price":1.61,"prev_price":1.41,"change":14.0},
        {"region":"PADD 2","product_category":"Chemical","price":1.27,"prev_price":1.09,"change":17.0},
        {"region":"PADD 2","product_category":"Distillate","price":0.51,"prev_price":0.85,"change":-40.0},
        {"region":"PADD 2","product_category":"Ethanol","price":0.5,"prev_price":null,"change":null},
        {"region":"PADD 2","product_category":"Finished Gasoline","price":0.5,"prev_price":0.98,"change":-49.0},
        {"region":"PADD 2","product_category":"Heavy Products","price":0.93,"prev_price":1.08,"change":-14.0},
        {"region":"PADD 2","product_category":"Light Crude","price":0.15,"prev_price":null,"change":null},
        {"region":"PADD 3","product_category":"Agricultural Products","price":1.5,"prev_price":1.03,"change":46.0},
        {"region":"PADD 3","product_category":"Aromatics","price":1.1,"prev_price":1.53,"change":-28.0},
        {"region":"PADD 3","product_category":"Chemical","price":1.54,"prev_price":1.27,"change":21.0},
        {"region":"PADD 3","product_category":"Condensate","price":0.75,"prev_price":0.8,"change":-6.0},
        {"region":"PADD 3","product_category":"Distillate","price":1.14,"prev_price":0.71,"change":61.0},
        {"region":"PADD 3","product_category":"Ethanol","price":1.53,"prev_price":1.25,"change":22.0},
        {"region":"PADD 3","product_category":"Finished Gasoline","price":0.76,"prev_price":0.93,"change":-18.0},
        {"region":"PADD 3","product_category":"Gasoline Components","price":0.77,"prev_price":0.55,"change":40.0},
        {"region":"PADD 3","product_category":"Heavy Products","price":0.7,"prev_price":0.73,"change":-4.0},
        {"region":"PADD 3","product_category":"Light Crude","price":0.29,"prev_price":0.65,"change":-55.0},
        {"region":"PADD 3","product_category":"NGL\/LPG","price":0.85,"prev_price":2.0,"change":-57.0},
        {"region":"PADD 3","product_category":"Sour Crude","price":0.49,"prev_price":0.7,"change":-30.0},
        {"region":"PADD 5","product_category":"Agricultural Products","price":1.78,"prev_price":1.54,"change":16.0},
        {"region":"PADD 5","product_category":"Chemical","price":2.21,"prev_price":3.18,"change":-31.0},
        {"region":"PADD 5","product_category":"Distillate","price":0.62,"prev_price":1.0,"change":-38.0},
        {"region":"PADD 5","product_category":"Ethanol","price":1.65,"prev_price":1.75,"change":-6.0},
        {"region":"PADD 5","product_category":"Finished Gasoline","price":0.52,"prev_price":1.0,"change":-48.0},
        {"region":"PADD 5","product_category":"Heavy Products","price":1.93,"prev_price":1.5,"change":29.0},
        {"region":"PADD 5","product_category":"NGL\/LPG","price":4.25,"prev_price":4.0,"change":6.0}
      ]);
  const regions = ['Canada', 'PADD 1A', 'PADD 1B', 'PADD 1C', 'PADD 2', 'PADD 3', 'PADD 5'];
  const productCategories = [...new Set(data.map(item => item.product_category))];

  const pricesByCategory = productCategories.reduce((acc, category) => {
    acc[category] = regions.reduce((regionAcc, region) => {
      regionAcc[region] = null;
      return regionAcc;
    }, {});
    return acc;
  }, {});

  data.forEach(({ region, product_category, price, change }) => {
    pricesByCategory[product_category][region] = { price, change };
  });

  useEffect(() => {
      fetch('/api/price_index', {
        method: 'GET',
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        }
      })
      .then((response) => auth(response))
      .then((response) => {
        if(!response.message) 
          setData(response)
      })
      .catch((error) => {
        console.error('Error:', error);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product Category</TableCell>
            {regions.map(region => (
              <TableCell key={region} align="right" >{region}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {productCategories.map((category) => (
            <TableRow
              key={category}
              style={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {category}
              </TableCell>
              {regions.map(region => (
                <TableCell key={`${category}-${region}`} align="right">
                  {pricesByCategory[category][region] ? (
                    <>
                      {pricesByCategory[category][region].price}
                      <PriceChange change={pricesByCategory[category][region].change} />
                    </>
                  ) : '—'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PriceIndex;
