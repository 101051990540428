import React, { useState } from 'react'
import { useMessage } from '../../context/message'
import { useAuth } from "../../context/auth";
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500
  },
  select: {
    minWidth: '100%'
  }
}));

export default function ReportDialog({open, setOpen, conversation_id}){
  const classes = useStyles()
  const {setMessage} = useMessage();
  const { auth } = useAuth();
  const [description, setDescription] = useState('')
  const [reason, setReason] = useState('')

  const close = () => {
    setOpen(false)
    setDescription('')
    setReason('')
  }

  const report = () => {
    if(reason !== ''){
      fetch('/api/report', {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/JSON'
        },
        body: JSON.stringify({'reason': reason, 'description': description, 'conversation_id': conversation_id}),
      })
      .then((response) => auth(response))
      .then((response) => {
        setMessage(response.message)
        close()
      })
      .catch((error) => {
        setMessage({'type': 'error', 'text': 'Error! Unable to send report.'})
        console.error('Error:', error)
      })
    }else{
      setMessage({'type': 'error', 'text':'Must select option.'})
    }
  }

  const updateDescription = e => {
    setDescription(e.target.value)
  }

  const selectOption = e => {
    setReason(e.target.value)
  }

  return (
    <Dialog open = {open} onClose = {close} className = {classes.container}>
      <DialogTitle>
        Report User
      </DialogTitle>
      <DialogContent>
        <Grid container spacing = {2}>
          <Grid item xs = {12}>
            <Typography>
              Report an issue
            </Typography>
          </Grid>
          <Grid item xs = {12}>
            <FormControl variant = 'outlined' className = {classes.select}>
              <InputLabel>Select option...</InputLabel>
              <Select
                value = {reason}
                onChange = {selectOption}
                label = 'Select option...'
              >
                <MenuItem value = {''}>
                  Select option...
                </MenuItem>
                <MenuItem value = {'Bad Faith Content'}>
                  Bad Faith Content
                </MenuItem>
                <MenuItem value = {'Inappropriate Behavior'}>
                  Inappropriate Behavior
                </MenuItem>
                <MenuItem value = {'Other'}>
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs = {12}>
            <TextField
              variant = 'outlined'
              label = 'Description'
              value = {description}
              onChange = {updateDescription}
              className = {classes.select}
              multiline
              rows = {5}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color = 'primary' onClick = {close}>
          Cancel
        </Button>
        <Button color = 'primary' onClick = {report}>
          Report
        </Button>
      </DialogActions>
    </Dialog>
  )
}
