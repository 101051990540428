import React, { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh';
import AlertDialog from '../inputs/AlertDialog'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'

export default function MyPlacedBidsTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [cancel, setCancel] = useState(false)
  //info dialog
  const [open, setOpen] = useState(false)
  const [bid_id, setBidId] = useState(0)

  useEffect(() => {
    fetch('/api/bid/placed', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch placed bids.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage]);

  const cancelBid = () => {
    fetch(`/api/bid/${bid_id}/cancel`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to cancel bid.'})
      console.error('Error:', error);
    })
  }

  const handleCloseInfo = () => {
    setOpen(false)
    setBidId(0)
  }

  const columns = [
    {
      title: 'Listing Id',
      field: 'listing_id',
      editable: 'never',
    },
    {
      title: 'Formatted Address',
      field: 'formatted_address',
      editable: 'never',
    },
    {
      title: 'Product',
      field: 'product',
      editable: 'never'
    },
    {
      title: 'Bid Price (USD/BBL)',
      field: 'bid_price',
      type: 'numeric',
      bounds: bounds.bid_price,
      customFilterAndSearch: rangeFilter('bid_price'),
    },
    {
      title: 'Ask Price (USD/BBL)',
      field: 'ask_price',
      type: 'numeric',
      editable: 'never',
      bounds: bounds.ask_price,
      customFilterAndSearch: rangeFilter('ask_price'),
    },
    {
      title: 'Start Date',
      field: 'start_date',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('start_date'),
      customSort: (a,b) => {
        var date1 = new Date(a.start_date)
        var date2 = new Date(b.start_date)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
    },
    {
      title: 'Term (Months)',
      field: 'bid_term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Comment',
      field: 'bid_comment',
      render: rowData => (
        rowData.bid_comment && (rowData.bid_comment.length > 30 ? rowData.bid_comment.substring(0,30) + '...' : rowData.bid_comment)
      )
    }
  ];

  return (
    <div>
      <AlertDialog
        open = {cancel}
        setOpen = {setCancel}
        title = 'Delete Bid'
        content = 'Are you sure you want to delete this bid?'
        submitText = 'Delete'
        submit = {cancelBid}
      />
      <InfoDialog
        open = {open}
        onClose = {handleCloseInfo}
        type = 'listing'
        id = {bid_id}
        disablePlaceBid = {true}
      />
      <MaterialTable
        title = 'Your Bids on Listings'
        columns = {columns}
        data = {data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        options = {{
          filtering: true,
          draggable: false,
          actionsColumnIndex: 0
        }}
        components={{
          FilterRow: props => <CustomFilter {...props} />
        }}
        actions={[{
          icon: () => <RefreshIcon color = 'primary'/>,
          tooltip: 'Refresh',
          isFreeAction: true,
          onClick: (event) => fetchData(!toggle)
        },
        {
          icon: () => <InfoIcon color = 'primary'/>,
          tooltip: 'More Info',
          onClick: (e, rowData) => {
            setBidId(rowData.bid_id)
            setOpen(true)
          }
        },
        {
          icon: () => <DeleteOutlineIcon color = 'primary'/>,
          tooltip: 'Cancel Bid',
          onClick: (e, rowData) => {
            setBidId(rowData.bid_id)
            setCancel(true)
          }
        }
        ]}
      />
    </div>
  );
}
