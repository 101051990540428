import React, {useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import { useAuth } from "../../context/auth";

export default function Unsubscribe() {
  const { auth } = useAuth();

  useEffect(() => {
    fetch('/api/user/unsubscribe', {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((data) => {
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Typography>
        You are now Unsubscribed.
      </Typography>
  )
}
