import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import ProductList from './ProductList'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    'z-index': 5
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 20,
    marginRight:10,
    color: theme.palette.primary.main
  },
}));

export default function FAQ ({open, setOpen}) {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return(
    <Dialog fullWidth={true} maxWidth='md' open = {open} onClose = {handleClose}>
      <DialogTitle className = {classes.closeButton}>
        <IconButton onClick = {handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className = {classes.heading}>
          Product List
        </Typography>
        <ProductList/>
      </DialogContent>
    </Dialog>
  )
}
