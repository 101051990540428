import React from 'react';
import Grid from '@material-ui/core/Grid';
import CombinedChart from './CombinedChart';
import BidAskChart from './BidAskChart';
import ComparisonChart from './ComparisonChart';
import VolumePieChart from './VolumePieChart';
import TankWatch from './TankWatch'
import Maps from './Maps'
import PriceIndex from './PriceIndex'
import ErrorBoundary from '../error/ErrorBoundary';
import { useAuth } from "../../context/auth";
import DataCover from '../display/DataCover'

export default function Dashboard() {
  const { dataSub, tankwatch_api } = useAuth()

  return (
    <ErrorBoundary>
      <Grid container spacing={3} style = {{minWidth:1000}}>
        <Grid item xs={12}>
          <Maps />
        </Grid>
        <Grid item xs = {12}>
          <div style = {{position: 'relative'}}>
            {dataSub !== 1 &&
              <DataCover/>
            }
            <PriceIndex/>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style = {{position: 'relative'}}>
            {dataSub !== 1 &&
              <DataCover/>
            }
            <BidAskChart />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style = {{position: 'relative'}}>
            {dataSub !== 1 &&
              <DataCover/>
            }
            <CombinedChart />
          </div>
        </Grid>
        <Grid item xs={9}>
          <div style = {{position: 'relative'}}>
            {dataSub !== 1 &&
              <DataCover/>
            }
            <ComparisonChart />
          </div>
        </Grid>
        <Grid item xs = {3}>
          <div style = {{position: 'relative'}}>
            {dataSub !== 1 &&
              <DataCover/>
            }
            <VolumePieChart />
          </div>
        </Grid>
        {
          <Grid item xs = {12}>
            <div style = {{position: 'relative'}}>
              {!tankwatch_api &&
                <DataCover/>
              }
              <TankWatch />
            </div>
          </Grid>
        }
      </Grid>
    </ErrorBoundary>
  );
}
