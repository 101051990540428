import React, { useEffect, useState } from 'react'
import { useAuth } from "../../context/auth";
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import InfoDialog from './InfoDialog'

const useStyles = makeStyles((theme) => ({
  month: {
    width: '7%',
  },
  month_text: {
    'text-align': 'center',
  },
  storage: {
    width: '8%'
  },
  calendar: {
    minWidth: 1250,
    minHeight: 500
  },
  booking: {
    backgroundColor: theme.palette.primary.main,
    width: '7%'
  },
  warning: {
    width: 20,
    height: 20,
    color: 'red',
    position: 'relative',
    left: '40%'
  }
}));

export default function TeamBookingCalendar({type}) {
  const classes = useStyles()
  const [bookings, setBookings] = useState([])
  const [year, setYear] = useState(new Date().getFullYear())
  const {auth} = useAuth()
  const [check, setCheck] = useState([])
  const [loading, setLoading] = useState(false)
  //info dialog
  const [open, setOpen] = useState(false)
  const [id, setId] = useState(0)
  const [bookingType, setBookingType] = useState('')

  const months = [1,2,3,4,5,6,7,8,9,10,11,12]
  const month_name = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  useEffect(() => {
    setLoading(true)
    fetch(`/api/booking/calendar/team/${type}/${year}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setBookings(response.data)
      setCheck(response.check)
      setLoading(false)

    })
    .catch((error) => {
      setLoading(false)
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const prevYear = () => {
    setCheck([])
    setBookings([])
    setYear(year - 1)

  }

  const nextYear = () => {
    setCheck([])
    setBookings([])
    setYear(year + 1)
  }

  const handleRowClick = (booking) => {
    if(booking.type === 'renewal'){
      setBookingType(booking.type)
      setId(booking.booking_id)
    } else {
      setBookingType(booking.type)
      setId(booking.bid_id)
    }
    setOpen(true)
  }

  const closeInfo = () => {
    setOpen(false)
    setBookingType('')
    setId(0)
  }

  const style = (booking, month, id) => {
    let style = {
      'width': '7%',
    }
    if (Number(booking.start_month) === month) {
      style['backgroundColor'] = `hsl(${25 + ((10/bookings.length) * (id))}, 100%, 50%)`
      style['borderRadius'] = '5px 0px 0px 5px'
      if (check[booking.storage_id] && check[booking.storage_id][month] && type === 'terminal'){
        style['backgroundColor'] = 'hsl(10, 100%, 50%)'
      }
    }
    else if(Number(booking.end_month) === month || (Number(booking.end_month) >= 12 && month === 12)){
      style['backgroundColor'] = `hsl(${25 + ((10/bookings.length) * (id))}, 100%, 50%)`
      style['borderRadius'] = '0px 5px 5px 0px'
      if (check[booking.storage_id] && check[booking.storage_id][month] && type === 'terminal'){
        style['backgroundColor'] = 'hsl(10, 100%, 50%)'
      }
    }
    else if (Number(booking.start_month) < month && Number(booking.end_month) > month) {
      style['backgroundColor'] = `hsl(${25 + ((10/bookings.length) * (id))}, 100%, 50%)`
      if (check[booking.storage_id] && check[booking.storage_id][month] && type === 'terminal'){
        style['backgroundColor'] = 'hsl(10, 100%, 50%)'
      }
    }

    return style
  }

  const check_overbooked = (month) => {
    for (const storage in check){
      if (check[storage][month]){
        return true
      }
    }
    return false
  }

  return(
    <div>
      <Paper className = {classes.calendar}>
        <Grid container>
          <Typography variant = 'h6' style = {{marginLeft: 25, marginTop: 25}}>
            {type === 'terminal' ? 'Bookings on your Storage': 'Bookings on your Offers'}
          </Typography>
        </Grid>
        <Grid container justify = 'center'>
          <Typography variant = 'button' style = {{fontSize: 16}}>
            Year
          </Typography>
        </Grid>
        <Grid container direction = 'row' justify = 'center'>
          <Button onClick = {prevYear}>
            {'<'}
          </Button>
          <Typography variant = 'button' style = {{fontSize: 16, marginTop: 5, marginLeft: 5, marginRight: 5}}>
            {year}
          </Typography>
          <Button onClick = {nextYear}>
            {'>'}
          </Button>
        </Grid>
        <List>
          <ListItem className = {classes.listItem}>
            <Grid container direction = 'row' justify = 'space-evenly'>
              <Grid item className = {classes.storage}>
                <Typography className = {classes.month_text}>
                  Username
                </Typography>
              </Grid>
              <Grid item className = {classes.storage}>
                <Typography className = {classes.month_text}>
                  Storage
                </Typography>
              </Grid>
              {months.map((month) =>
                <Grid item key = {month} className = {classes.month}>
                  <Typography className = {classes.month_text}>
                    {month_name[month - 1]}
                  </Typography>
                  {check_overbooked(month) && type === 'terminal' &&
                    <Tooltip title='One of your storage locations is overbooked for this month.'>
                      <ErrorOutlineIcon className = {classes.warning} />
                    </Tooltip>
                  }
                </Grid>
              )}
            </Grid>
          </ListItem>
          {loading && <CircularProgress style = {{position: 'relative',left:'49%'}}/>}
          {bookings && bookings.length === 0 &&
            <ListItem>
              <Grid container direction = 'row' justify = 'center'>
                <Grid item>
                  <Typography>
                    No bookings for this year.
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          }
          {bookings && bookings.map((booking, id) =>
            <ListItem button
              className = {classes.listItem}
              onClick = {() => handleRowClick(booking)}
              key = {id}
            >
              <Grid container direction = 'row' justify = 'space-evenly'>
                {type === 'terminal' &&
                <Grid item className = {classes.storage}>
                  {(id === 0 || (bookings[id - 1] && bookings[id].terminal_username !== bookings[id - 1].terminal_username)) ?
                    <Typography align= 'center'>
                      {booking.terminal_username}
                    </Typography>
                    :
                    <Typography align= 'center'>
                      &nbsp;
                    </Typography>
                  }
                </Grid>
                }
                {type === 'trader' &&
                <Grid item className = {classes.storage}>
                  {(id === 0 || (bookings[id - 1] && bookings[id].trader_username !== bookings[id - 1].trader_username)) ?
                    <Typography align= 'center'>
                      {booking.trader_username}
                    </Typography>
                    :
                    <Typography align= 'center'>
                      &nbsp;
                    </Typography>
                  }
                </Grid>
                }
                {type === 'terminal' &&
                  <Grid item className = {classes.storage}>
                    {(id === 0 || (bookings[id - 1] && bookings[id].storage_name !== bookings[id - 1].storage_name)) ?
                      <Typography align= 'center'>
                        {booking.storage_name}
                      </Typography>
                      :
                      <Typography align= 'center'>
                        &nbsp;
                      </Typography>
                    }
                  </Grid>
                }
                {type === 'trader' &&
                  <Grid item className = {classes.storage}>
                    {(id === 0 || (bookings[id - 1] && bookings[id].address !== bookings[id - 1].address)) ?
                      <Typography align= 'center'>
                        {booking.locality}, {booking.administrative_area_level_1}
                      </Typography>
                      :
                      <Typography align= 'center'>
                        &nbsp;
                      </Typography>
                    }
                  </Grid>
                }
                {months.map((month) =>
                  <Grid item
                    style = {style(booking, month, id)}
                    key = {month}
                  >
                    {Number(booking.start_month) === month &&
                      <Typography variant = 'button' style = {{marginLeft: 5}}>
                        {booking.volume} MBBL
                      </Typography>
                    }
                  </Grid>
                )}
              </Grid>
            </ListItem>
          )
          }
        </List>
      </Paper>
      <InfoDialog
        open = {open}
        onClose = {closeInfo}
        type = {bookingType}
        id = {id}
      />
    </div>
  )
}
