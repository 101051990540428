import React, { useEffect, useState } from 'react'
import Bid from './Bid'
import InquiryBid from './InquiryBid'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 20,
    marginLeft: 20,
    marginRight:10,
    color: theme.palette.primary.main
  },
  text: {
    marginLeft : 20,
    marginTop : 20,
  },
}));

export default function Renewal({id}) {
  const classes = useStyles()
  const { auth } = useAuth();
  const { setMessage } = useMessage()
  const [data, setData] = useState({
    'renewal_id': '',
    'renewal_booking_id': '',
    'start_date': '',
    'term': '',
    'price': '',
    'volume': '',
    'bid_id': '',
    'type': ''
  })

  useEffect(() => {
    fetch(`/api/renew/${id}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data)
    })
    .catch((error) => {
      setMessage('Error! Unable to fetch renewal data.')
      console.error('Error', error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Grid container spacing = {1}>
        <Typography className = {classes.heading}>
          Renewal Info
        </Typography>
      </Grid>
      <Grid container spacing = {3}>
        <Grid item>
          <TextField
            value = {data['renewal_id'] || data['booking_id'] || ''}
            label = 'Renewal Id'
            variant = 'outlined'
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {data['price']}
            label = 'Price'
            variant = 'outlined'
            InputProps = {{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {data['volume']}
            label = 'Volume'
            variant = 'outlined'
            InputProps = {{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {data['start_date']}
            label = 'Start Date'
            variant = 'outlined'
            InputProps = {{
              readOnly: true
            }}
            className = {classes.text}
          />
        </Grid>
        <Grid item>
          <TextField
            value = {data['term']}
            label = 'Term'
            variant = 'outlined'
            InputProps = {{
              readOnly: true
            }}
            className = {classes.text}
          />
        </Grid>
      </Grid>
      {data['type'] === 'listing' &&
        <Bid id = {data['bid_id']} hideStatus/>
      }
      {data['type'] === 'inquiry' &&
        <InquiryBid id = {data['bid_id']} hideStatus/>
      }
    </div>
  )
}
