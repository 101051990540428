import React, { useState, useRef } from 'react';
import EditableTable from '../inputs/EditableTable';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { rangeFilter } from '../FilterFunctions';
import AddStorage from './AddStorage'
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AlertDialog from '../inputs/AlertDialog'
import AccessModeTable from '../display/AccessModeTable'
import TutorialStep from '../display/TutorialStep'
import AddListing from '../listing/AddListing'

export default function MyStorageTable() {
  const { setMessage } = useMessage();
  const { auth, tutorialStep, setTutorialStep } = useAuth();
  const addRef = useRef()
  const [open, setOpen] = useState(false);
  const [deleteDialog, setDelete] = useState(false)
  const [storage_id, setStorage_id] = useState(0);
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [addListing, setAddListing] = useState(false)

  const columns = [
    {
      title: 'Location',
      field: 'formatted_address'
    },
    {
      title: 'Storage Name',
      field: 'storage_name',
    },
    {
      title: 'Product Category',
      field: 'product_category',
      render: rowData => (
        rowData.product_category && (rowData.product_category.length > 30 ? rowData.product_category.substring(0,30) + '...' : rowData.product_category)
      )
    },
    {
      title: 'Total Capacity (MBBL)',
      field: 'total_capacity',
      type: 'numeric',
      bounds: bounds.total_capacity,
      customFilterAndSearch: rangeFilter('total_capacity'),
    },
    {
      title: 'Tank Material',
      field: 'tank_material',
      lookup: {
        'Carbon Steel': 'Carbon Steel',
        'Stainless Steel': 'Stainless Steel',
        'Carbon Steel Lined': 'Carbon Steel Lined',
        'Mild Steel': 'Mild Steel',
        'Coated Steel': 'Coated Steel',
        'Other': 'Other'
      },
    },
    {
      title: 'Tank Type',
      field: 'tank_type',
      lookup: {
        'Internal Floating Roof': 'Internal Floating Roof',
        'External Floating Roof': 'External Floating Roof',
        'Coned Roof': 'Coned Roof',
        'Cavern': 'Cavern',
        'Sphere': 'Sphere',
        'Other': 'Other'
      },
    },
    {
      title: 'Tank Features',
      field: 'tank_features',
      render: rowData => (
        rowData.tank_features && (rowData.tank_features.length > 30 ? rowData.tank_features.substring(0,30) + '...' : rowData.tank_features)
      )
    },
    {
      title: 'Heel Requirement (BBL)',
      field: 'heel_requirement',
      type: 'numeric',
      bounds: bounds.heel_requirement,
      customFilterAndSearch: rangeFilter('heel_requirement'),
    },
    {
      title: 'Blendable (Y/N)',
      field: 'blending_capability',
      lookup: {
        1: 'Y',
        0: 'N',
      },
    },
    {
      title: 'Heated (Y/N)',
      field: 'heating_capability',
      lookup: {
        1: 'Y',
        0: 'N',
      },
    },
    {
      title: 'Com or Seg',
      field: 'commingled',
      lookup: {
        1: 'Commingled',
        0: 'Segregated',
      },
    },
    {
      title: 'Number of Tanks',
      field: 'number_of_tanks',
      type: 'numeric',
      bounds: bounds.number_of_tanks,
      customFilterAndSearch: rangeFilter('number_of_tanks'),
    },
    {
      title: 'Is Sublease',
      field: 'sublet',
      lookup: {
        1: 'Y',
        0: 'N'
      }
    }
  ];

  const deleteStorage = () => {
    fetch(`/api/storage/${storage_id}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to delete storage.'})
      console.error('Error:', error);
    })
    setStorage_id(0)
  }

  const accessModes = (rowData) => {
    return (
      <AccessModeTable storage_id={rowData.storage_id} />
    )
  }

  return (
    <div>
      <AddStorage
        open = {open}
        setOpen = {setOpen}
        storage_id = {storage_id}
        setStorage_id = {setStorage_id}
        fetchData = {fetchData}
        toggle = {toggle}
      />
      <AddListing
        open = {addListing}
        setOpen = {setAddListing}
        toggle = {toggle}
        fetchData = {fetchData}
        myStorageID = {storage_id}
        setMyStorageID = {setStorage_id}
      />
      <AlertDialog
        open = {deleteDialog}
        setOpen = {setDelete}
        title = 'Delete Storage'
        content = 'Are you sure you want to delete this storage location?'
        submitText = 'Delete'
        submit = {deleteStorage}
      />
      <EditableTable
        title='My Storage'
        table='storage'
        columns={columns}
        setBounds={setBounds}
        fetchData = {fetchData}
        toggle = {toggle}
        actions = {[
          {
            icon: () => <AddBoxIcon color = 'primary' ref = {addRef}/>,
            tooltip: 'Add Storage',
            isFreeAction: true,
            onClick: () => {
              setOpen(true)
              if (tutorialStep === 10){
                setTutorialStep(11)
              }
            }
          },
          {
            icon: () => <AddBoxIcon color = 'primary' />,
            tooltip: 'Add Listing',
            onClick: (e, rowData) => {
              setStorage_id(rowData.storage_id);
              setAddListing(true);
            }
          },
          {
            icon: () => <EditIcon color = 'primary'/>,
            tooltip: 'Edit Storage',
            onClick: (e, rowData) => {
              setStorage_id(rowData.storage_id)
              setOpen(true)
            }
          },
          {
            icon: () => <DeleteOutlineIcon color = 'primary'/>,
            tooltip: 'Delete Storage',
            onClick: (e, rowData) => {
              setStorage_id(rowData.storage_id)
              setDelete(true)
            }
          }
        ]}
        detailPanel={accessModes}
      />
      {tutorialStep === 10 &&
        <TutorialStep refPosition = {addRef.current} text = 'Click here to add Storage'/>
      }
    </div>
  );
}
