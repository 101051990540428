import React, { useState } from 'react'
import { useMessage } from '../../context/message'
import { useAuth } from '../../context/auth'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  select: {
    marginTop: 15,
    width: '90%'
  },
  textfield: {
    marginTop:10,
    width: '90%'
  },
  dialog: {
    minWidth:500,
    minHeight: 300
  }
}))

export default function ReportIssue({open, setOpen}) {
  const classes = useStyles()
  const { auth } = useAuth()
  const { setMessage } = useMessage()
  const [value, setValue] = useState('')
  const [text, setText] = useState('')

  const changeText = (event) => {
    setText(event.target.value)
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const onClose = () => {
    setOpen(false)
    setText('')
    setValue('')
  }

  const submit = () => {
    //submit request
    if (value !== '') {
      if (text !== '') {
        fetch('/api/report/request', {
          method: 'POST',
          headers: {
            'x-access-token': sessionStorage.getItem('token'),
            'Content-Type': 'application/JSON'
          },
          body: JSON.stringify({'request_type': value, 'request_description': text}),
        })
        .then((response) => auth(response))
        .then((response) => {
          setMessage(response.message)
          if (response.message.type === 'success'){
            onClose()
          }
        })
        .catch((error) => {
          setMessage({'type': 'error', 'text': 'Error! Unable to send report.'})
          console.error('Error:', error)
        })
      } else {
        setMessage({'type': 'error', 'text':'Must enter description.'})
      }
    } else {
      setMessage({'type': 'error', 'text':'Must select option.'})
    }

  }

  return (
    <Dialog
      open = {open}
      onClose = {onClose}
      className = {classes.dialog}
    >
      <DialogTitle>
        Report an issue
      </DialogTitle>
      <DialogContent>
        <Typography>
          Report an issue or bug on the platform, request a product to be added to product list, or request a new feature for the platform.
        </Typography>
        <Grid container justify = 'center'>
          <FormControl variant = 'outlined' className = {classes.select}>
            <InputLabel id = 'select option'>
              Select an option...
            </InputLabel>
            <Select
              labelId = 'select option'
              value = {value}
              onChange = {handleChange}
              label = 'Select an option...'
            >
              <MenuItem value={1}>Report an issue</MenuItem>
              <MenuItem value={2}>Request a new product</MenuItem>
              <MenuItem value={3}>Request a new platform feature</MenuItem>
            </Select>
          </FormControl>
          <TextField
            multiline
            label = 'Description'
            rows = {4}
            value = {text}
            onChange = {changeText}
            variant = 'outlined'
            className = {classes.textfield}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color = 'primary' onClick = {onClose}>
          Cancel
        </Button>
        <Button color = 'primary' onClick = {submit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
