import React, { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import AlertDialog from '../inputs/AlertDialog'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'

export default function MyReceivedBidsTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [toggle, fetchData] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [bid_id, setBidId] = useState(0)
  const [bounds, setBounds] = useState({});
  const [bid, setBid] = useState({
    bid_id: '',
    reason: ''
  });
  const [accept, setAccept] = useState(false)

  const handleClose = () => {
    setBid({ bid_id: '', reason: '' });
    setOpen(false);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false)
    setBidId(0)
  }

  useEffect(() => {
    fetch('/api/bid/received', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch received bids.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage]);

  const handleSubmit = () => {
    if (!loading) {
      setLoading(true)
      fetch(`/api/bid/${bid.bid_id}/reject`, {
        method: 'DELETE',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bid),
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        setMessage(response.message);
        fetchData(!toggle);
        setLoading(false)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to reject bid'});
        setLoading(false)
        console.error('Error:', error);
      })
    }
  };

  const columns = [
    {
      title: 'Listing Id',
      field: 'listing_id',
      defaultGroupOrder: 0,
    },
    {
      title: 'Storage Name',
      field: 'storage_name'
    },
    {
      title: 'Product',
      field: 'product'
    },
    {
      title: 'Volume (MBBL)',
      field: 'volume',
      bounds: bounds.volume,
      customFilterAndSearch: rangeFilter('volume'),
    },
    {
      title: 'Bid Price (USD/BBL)',
      field: 'bid_price',
      type: 'numeric',
      bounds: bounds.bid_price,
      customFilterAndSearch: rangeFilter('bid_price'),
    },
    {
      title: 'Ask Price (USD/BBL)',
      field: 'ask_price',
      type: 'numeric',
      bounds: bounds.ask_price,
      customFilterAndSearch: rangeFilter('ask_price'),
    },
    {
      title: 'Start Date',
      field: 'start_date',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('start_date'),
      customSort: (a,b) => {
        var date1 = new Date(a.start_date)
        var date2 = new Date(b.start_date)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
    },
    {
      title: 'Term (Months)',
      field: 'bid_term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Comment',
      field: 'bid_comment',
      render: rowData => (
        rowData.bid_comment && (rowData.bid_comment.length > 30 ? rowData.bid_comment.substring(0,30) + '...' : rowData.bid_comment)
      )
    }
  ];

  const acceptBid = () => {
    fetch(`/api/booking/${bid_id}`,{
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then(response => {
      if (response.message.type === 'success') {
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to accept bid.'})
      console.error('Error:', error)
    });
  }

  return (
    <div>
      <AlertDialog
        open = {accept}
        setOpen = {setAccept}
        title = 'Negotiate'
        content = 'By clicking negotiate you are agreeing to reveal your contact information to the bidder to finish negotiations'
        submitText = 'Negotiate'
        submit = {acceptBid}
      />
      <MaterialTable
        title = 'Bids on Your Listings'
        maxWidth={false}
        columns = {columns}
        data = {data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        options = {{
          filtering: true,
          draggable: false,
          actionsColumnIndex: 0,
          grouping: true,
        }}
        components={{
          Groupbar: () => null,
          FilterRow: props => <CustomFilter {...props} />
        }}
        actions = {[
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: (event) => fetchData(!toggle)
          },
          {
            icon : () => <CheckIcon color = 'primary'/>,
            tooltip : 'Negotiate',
            onClick: (event, rowData) => {
              setBidId(rowData.bid_id)
              setAccept(true)
            }
          },
          {
            icon : () => <CancelIcon color = 'primary'/>,
            tooltip : 'Reject Bid',
            onClick: (event, rowData) => {
              setBid({ ...bid, bid_id: rowData.bid_id });
              setOpen(true);
            }
          },
          {
            icon: () => <InfoIcon color = 'primary'/>,
            tooltip: 'More Info',
            onClick: (e, rowData) => {
              setBidId(rowData.bid_id)
              setOpenInfo(true)
            }
          }
        ]}
      />
      <InfoDialog
        open = {openInfo}
        onClose = {handleCloseInfo}
        type = 'listing'
        id = {bid_id}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth={true}
        >
          <DialogTitle>Reject Bid</DialogTitle>
          <DialogContent>
            <TextField
              required
              multiline
              fullWidth
              name='reason'
              label='Reason'
              value={bid.reason}
              onChange={e => setBid({...bid, reason: e.target.value})}
            />
          </DialogContent>
          <DialogActions>
            <div style = {{position: 'relative'}}>
              <Button onClick={handleSubmit} color="primary">
                Reject
              </Button>
              {loading &&
                <CircularProgress size={32} style = {{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -16,
                  marginLeft: -16
                  }}
                />
              }
            </div>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
