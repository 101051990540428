import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useChat } from '../../context/chat'
import AlertDialog from '../inputs/AlertDialog'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import Popover from '@material-ui/core/Popover'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import MenuItem from '@material-ui/core/MenuItem'
import ReportDialog from '../inputs/ReportDialog'
import InfoDialog from '../display/InfoDialog'

const useStyles = makeStyles((theme) => ({
  received: {
    'width': '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 40,
    'margin': theme.spacing(2)
  },
  sent: {
    'background-color': theme.palette.primary.main,
    'maxWidth': '85%',
    'minHeight': 40,
    'float': 'right',
    'border-style': 'solid',
    'borderColor': theme.palette.primary.main,
    'border-radius': 40,
    'paddingLeft': 10,
    'paddingRight': 10,
    'paddingTop': 10,
    'paddingBottom': 10,
    'overflow-x':'hidden',
    'overflowAnchor': 'auto',
  },
  filler: {
    'width': '100%',
  },
  divider: {
    'borderBottom': '2px solid',
  },
  input: {
    'width': '65%',
    'padding': theme.spacing(2),
  },
  buttonGroup: {
    'width': '30%',
  },
  container: {
    'height': '500px',
    overflow: 'auto',
    'borderBottom': '2px solid',
    'overflowAnchor': 'none'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: 10,
    marginRight:10,
    color: theme.palette.primary.main,
    paddingTop: 15,
    paddingBottom: 15,
    maxWidth: 170
  },
  infoButton: {
    position: 'absolute',
    right: 50,
    top: 0,
    height: 50,
    width: 50,
  },
  icon : {
    height: 30,
    width: 30
  }
}));

export default function Chatroom ({close, messages, setMessages, focus}) {
  const classes = useStyles()
  const { currentChat, fetchConversations, setFetchConversations } = useChat()
  const {setMessage} = useMessage();
  const { auth } = useAuth();
  const [chatMessage, setChatMessage] = useState('')
  const messagesEndRef = useRef(null)
  const [info, setInfo] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const anchorRef = useRef(null)
  const [leave,setLeave] = useState(false)
  const [closed, setClosed] = useState(false)
  const [report, setReport] = useState(false)
  const [type, setType] = useState('')
  const [id, setId] = useState(0)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView()
  }

  useEffect(() => {
    scrollToBottom()
  }, [focus])

  useEffect(() => {
    if (currentChat.conversation_id){
      fetch(`/api/message/conversation/${currentChat.conversation_id}`, {
        method: 'GET',
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        }
      })
      .then((response) => auth(response))
      .then((response) => {
        setMessages(response.data)
        setClosed(response.closed)
        updateRead(currentChat.conversation_id)
      })
      .catch((error) => {
        setMessage({'type': 'error', 'text': 'Error! Conversation does not exist.'})
        console.error('Error:', error);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchConversations, setMessage, currentChat.conversation_id])

  useEffect(() => {
    if(currentChat){
      if(currentChat.bid_id){
        setType('listing')
        setId(currentChat.bid_id)
      } else if (currentChat.inquiry_bids_id) {
        setType('inquiry')
        setId(currentChat.inquiry_bids_id)
      } else if (currentChat.listing_id && !currentChat.bid_id) {
        setType('listing_info')
        setId(currentChat.listing_id)
      } else if (currentChat.inquiry_id && !currentChat.inquiry_bids_id) {
        setType('inquiry_info')
        setId(currentChat.inquiry_id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const updateRead = (conversation_id) => {
    fetch(`/api/message/conversation/${conversation_id}`, {
      method: 'PUT',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
      },
    })
    .catch((error) => {
      console.error('Error:', error)
    })
  }

  const writeMessage = () => {
    if (chatMessage !== ''){
      fetch(`/api/message/conversation/${currentChat.conversation_id}`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/JSON'
        },
        body: JSON.stringify({'message':chatMessage}),
      })
      .then((response) => auth(response))
      .then((response) => {
        setMessages([...messages, {'message':chatMessage, 'author': true}])
        setChatMessage('')
      })
      .catch((error) => {
        setMessage({'type': 'error', 'text': 'Error! Unable to write message.'})
        console.error('Error:', error)
      })
    }
  }

  const openInfo = (event) => {
    setInfo(true)
  }

  const closeInfo = () => {
    setInfo(false)
  }

  const handleMessageChange = (event) => {
    if (chatMessage.concat('\n') !== event.target.value)
      setChatMessage(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter'){
      writeMessage()
    }
  }

  const openDropdown = () => {
    setDropdown(true)
  }

  const closeDropdown = () => {
    setDropdown(false)
  }

  const reportUser = () => {
    closeDropdown()
    setReport(true)
  }

  const openDialog = () => {
    closeDropdown()
    setLeave(true)
  }

  const leaveChat = () => {
    fetch(`/api/message/conversation/${currentChat.conversation_id}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
      },
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success'){
        setChatMessage('')
        setFetchConversations(!fetchConversations)
        setMessage(response.message)
        setLeave(false)
      }
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to leave chat.'})
      console.error('Error:', error)
    })
  }

  return (
    <div>
      <AlertDialog
        open = {leave}
        setOpen = {setLeave}
        title = 'Leave Chat'
        content = 'Once you leave the chat you will be unable to send or receive messages.
        However, you will still be able to see previous messages. Are you sure you want to leave the chat?'
        submitText = 'Leave Chat'
        submit = {leaveChat}
      />
      <ReportDialog
          open = {report}
          setOpen = {setReport}
          conversation_id = {currentChat.conversation_id}
      />
      <InfoDialog
        open = {info}
        onClose = {closeInfo}
        type = {type}
        id = {id}
      />
      <Paper>
        <Typography className={classes.heading}>
          {currentChat.conversation_title}
          <IconButton color = 'primary' className = {classes.infoButton} onClick = {(e) => openInfo(e)}>
            <InfoIcon className = {classes.icon}/>
          </IconButton>
          <IconButton aria-label="close" className = {classes.closeButton} onClick={close}>
            <CloseIcon className = {classes.icon}/>
          </IconButton>
        </Typography>

        <Divider className={classes.divider}/>
        <Container className={classes.container}>
          <List>
            {messages.map((message, key) => (
                <ListItem key = {key}>
                  <Grid item xs = {12}>
                    <Typography className = {classes.sent} style = {!message['author'] ? {float:'left', 'backgroundColor': '#424242'} : null}>
                      {message['message']}
                    </Typography>
                  </Grid>
                </ListItem>
            ))}
            {closed &&
              <ListItem key = 'closed'>
                <Grid item xs = {12}>
                  <Typography className = {classes.sent} style = {{'backgroundColor': 'red', 'borderColor': 'red'}}>
                    {'Chat has been closed!'}
                  </Typography>
                </Grid>
              </ListItem>
            }
            <div ref={messagesEndRef} />
          </List>
        </Container>
        <InputBase
          className = {classes.input}
          placeholder = 'Enter message here'
          value = {chatMessage}
          onChange = {handleMessageChange}
          onKeyDown = {handleKeyDown}
          multiline = {true}
          disabled = {closed}
        />
        <ButtonGroup variant = 'contained' color = 'primary' ref = {anchorRef} className = {classes.buttonGroup}>
          <Button
            onClick = {writeMessage}
          >
            Send
          </Button>
          <Button size = 'small' onClick = {openDropdown}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popover
          open= {dropdown}
          onClose = {closeDropdown}
          anchorEl = {anchorRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Paper>
              <MenuItem onClick = {reportUser}>
                Report User
              </MenuItem>
              {!closed &&
                <MenuItem onClick = {openDialog}>
                  Leave Chat
                </MenuItem>
              }
          </Paper>
        </Popover>
      </Paper>
    </div>
  )
}
