import React, { useEffect, useState } from 'react'
import { useAuth } from "../../context/auth";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  nested: {
    left: theme.spacing(3),
  },
  list: {
    'border-bottom': '1.5px solid #fc6c05',
  }
}));

export default function ProductList() {
  const classes = useStyles()
  const { auth } = useAuth()
  const [categories, setCategories] = useState([])
  const [lastClicked, setLastClicked] = useState('')

  const handleClick = (category) => {
    if (lastClicked === category){
      setLastClicked('')
    }
    else{
      setLastClicked(category)
    }
  }

  useEffect(() => {
    fetch('/api/context/products', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then((response) => {
      setCategories(response.data)
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <List>
        {categories && categories.map((row) => (
          <div key = {row.product_category}>
            <ListItem className = {classes.list} button onClick = {() => handleClick(row.product_category)}>
              <ListItemText primary = {row.product_category} />
              {row.product_category === lastClicked ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in = {lastClicked === row.product_category}>
              <List>
                {row.products && row.products.split(',').map((product) => (
                  <ListItem key = {product} className = {classes.nested}>
                    <ListItemText primary = {product}/>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
  )
}
