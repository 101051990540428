import React, { useState, useEffect } from 'react';
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { ComposedChart, Area, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import SimpleSelect from '../inputs/SimpleSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DateRangeFilter from '../inputs/DateRangeFilter';
import { useMessage } from '../../context/message';
import { useAuth } from '../../context/auth';
import { useField } from '../../context/context'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 500,
    minWidth: 1150
  },
  filter: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}));

const PriceCheckbox = withStyles({
  root: {
    color: '#03fc77',
    '&$checked': {
      color: '#03fc77',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const OpenInterestCheckbox = withStyles({
  root: {
    color: '#3f51b5',
    '&$checked': {
      color: '#3f51b5',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const today = new Date();

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};


export default function CombinedChart() {
  const theme = useTheme();
  const classes = useStyles();
  const [data, setData] = useState([{}]);
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const { regions, product_categories, fetchFields, setFetchFields } = useField()
  const [regions_list, setRegions_list] = useState([]);
  const [region, setRegion] = useState('');
  const [product_categories_list, setProduct_categories_list] = useState([]);
  const [product_category, setProduct_category] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: addDays(today, -183),
    endDate: addDays(today, 182)
  });
  const [display, setDisplay] = useState({
    price: true,
    open_interest: true,
    available_capacity: true
  })

  useEffect(() => {
    if (regions){
      setRegions_list(regions.map(function(item) {return {value: item.region, label: item.region}}))
    } else {
      setFetchFields(!fetchFields)
    }
    if (product_categories){
      setProduct_categories_list(product_categories.map(function(item) {return {value: item.product_category, label: item.product_category}}))
    } else {
      setFetchFields(!fetchFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[regions, product_categories])

  const handleDisplay = (event) => {
    setDisplay({ ...display, [event.target.name]: event.target.checked })
  }

  useEffect(() => {
    fetch(`/api/bid_ask?region=${region}&product_category=${product_category}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if(!response.message)
        setData(response);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch volume data.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[region, product_category, dateRange, setMessage])

  return (
    <Paper className={classes.root}>
      <Grid container justify = 'center'>
        <Typography variant = 'button' style = {{fontSize: 16, marginTop: 10}}>
          Bid Ask Spread
        </Typography>
      </Grid>
      <Grid container justify = 'center'>
        <SimpleSelect value={region} setValue={setRegion} label='Region' options={regions_list} />
        <SimpleSelect value={product_category} setValue={setProduct_category} label='Product Category' options={product_categories_list} />
        <FormControlLabel
          className={classes.filter}
          control={<DateRangeFilter
            value = {dateRange}
            onChange = {setDateRange}
            definedRanges = {[
              {
                label: 'YTD',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: today
              },
              {
                label: 'This Month',
                startDate: new Date(today.getFullYear(), today.getMonth(), 1),
                endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0)
              },
              {
                label: 'Next Month',
                startDate: new Date(today.getFullYear(), today.getMonth() + 1, 1),
                endDate: new Date(today.getFullYear(), today.getMonth() + 2, 0)
              },
              {
                label: 'This Quarter',
                startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4) * 3, 1),
                endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 0)
              },
              {
                label: 'Next Quarter',
                startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 1),
                endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 2) * 3, 0)
              },
              {
                label: 'This Year',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: new Date(today.getFullYear(), 12, 0)
              },
              {
                label: 'Next Year',
                startDate: new Date(today.getFullYear() + 1, 0, 1),
                endDate: new Date(today.getFullYear() + 1, 12, 0)
              },
              {
                label: 'Reset',
                startDate: addDays(today, -183),
                endDate: addDays(today, 182)
              },
            ]}
          />}
        />
      </Grid>
      <React.Fragment>
        <ResponsiveContainer height = '90%'>
          <ComposedChart
            data={data}
            margin={{
              top: 16,
              right: 16,
              bottom: 80,
              left: 24,
            }}
          >
            <XAxis
              dataKey="date"
              stroke={theme.palette.text.secondary}
              type='number'
              tickFormatter={(unixTime) => moment(unixTime).format('MMM YY')}
              domain={['auto', 'auto']}
            >
              {(data.length===0) && <Label value='No data matching specified parameters' position='top' offset={200} fill={theme.palette.text.primary} />}
            </XAxis>
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                Price ($/BBL)
              </Label>
            </YAxis>
            <Tooltip />
            <Legend verticalAlign='top'/>
            <Line
              connectNulls
              type="monotone"
              dataKey="bid"
              stroke='#3f51b5'
              dot={false}
            />
            <Line
              connectNulls
              type="monotone"
              dataKey="ask"
              stroke={theme.palette.primary.main}
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </React.Fragment>
    </Paper>
  );
}
