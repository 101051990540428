import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { useAuth } from "../../context/auth";
import TextField from "@material-ui/core/TextField"
import { useMessage } from "../../context/message";
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh'
import AlertDialog from '../inputs/AlertDialog'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

const useStyles = makeStyles((theme) => ({
  userInfo: {
    margin: theme.spacing(3),
    width: '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 10,
  },
}));

export default function MyPlacedRenewals() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false)
  //submit
  const [cancel, setCancel] = useState(false)
  const [renewalId, setRenewalId] = useState(0)
  //info
  const [info, setInfo] = useState(false)
  const [id, setId] = useState(0)

  useEffect(() => {
    fetch('/api/renew/placed', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch renewal requests.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage]);

  const cancel_renewal = (rowData) => {
    fetch(`/api/renew/cancel/${renewalId}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setMessage(response.message)
      setCancel(false)
      fetchData(!toggle)
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to delete renewal request.'})
      setCancel(false)
      console.error('Error:', error);
    })
  }

  const handleClose = () => {
    setInfo(false)
    setId(0)
  }

  return (
    <div>
        <MaterialTable
          title = "Renewal Requests"
          columns = {[
            {
              'title' : 'Location',
              'field' : 'address'
            },
            {
              'title' : 'Price (USD/BBL)',
              'field' : 'price',
              'type' : 'numeric',
              'bounds': bounds.price,
              'customFilterAndSearch': rangeFilter('price'),
              'step': .01
            },
            {
              'title' : 'Product',
              'field' : 'product'
            },
            {
              'title' : 'Volume (MBBL)',
              'field' : 'volume',
              'type' : 'numeric',
              'bounds': bounds.volume,
              'customFilterAndSearch': rangeFilter('volume'),
              'step': 1
            },
            {
              'title' : 'Start Date',
              'field' : 'start_date',
              'type' : 'date',
              'customFilterAndSearch' : dateRangeFilter('start_date'),
              customSort: (a,b) => {
                var date1 = new Date(a.start_date)
                var date2 = new Date(b.start_date)
        
                return date1 - date2
              },
              render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
            },
            {
              'title' : 'Term (Months)',
              'field' : 'term',
              'type': 'numeric',
              'bounds': bounds.term,
              'customFilterAndSearch': rangeFilter('term')
            }
          ]}
          data = {data}
          icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
          actions = {[{
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: (event) => fetchData(!toggle)
          },
          {
            icon: () => <InfoIcon color = 'primary'/>,
            tooltip: 'More Info',
            onClick: (event, rowData) => {
              setId(rowData.renewal_id)
              setInfo(true)
            }
          },
          {
            icon: () => <DeleteOutlineIcon color = 'primary'/>,
            tooltip: 'Delete',
            onClick: (event, rowData) => {
              setRenewalId(rowData.renewal_id)
              setCancel(true)
            }
          }
          ]}
          detailPanel = {
            rowData => {
              return(
              <Grid container spacing = {2} className = {classes.userInfo}>
                <Grid item xs>
                  <TextField
                    id="First_Name"
                    label="First Name"
                    value = {rowData.receiver_first_name || ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Last_Name"
                    label="Last Name"
                    value = {rowData.receiver_last_name || ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Company"
                    label="Company"
                    value = {rowData.receiver_company || ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item sm>
                  <TextField
                    id="email"
                    label="Email"
                    value = {rowData.receiver_email || ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Office_Phone"
                    label="Office Phone"
                    value = {rowData.receiver_office_phone || ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="Cell_Phone"
                    label="Cell Phone"
                    value = {rowData.receiver_cell_phone || ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            )
            }
          }
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          options={{
            filtering: true,
            draggable: false,
            actionsColumnIndex: 0
          }}
          components={
            {
              FilterRow: props => <CustomFilter {...props} />
            }
          }
        />
      <AlertDialog
        open = {cancel}
        setOpen = {setCancel}
        title =  'Delete Renewal Request'
        content = 'Are you sure you want to delete this request?'
        submitText = 'Delete'
        submit = {cancel_renewal}
       />
       <InfoDialog
         open = {info}
         onClose = {handleClose}
         type = 'renewal'
         id = {id}
       />
    </div>
  )
}
