import React from 'react';
import TeamListingTable from './TeamListingTable';
import TeamReceivedBidsTable from '../bid/TeamReceivedBidsTable';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(5)
  }
}))
export default function MyListingsPage() {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.table}>
        <TeamListingTable />
      </Box>
      <Box className={classes.table}>
        <TeamReceivedBidsTable />
      </Box>
    </Box>
  )
}
