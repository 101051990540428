import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'

const RailComponent = ({ map, maps, railData, strokeColor, visible }) => {
  const [polylines, setPolylines] = useState([]);
  const [selectedRail, setSelectedRail] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (map && maps && railData) {
      // Clear existing polylines when pipelineData changes or component mounts
      polylines.forEach(polyline => polyline.setMap(null)); // Remove existing polylines
      setPolylines([]); // Clear state of polylines

      // Render new polylines if visible is true
      if (visible) {
        const newPolylines = railData.features.map(feature => {
          if (feature.geometry && feature.geometry.type === 'LineString' && feature.geometry.coordinates) {

            const path = feature.geometry.coordinates.map(coord => ({
              lat: coord[1],
              lng: coord[0]
            }));
            const polyline = new maps.Polyline({
              path: path,
              strokeColor: strokeColor || '#FF0000', // Default to red if strokeColor not provided
              strokeOpacity: 1,
              strokeWeight: 1,
              zIndex: 2,
              strokePattern: [
                { offset: '0', repeat: '10px' }
              ],
              map: map
            });

            // Add event listeners
            polyline.addListener('mouseover', () => {
              polyline.setOptions({ strokeWeight: 4 });
            });
            polyline.addListener('mouseout', () => {
              polyline.setOptions({ strokeWeight: 2 });
            });
            polyline.addListener('click', () => {
              setSelectedRail(feature)
              setOpenDialog(true)
            });

            return polyline;
          }
          return null;
        });

        setPolylines(newPolylines.filter(polyline => polyline !== null)); // Update state with new polylines
      }
    }
  }, [map, maps, railData, strokeColor, visible]);

  // Hide or show polylines based on visibility
  useEffect(() => {
    polylines.forEach(polyline => {
      if (visible) {
        polyline.setMap(map);
      } else {
        polyline.setMap(null);
      }
    });
  }, [visible, map, polylines]);

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  return (
    <>
      {/* Material-UI Dialog for displaying pipeline information */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Rail Information</DialogTitle>
        <DialogContent>
          {selectedRail && (
            <>
              <p><strong>OBJECTID:</strong> {selectedRail.properties.OBJECTID}</p>
              <p><strong>FRAARCID:</strong> {selectedRail.properties.FRAARCID}</p>
              <p><strong>TOFRANODE:</strong> {selectedRail.properties.TOFRANODE}</p>
              <p><strong>STFIPS:</strong> {selectedRail.properties.STFIPS}</p>
              <p><strong>CNTYFIPS:</strong> {selectedRail.properties.CNTYFIPS}</p>
              <p><strong>STCNTYFIPS:</strong> {selectedRail.properties.STCNTYFIPS}</p>
              <p><strong>STATEAB:</strong> {selectedRail.properties.STATEAB}</p>
              <p><strong>COUNTRY:</strong> {selectedRail.properties.COUNTRY}</p>
              <p><strong>FRADISTRICT:</strong> {selectedRail.properties.FRADISTRICT}</p>
              <p><strong>RROWNER1:</strong> {selectedRail.properties.RROWNER1}</p>
              <p><strong>RROWNER2:</strong> {selectedRail.properties.RROWNER2}</p>
              <p><strong>RROWNER3:</strong> {selectedRail.properties.RROWNER3}</p>
              <p><strong>TRKRGHTS1:</strong> {selectedRail.properties.TRKRGHTS1}</p>
              <p><strong>TRKRGHTS2:</strong> {selectedRail.properties.TRKRGHTS2}</p>
              <p><strong>TRKRGHTS3:</strong> {selectedRail.properties.TRKRGHTS3}</p>
              <p><strong>TRKRGHTS4:</strong> {selectedRail.properties.TRKRGHTS4}</p>
              <p><strong>TRKRGHTS5:</strong> {selectedRail.properties.TRKRGHTS5}</p>
              <p><strong>TRKRGHTS6:</strong> {selectedRail.properties.TRKRGHTS6}</p>
              <p><strong>TRKRGHTS7:</strong> {selectedRail.properties.TRKRGHTS7}</p>
              <p><strong>TRKRGHTS8:</strong> {selectedRail.properties.TRKRGHTS8}</p>
              <p><strong>TRKRGHTS9:</strong> {selectedRail.properties.TRKRGHTS9}</p>
              <p><strong>DIVISION:</strong> {selectedRail.properties.DIVISION}</p>
              <p><strong>SUBDIV:</strong> {selectedRail.properties.SUBDIV}</p>
              <p><strong>BRANCH:</strong> {selectedRail.properties.BRANCH}</p>
              <p><strong>YARDNAME:</strong> {selectedRail.properties.YARDNAME}</p>
              <p><strong>PASSNGR:</strong> {selectedRail.properties.PASSNGR}</p>
              <p><strong>STRACNET:</strong> {selectedRail.properties.STRACNET}</p>
              <p><strong>TRACKS:</strong> {selectedRail.properties.TRACKS}</p>
              <p><strong>NET:</strong> {selectedRail.properties.NET}</p>
              <p><strong>MILES:</strong> {selectedRail.properties.MILES}</p>
              <p><strong>KM:</strong> {selectedRail.properties.KM}</p>
              <p><strong>TIMEZONE:</strong> {selectedRail.properties.TIMEZONE}</p>
              <p><strong>Shape_Length:</strong> {selectedRail.properties.Shape_Length}</p>
              {/* Add other properties as needed */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )};

export default RailComponent;
