import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { useMessage } from '../../context/message';
import { useAuth } from '../../context/auth';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useField } from '../../context/context'
import Paper from '@material-ui/core/Paper';
import SimpleSelect from '../inputs/SimpleSelect'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DateRangeFilter from '../inputs/DateRangeFilter';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ButtonGroup from  '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

const colors = [
  '#fc6c05',
  'orange',
  'gold',
  'yellow',
  '#03fc77',
  'mediumspringgreen',
  'mediumseagreen',
  'darkcyan',
  'cornflowerblue',
  'aqua',
  'paleturquoise',
  'red',
  'orangered'


]

const today = new Date();

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 630,
    minWidth:350
  },
  filter: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  button: {
    backgroundColor: '#954807',
    fontSize: 12,
    width:160,
    '&:hover' : {
      backgroundColor: '#fc6c05'
    }
  },
  selected: {
    fontSize: 12,
    width: 160,
    '&:hover' : {
      backgroundColor: '#fc6c05'
    }
  },
  small_button: {
    backgroundColor: '#954807',
    fontSize: 10,
    width:100,
    '&:hover' : {
      backgroundColor: '#fc6c05'
    }
  },
  small_selected: {
    fontSize: 10,
    width: 100,
    '&:hover' : {
      backgroundColor: '#fc6c05'
    }
  }
}));

const defaultData = [
  {product_category: " Heavy Crude", volume: 100},
  {product_category: " Light Crude", volume: 100},
  {product_category: " NGL/LPG", volume: 100}
]

export default function VolumePieChart() {
  const theme = useTheme();
  const classes = useStyles();
  const { auth, dataSub } = useAuth();
  const { setMessage } = useMessage();
  const [product_category, setProduct_category] = useState('')
  const [region, setRegion] = useState('')
  const [data, setData] = useState(defaultData);
  const { regions, product_categories, fetchFields, setFetchFields } = useField()
  const [regions_list, setRegions_list] = useState([])
  const [product_categories_list, setProduct_categories_list] = useState([])
  const [type, setType] = useState('region')
  const [available_capacity, setAvailableCapacity] = useState('available_capacity')
  const [dateRange, setDateRange] = useState({
    startDate: addDays(today, -183),
    endDate: addDays(today, 182)
  });

  useEffect(() => {
    if (regions){
      setRegions_list(regions.map(function(item) {return {value: item.region, label: item.region}}))
    } else {
      setFetchFields(!fetchFields)
    }
    if (product_categories){
      setProduct_categories_list(product_categories.map(function(item) {return {value: item.product_category, label: item.product_category}}))
    } else {
      setFetchFields(!fetchFields)
    }
    if (dataSub === 0){
      setRegion('PADD 1B')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[regions, product_categories])

  const byProduct = () => {
    setType('product_category')
    setRegion('')
    setProduct_category('Heavy Crude')
  }

  const byRegion = () => {
    setType('region')
    setProduct_category('')
    setRegion('PADD 1B')
  }

  useEffect(() => {
    fetch(`/api/volume?product_category=${product_category}&region=${region}&type=${available_capacity}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      if(!response.message)
        setData(response)
    })
    .catch(error => {
      setMessage({'type': 'error', 'text': 'Error! Unable to booked capacity data.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, product_category, setMessage, available_capacity, dateRange])

  return (
    <Paper className = {classes.root}>

      <Grid container justify = 'center'>
        <Typography variant = 'button' style = {{fontSize: 16, marginTop: 15}}>
          {available_capacity === 'available_capacity' || available_capacity === '' ? 'Available Capacity' : 'Open Interest'}
        </Typography>
      </Grid>
      <Grid container justify = 'center'>
        <Typography variant = 'button' style = {{fontSize: 12}}>
          {type === 'region' ? 'By Region' : 'By Product Category'}
        </Typography>
      </Grid>
      <Grid container justify = 'center'>
        <ButtonGroup color = 'primary' variant = 'contained'>
          <Button onClick = {(event) => {setAvailableCapacity('available_capacity')}} className = {available_capacity === 'available_capacity' ? classes.selected : classes.button}>
            Available Capacity
          </Button>
          <Button onClick = {(event) => {setAvailableCapacity('open_interest')}} className = {available_capacity === 'open_interest' ? classes.selected : classes.button}>
            Open Interest
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid style = {{marginTop:5}} container justify = 'center'>
        <ButtonGroup color = 'primary' variant = 'contained'>
          <Button onClick = {byRegion} className = {type === 'region' ? classes.small_selected : classes.small_button}>
            By Region
          </Button>
          <Button onClick = {byProduct} className = {type === 'product_category' ? classes.small_selected : classes.small_button}>
            By Product
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid container justify = 'center'>
        <FormControlLabel
          className={classes.filter}
          control={<DateRangeFilter
            value = {dateRange}
            onChange = {setDateRange}
            definedRanges = {[
              {
                label: 'YTD',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: today
              },
              {
                label: 'This Month',
                startDate: new Date(today.getFullYear(), today.getMonth(), 1),
                endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0)
              },
              {
                label: 'Next Month',
                startDate: new Date(today.getFullYear(), today.getMonth() + 1, 1),
                endDate: new Date(today.getFullYear(), today.getMonth() + 2, 0)
              },
              {
                label: 'This Quarter',
                startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4) * 3, 1),
                endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 0)
              },
              {
                label: 'Next Quarter',
                startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 1),
                endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 2) * 3, 0)
              },
              {
                label: 'This Year',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: new Date(today.getFullYear(), 12, 0)
              },
              {
                label: 'Next Year',
                startDate: new Date(today.getFullYear() + 1, 0, 1),
                endDate: new Date(today.getFullYear() + 1, 12, 0)
              },
              {
                label: 'Reset',
                startDate: addDays(today, -183),
                endDate: addDays(today, 182)
              },
            ]}
          />}
        />
      </Grid>
      <React.Fragment>
        <ResponsiveContainer height = '62%'>
          <PieChart width={400} height={300}>
            <Pie
              dataKey="volume"
              nameKey={type === 'region' ? 'product_category' : 'region'}
              data={data}
              fill={theme.palette.primary.main}
              outerRadius={100}
              label
            >
              {data && data.map((volume, index) =>
                <Cell key = {index} fill = {colors[index]} />
              )}
            </Pie>
            <Legend verticalAlign='bottom' />
            <Tooltip/>
          </PieChart>
        </ResponsiveContainer>
      </React.Fragment>
      <Grid container justify='center' >
        {type === 'region' ?
          <SimpleSelect value={region} setValue={setRegion} label='Region' options={regions_list} />
         :
          <SimpleSelect value={product_category} setValue={setProduct_category} label='Product Category' options={product_categories_list}/>
        }
      </Grid>
      {data.length === 0 &&
        <Grid container justify = 'center' style = {{marginTop: -100}}>
          <Typography>
            No data matching specified parameters
          </Typography>
        </Grid>
      }
    </Paper>
  )
}
