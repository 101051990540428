import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useAuth } from "../../context/auth";
import { useMessage } from "../../context/message";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  select: {
    width: 200,
  },
}));

const vesselSizeOptions = ['VLCC', 'Suezmax', 'Aframax', 'Panamax', 'LR', 'MR', 'OTB']

export default function BidForm({ bid, products, updateField, setBid }) {
  const classes = useStyles();
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [accessModes, setAccessModes] = useState([]);
  const [bidModes, setBidModes] = useState({});
  const [vessels, setVessels] = useState([]);
  const [showVessel, setShowVessel] = useState(false);

  useEffect(() => {
    fetch(`/api/listing/get_modes/${bid.listing_id}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setAccessModes(response.data || []);
      let temp = [];
      response.data.map(mode => (mode.type === 'Vessel') && temp.push(mode.mode_id))
      setVessels(temp);
    })
    .catch((error) => {
      setMessage({type: 'error', text: 'Error! Unable to fetch Access Modes'})
      console.error('Error', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage])

  const handleCheck = (e) => {
    setBidModes({...bidModes, [e.target.name]: e.target.checked})
  }

  useEffect(() => {
    let temp = [];
    for (const id in bidModes) {
      bidModes[id] && temp.push(parseInt(id))
    }
    setBid({...bid, modes: temp})
    setShowVessel(vessels.some(r => temp.includes(r)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidModes])

  return (
    <Container>
      <FormControl className = {classes.root}>
        <InputLabel>Product</InputLabel>
        <Select
          className={classes.select}
          value = {bid.product}
          onChange = {updateField}
          name = 'product'
          label = 'Product'
        >
          {products && products.map((item) =>
            <MenuItem key = {item} value = {item}> {item} </MenuItem>
          )}
        </Select>
      </FormControl>
      <TextField
        required
        className={classes.root}
        name = "bid_price"
        label = "Bid Price"
        value =  {bid.bid_price}
        onChange={updateField}
      />
      <TextField
        required
        className={classes.root}
        name = "volume"
        label = "Volume"
        value =  {bid.volume}
        onChange={updateField}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.root}
          format='MM/dd/yyyy'
          margin = "normal"
          label = "Start Date"
          name = "start_date"
          value = {bid.start_date}
          onChange = {(long, short) => setBid({...bid, start_date: short})}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      <TextField
        required
        className={classes.root}
        name = "term"
        label = "Term (Months)"
        value =  {bid.term}
        onChange={updateField}
      />
      <TextField
        required
        className={classes.root}
        name = "comment"
        label = "Comment"
        value =  {bid.comment}
        onChange={updateField}
      />
      {accessModes.length !== 0 &&
        <div>
          <Typography
            className={classes.root}
            variant='h6'
            >Select your modes of access
          </Typography>
          <Divider />
          <TableContainer className={classes.root} >
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Access Mode Name</TableCell>
                  <TableCell>Inbound / Outbound</TableCell>
                  <TableCell>Access Type</TableCell>
                  <TableCell>Transfer Fee</TableCell>
                  <TableCell>Dock Draft (ft)</TableCell>
                  <TableCell>LOA (ft)</TableCell>
                  <TableCell>Class One Rail</TableCell>
                  <TableCell>Rail Spots</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessModes.map((mode, index) => (
                  <TableRow key={index}>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={bidModes[mode.mode_id]}
                        onChange={handleCheck}
                        name={mode.mode_id}
                        color='primary'
                      />
                    </TableCell>
                    <TableCell>{mode.mode_name}</TableCell>
                    <TableCell>{mode.inbound_outbound}</TableCell>
                    <TableCell>{mode.type}</TableCell>
                    <TableCell>{mode.pump_over_rate}</TableCell>
                    <TableCell>{mode.dock_draft}</TableCell>
                    <TableCell>{mode.length_overall}</TableCell>
                    <TableCell>{mode.class_one_rail}</TableCell>
                    <TableCell>{mode.rail_spots}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {showVessel &&
          <div>
            <TextField
              className={classes.root}
              name = "vessel_name"
              label = "Vessel Name"
              value =  {bid.vessel_name}
              onChange={updateField}
            />
            <FormControl className = {classes.root}>
              <InputLabel>Vessel Size</InputLabel>
              <Select
                className={classes.select}
                value = {bid.vessel_size}
                onChange = {updateField}
                name = 'vessel_size'
                label = 'Vessel Size'
              >
                {vesselSizeOptions.map((item) =>
                  <MenuItem key = {item} value = {item}> {item} </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>}
        </div>}
    </Container>
  )
}
