import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AlertDialog from './AlertDialog'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: 350
  },
  label: {
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(1.5)
  },
  select: {
    width: 350
  },
  button: {
    margin: theme.spacing(2),
  },
  textfield: {
    width: 350
  },
  listItem: {
    width: 700
  }
}));


export default function AddProduct() {
  const { setMessage } = useMessage();
  const classes = useStyles()
  const { auth } = useAuth();
  const [categories, setCategories] = useState([])
  const [product, setProduct] = useState('')
  const [product_category, setProductCategory] = useState('')
  const [editProduct, setEditProduct] = useState('')
  const [newProduct, setNewProduct] = useState('')
  const [deleteProduct, setDeleteProduct] = useState(false)
  const [deleteProductName, setDeleteProductName] = useState('')
  const [update, setUpdate] = useState(false)
  const [fetchData, setFetchData] = useState(false)

  useEffect(() => {
    fetch('/api/context/products', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then((response) => {
      setCategories(response.data)
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData])

  const addProduct = () => {
      fetch(`/api/context/addProduct`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({product: product, product_category: product_category})
      })
      .then(response => auth(response))
      .then(response => {
        setMessage(response.message)
        setFetchData(!fetchData)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to add product'});
        console.error('Error:', error);
      })
  }

  const edit = (p) => {
    setNewProduct(p)
    setEditProduct(p)
  }

  const cancelUpdate = () => {
    setNewProduct('')
    setEditProduct('')
  }

  const productDelete = () => {
    fetch(`/api/context/deleteProduct`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({product: deleteProductName, product_category: product_category})
    })
    .then(response => auth(response))
    .then(response => {
      setMessage(response.message)
      setDeleteProductName('')
      setFetchData(!fetchData)
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to remove product'});
      console.error('Error:', error);
    })
  }

  const updateProduct = () => {
    fetch(`/api/context/updateProduct`, {
      method: 'PUT',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({newProduct: newProduct, product: editProduct, product_category: product_category})
    })
    .then(response => auth(response))
    .then(response => {
      setMessage(response.message)
      setFetchData(!fetchData)
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to update product'});
      console.error('Error:', error);
    })
  }

  return (
    <div>
      <AlertDialog
        open = {deleteProduct}
        setOpen = {setDeleteProduct}
        title = 'Delete Product'
        content = 'Are you sure you want to delete product?'
        submitText = 'Delete'
        submit = {productDelete}
      />
      <AlertDialog
        open = {update}
        setOpen = {setUpdate}
        title = 'Update Product'
        content = 'Are you sure you want to update product?'
        submitText = 'Update'
        submit = {updateProduct}
      />
      <Paper>
        <Grid container>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Product Category</InputLabel>
              <Select
                className={classes.select}
                value = {product_category}
                onChange = {(e) => {setProductCategory(e.target.value)}}
                name = 'product_category'
                label = 'Product Category'
                variant="outlined"
              >
                {categories && categories.map((item) =>
                  <MenuItem key = {item.product_category} value = {item.product_category}> {item.product_category} </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className = {classes.root}>
            <TextField
              required
              className = {classes.textfield}
              name = 'product'
              label = 'Product'
              value = {product}
              onChange = {(e) => setProduct(e.target.value)}
              variant = 'outlined'
            />
          </Grid>
          <Grid item className = {classes.root}>
            <Button onClick = {addProduct} variant = 'contained' color = 'primary' style = {{top: 10}}>
              Add
            </Button>
          </Grid>
        </Grid>
        {/* display products */}
        <List className = {classes.root} >
          {categories && categories.map((item) =>
            <div>
              {item.product_category === product_category && item.products.split(',').map((p) =>{
              return(
                <ListItem key = {p} className = {classes.listItem}>
                  <ListItemIcon>
                    <IconButton onClick = {() => edit(p)} color = 'primary'>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick = {()=> {setDeleteProduct(true); setDeleteProductName(p)}} color = 'primary'>
                      <DeleteOutlineIcon/>
                    </IconButton>
                  </ListItemIcon>
                  {editProduct == p ?
                    <TextField
                      required
                      className = {classes.textfield}
                      value = {newProduct}
                      onChange = {(e) => setNewProduct(e.target.value)}
                      variant = 'outlined'
                    />
                    :
                    <ListItemText
                      primary = {p}
                    />
                  }
                  {editProduct == p &&
                    <Button onClick = {() => setUpdate(true)} variant = 'contained' color = 'primary' style = {{left: 10}}>
                      Update
                    </Button>
                  }
                  {editProduct == p &&
                    <Button onClick = {cancelUpdate} variant = 'contained' color = 'primary' style = {{left: 20}}>
                      Cancel
                    </Button>
                  }

                </ListItem>)}
              )}
            </div>
          )}
        </List>
      </Paper>
    </div>
  )
}
