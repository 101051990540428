import Typography from '@material-ui/core/Typography'
import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'

const inquiryTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    }
  }
})

export default function Legend({showListings, showInquirys, setShowListings, setShowInquirys}) {

  const listings = () => {
    setShowListings(!showListings)
  }

  const inquirys = () => {
    setShowInquirys(!showInquirys)
  }

  return (
      <div>
        <Grid item xs = {12}>
          <IconButton
            size = 'small'
            onClick = {listings}
          >
              <LocationOnIcon color = 'primary'/>
              <Typography>
                  Listing
              </Typography>
          </IconButton>
          <Checkbox checked = {showListings} onChange = {listings} color = 'primary' size = 'small'/>
        </Grid>
        <Grid item xs = {12}>
          <IconButton
            size = 'small'
            onClick = {inquirys}
          >
            <ThemeProvider theme = {inquiryTheme}>
              <LocationOnIcon color = 'primary'/>
            </ThemeProvider>
            <Typography>
                Inquiry
            </Typography>
          </IconButton>
          <Checkbox checked = {showInquirys} onChange = {inquirys} color = 'primary' size = 'small'/>
        </Grid>
      </div>
  )
}
