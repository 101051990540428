import React from 'react'
import {
  useParams
} from "react-router-dom";
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    'text-align':'center'
  },
  button: {
    width: '250px',
    margin: theme.spacing(1),
  },
  error: {
    margin: theme.spacing(1)
  }
}));

export default function ErrorPage() {
  let {error} = useParams()
  const classes = useStyles();


  return(
      <Paper>
        <Typography variant = 'h5' className = {classes.text}>
          Something went wrong!
        </Typography>
        <Typography className = {classes.error}>
          {error}
        </Typography>
        <Grid item xs = {12} align='center'>
          <Button href = {'/'} color = 'primary' variant = 'contained' className = {classes.button}>
            Return to Dashboard
          </Button>
        </Grid>
      </Paper>
  )
}
