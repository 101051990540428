import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MyReceivedRenewals from './MyReceivedRenewals'
import MyPlacedRenewals from './MyPlacedRenewals'

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: theme.palette.secondary.secondary,
    },
    tabSelect: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        textColor: theme.palette.secondary.main,
      },
    },
    paper: {
      'marginTop':theme.spacing(15),
    }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
      </div>
  );
}

export default function MyRenewals(){
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <div>
        <Paper>
          <Tabs
            indicatorColor = 'primary'
            textColor = 'primary'
            variant = 'fullWidth'
            onChange = {handleChange}
            value = {value}
            className = {classes.tab}
            >
              <Tab label = 'Received Renewal Requests' id = {0} className = {value!==0 ? classes.tabSelect : classes.tab}/>
              <Tab label = 'Placed Renewal Requests' id = {1} className = {value!==1 ? classes.tabSelect : classes.tab}/>
            </Tabs>
          </Paper>
          <TabPanel value={value} index={0}>
            <MyReceivedRenewals />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MyPlacedRenewals />
          </TabPanel>
      </div>
    )
}
