import React, {useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AlertDialog from '../inputs/AlertDialog'

import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  text: {
    margin: theme.spacing(2),
    width:500
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

export default function TankWatchApiPreference (){
  const [save, setSave] = useState(false)
  const classes = useStyles()
  const { auth, setTankwatch } = useAuth()
  const { setMessage } = useMessage()
  const [api_token, setApiToken] = useState('')

  const handleSubmit = () => {
    fetch('/api/user/tankwatch', {
      method: 'PUT',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({tankwatch_api: api_token})
    })
    .then(response => auth(response))
    .then(response => {
      setSave(false)
      setMessage(response.message);
      setTankwatch(api_token)
      sessionStorage.setItem('tankwatch', api_token)
    })
    .catch(error => {
      setSave(false)
      setMessage({type: 'error', text: 'Error! Unable to set token'});
      console.error('Error:', error);
    })
  }

  return (
    <div>
      <AlertDialog
        open = {save}
        setOpen = {setSave}
        title = 'Save API Token'
        content = 'Are you sure you want to save token? Doing so will overwrite any previous token.'
        submitText = 'Save'
        submit = {handleSubmit}
      />
      <Paper>
        <Grid container>
          <Typography color='primary' className={classes.heading}>TankWatch API Token:</Typography>
        </Grid>
        <TextField
          label = 'API Token'
          variant = 'outlined'
          className = {classes.text}
          value = {api_token}
          onChange = {(e) => setApiToken(e.target.value)}
        />
        <Button
          className = {classes.button}
          variant = 'contained'
          color = 'primary'
          onClick = {() => setSave(true)}
          >
          Submit
        </Button>
      </Paper>
    </div>
  )
}
