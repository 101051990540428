import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAuth } from '../../context/auth';

export default function SessionExpired() {
  const { expired, setExpired, setIsAuthenticated } = useAuth();

  const handleClose = () => {
    setExpired(false);
    sessionStorage.removeItem('token');
    setIsAuthenticated(false);
  }

  return (
    <Dialog
      open={expired}
    >
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session has expired. You must log back in to continue.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}
