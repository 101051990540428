import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginRight:10,
    color: theme.palette.primary.main
  },
}));

export default function AccountTypeInfo() {
  const classes = useStyles()

  return (
    <div>
      <Typography variant='h6' className = {classes.heading}>
        Viewer
      </Typography>
      <Typography>
        A viewer can only view listings and inquiries.
      </Typography>
      <Typography variant='h6' className = {classes.heading}>
        Trader
      </Typography>
      <Typography>
        A trader can view listings and inquiries. They can place bids on listings and create inquiries.
      </Typography>
      <Typography variant='h6' className = {classes.heading}>
        Terminal
      </Typography>
      <Typography>
        A terminal can view listings and inquiries. They can place bids on listings and create inquiries. They can also place their own listings and place bids on inquiries.
      </Typography>
    </div>
  )
}
