import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

const PADDComponent = ({ map, maps, paddData, fillColor, strokeColor, visible }) => {
  const [polygons, setPolygons] = useState([]);
  const [selectedPadd, setSelectedPadd] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);


  const convertCoordinates = (coordinates, type) => {
    if (type === 'MultiPolygon'){
      return coordinates.map(polygon => {
        return polygon[0].map(([lng, lat]) => {
          return { lat, lng };
        });
      });
    }
    else if (type === 'Polygon') {
      return coordinates.map(polygon => {
        return polygon.map(([lng, lat]) => {
          return { lat, lng}
        })
      })
    }
}
  useEffect(() => {
    if (map && maps && paddData) {
      // Clear existing polygons when paddData changes or component mounts
      polygons.forEach(polygon => polygon.setMap(null)); // Remove existing polygons
      setPolygons([]); // Clear state of polygons

      // Render new polygons if visible is true
      if (visible) {
        const newPolygons = paddData.features.map(feature => {
          if (feature.geometry && (feature.geometry.type === 'MultiPolygon' || feature.geometry.type === 'Polygon') && feature.geometry.coordinates) {
            const paths = convertCoordinates(feature.geometry.coordinates, feature.geometry.type)
            const polygon = new maps.Polygon({
              paths: paths,
              strokeColor: '#B9B4B3',
              strokeOpacity: .35,
              strokeWeight: 2,
              fillColor: '#B9B4B3',
              fillOpacity: 0.1,
              zIndex: 0,
              map: map
            });

            // Add event listeners
            polygon.addListener('mouseover', () => {
              polygon.setOptions({ strokeWeight: 4 });
            });
            polygon.addListener('mouseout', () => {
              polygon.setOptions({ strokeWeight: 2 });
            });
            polygon.addListener('click', () => {
              setSelectedPadd(feature);
              setOpenDialog(true);
            });

            return polygon;
          }
          return null;
        });

        setPolygons(newPolygons.filter(polygon => polygon !== null)); // Update state with new polygons
      }
    }
  }, [map, maps, paddData, strokeColor, fillColor, visible]);

  // Hide or show polygons based on visibility
  useEffect(() => {
    polygons.forEach(polygon => {
      if (visible) {
        polygon.setMap(map);
      } else {
        polygon.setMap(null);
      }
    });
  }, [visible, map, polygons]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {/* Material-UI v4 Dialog for displaying PADD information */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>PADD Information</DialogTitle>
        <DialogContent>
          {selectedPadd && (
            <>
              <p><strong>PADD:</strong> {selectedPadd.properties.PADD}</p>
              <p><strong>Name:</strong> {selectedPadd.properties.Name}</p>
              {/* Add other properties as needed */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PADDComponent;
