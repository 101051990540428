import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import CustomFilter from '../CustomFilter';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import FileViewer from '../display/FileViewer'

export default function TeamInquiryTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [data, setData] = useState([]);
  const [inquiry_id, setInquiryId] = useState(0)
  const [viewFile, setViewFile] = useState(false)

  useEffect(() => {
    fetch('/api/inquiry/team', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setData(response.data || []);
      setBounds && setBounds(response.bounds || {});
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch storage data'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage, setBounds])

  const columns = [
    {
      title: 'Username',
      field: 'username',
      defaultGroupOrder: 0
    },
    {
      title: 'Inquiry ID',
      field: 'inquiry_id',
    },
    {
      title: 'Location',
      field: 'formatted_address',
    },
    {
      title: 'Radius (mi)',
      field: 'radius',
      type: 'numeric',
      bounds: bounds.radius,
      customFilterAndSearch: rangeFilter('radius'),
    },
    {
      title: 'Product',
      field: 'product',
    },
    {
      title: 'Bid Price (USD/BBL)',
      field: 'bid_price',
      type: 'numeric',
      bounds: bounds.bid_price,
      customFilterAndSearch: rangeFilter('bid_price'),
    },
    {
      title: 'Min Volume (MBBL)',
      field: 'min_volume',
      type: 'numeric',
      bounds: bounds.min_volume,
      customFilterAndSearch: rangeFilter('min_volume'),
    },
    {
      title: 'Max Volume (MBBL)',
      field: 'max_volume',
      type: 'numeric',
      bounds: bounds.max_volume,
      customFilterAndSearch: rangeFilter('max_volume'),
    },
    {
      title: 'Term (Months)',
      field: 'term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Start Date',
      field: 'start_date',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('start_date'),
    },
    {
      title: 'Comment',
      field: 'comment',
    },
  ];

  return (
    <div>
      <FileViewer open = {viewFile} setOpen = {setViewFile} id = {inquiry_id} type = 'inquiry' />
      <MaterialTable
        title='Our Inquiries'
        columns={columns}
        data={data}
        options={{
          filtering: true,
          draggable: false,
          exportButton: true,
          grouping: true
        }}
        components={{
          Groupbar: () => null,
          FilterRow: props => <CustomFilter {...props} />,
        }}
        actions={[
          {
            icon: () => <RefreshIcon/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: event => fetchData(!toggle)
          },
          {
            icon: () => <AttachFileIcon/>,
            tooltip: 'View Attachments',
            onClick: (event, rowData) => {
              setInquiryId(rowData.inquiry_id)
              setViewFile(true)
            }
          }
        ]}
      />
    </div>
  );
}
