import React, { useState } from 'react';
import TeamTerminalBookings from './TeamTerminalBookings';
import TeamTraderBookings from './TeamTraderBookings';
import TeamRenewals from './TeamRenewals';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TeamBookingCalendar from '../display/TeamBookingCalendar'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(5)
  },
  tab: {
    backgroundColor: theme.palette.secondary.secondary,
    },
  tabSelect: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      textColor: theme.palette.secondary.main,
    },
  },
  paper: {
    'marginTop':theme.spacing(15),
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
      </div>
  );
}

export default function TeamBookingsPage() {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0)
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (event, newValue) => (
    setValue2(newValue)
  )

  return (
    <Box>
      <Box className={classes.table}>
        <Tabs
          indicatorColor = 'primary'
          textColor = 'primary'
          variant = 'fullWidth'
          onChange = {handleChange}
          value = {value}
          className = {classes.tab}
          >
            <Tab label = 'Calendar' id = {0} className = {value!==0 ? classes.tabSelect : classes.tab}/>
            <Tab label = 'Table' id = {1} className = {value!==1 ? classes.tabSelect : classes.tab}/>
          </Tabs>
        <TabPanel value={value} index={0}>
          <TeamBookingCalendar type = 'terminal'/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TeamTerminalBookings />
        </TabPanel>
      </Box>
      <Box className={classes.table}>
        <Tabs
          indicatorColor = 'primary'
          textColor = 'primary'
          variant = 'fullWidth'
          onChange = {handleChange2}
          value = {value2}
          className = {classes.tab}
          >
            <Tab label = 'Calendar' id = {0} className = {value2!==0 ? classes.tabSelect : classes.tab}/>
            <Tab label = 'Table' id = {1} className = {value2!==1 ? classes.tabSelect : classes.tab}/>
          </Tabs>
        <TabPanel value={value2} index={0}>
          <TeamBookingCalendar type = 'trader'/>
        </TabPanel>
        <TabPanel value={value2} index={1}>
          <TeamTraderBookings />
        </TabPanel>
      </Box>
      <Box className={classes.table}>
        <TeamRenewals />
      </Box>
    </Box>
  )
}
