import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { Link } from 'react-router-dom';
import BatteryStdIcon from '@material-ui/icons/BatteryStd';
import ListIcon from '@material-ui/icons/List';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function MyTeam({ open, setOpen }) {
  const classes = useStyles();

  const handleClick = () => {
    setOpen({...open, team: !open.team});
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary="My Team" />
        {open.team ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open.team} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink button className={classes.nested} component={Link} to='/team/members'>
            <ListItemIcon>
              <PeopleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Members" />
          </ListItemLink>
          <ListItemLink button className={classes.nested} component={Link} to='/team/storage'>
            <ListItemIcon>
              <BatteryStdIcon />
            </ListItemIcon>
            <ListItemText primary="Our Storage" />
          </ListItemLink>
          <ListItemLink button className={classes.nested} component={Link} to='/team/listing'>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Our Listings" />
          </ListItemLink>
          <ListItemLink button className={classes.nested} component={Link} to='/team/inquiry'>
            <ListItemIcon>
              <BatteryUnknownIcon />
            </ListItemIcon>
            <ListItemText primary="Our Inquiries" />
          </ListItemLink>
          <ListItemLink button className={classes.nested} component={Link} to='/team/bid'>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Our Bids" />
          </ListItemLink>
          <ListItemLink button className={classes.nested} component={Link} to='/team/booking'>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Our Bookings" />
          </ListItemLink>
        </List>
      </Collapse>
    </div>
  );
}
