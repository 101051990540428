import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const ColorCheckbox = withStyles((theme) => ({
  root: {
    color: (props) => props.color,
  '&$checked': {
    color: (props) => props.color
  },
  transform: 'scale(0.7)',
},
checked:{},
}))((props) => <Checkbox color="default" {...props} />);

export default function customLegend({display, setDisplay, colors}) {

  const toggleDisplay = (entity) => {
   setDisplay((prevDisplay) => ({
     ...prevDisplay,
     [entity]: !prevDisplay[entity]
   }));
  };
  return (
    <Grid container spacing={1} style={{display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',}}>
      {Object.keys(display).map((key, index) => (
        <Grid item style = {{marginBottom:-15, display:'flex', alignItems:'center'}}>
          <FormControlLabel
            control = {
              <ColorCheckbox
                id = {key}
                checked = {display[key]}
                onClick = {() => toggleDisplay(key)}
                color = {colors[index]}
                size = 'small'
              />
            }
            label = {<Typography variant="body2">{key}</Typography>}

          />
        </Grid>
      ))}
    </Grid>
  )
}
