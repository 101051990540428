import React, { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import FileViewer from '../display/FileViewer'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

export default function MyReceivedBidsTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [toggle, fetchData] = useState(false);
  const [bounds, setBounds] = useState({});
  const [bid_id, setBidId] = useState(0)
  const [viewFile, setViewFile] = useState(false)

  useEffect(() => {
    fetch('/api/bid/received/team', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch received bids.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage]);

  const columns = [
    {
      title: 'Username',
      field: 'username',
      defaultGroupOrder: 0
    },
    {
      title: 'Listing Id',
      field: 'listing_id',
      defaultGroupOrder: 1,
    },
    {
      title: 'Storage Name',
      field: 'storage_name'
    },
    {
      title: 'Product',
      field: 'product'
    },
    {
      title: 'Bid Price (USD/BBL)',
      field: 'bid_price',
      type: 'numeric',
      bounds: bounds.bid_price,
      customFilterAndSearch: rangeFilter('bid_price'),
    },
    {
      title: 'Ask Price (USD/BBL)',
      field: 'ask_price',
      type: 'numeric',
      bounds: bounds.ask_price,
      customFilterAndSearch: rangeFilter('ask_price'),
    },
    {
      title: 'Start Date',
      field: 'start_date',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('start_date'),
    },
    {
      title: 'Term (Months)',
      field: 'bid_term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Comment',
      field: 'bid_comment'
    }
  ];

  return (
    <div>
      <FileViewer open = {viewFile} setOpen = {setViewFile} id = {bid_id} type = 'bid' />
      <MaterialTable
        title = 'Bids on Your Listings'
        maxWidth={false}
        columns = {columns}
        data = {data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        options = {{
          filtering: true,
          draggable: false,
          actionsColumnIndex: 0,
          grouping: true,
        }}
        components={{
          Groupbar: () => null,
          FilterRow: props => <CustomFilter {...props} />
        }}
        actions = {[
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: (event) => fetchData(!toggle)
          },
          {
            icon: () => <AttachFileIcon color = 'primary'/>,
            tooltip: 'View Attachments',
            onClick: (event, rowData) => {
              setBidId(rowData.bid_id)
              setViewFile(true)
            }
          }
        ]}
      />
    </div>
  );
}
