import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import { useAuth } from "../../context/auth";
import { useMessage } from "../../context/message";
import { useField } from '../../context/context'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SignUp from './SignUp'

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://thetanktiger.com/">
        The Tank Tiger
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const circleSize = 48;
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginRight:10,

    color: theme.palette.primary.main
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: (-circleSize / 2),
    marginLeft: (-circleSize / 2),
  },
  link: {
    cursor: 'pointer'
  },
  signUp: {
    cursor: 'pointer',
    float: 'right'
  },
  email: {
    minWidth: 300
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { isAuthenticated, setIsAuthenticated, setUsername, setUserCategory ,
    setUser_id, setTeam_id, setExpired, setTutorialStep, setDataSub, setTankwatch, setOverlayAccess } = useAuth();
  const { setMessage } = useMessage();
  const { fetchFields, setFetchFields } = useField()
  const { auth } = useAuth();
  let referer = '/'
  if (props.location.state){
    referer = props.location.state.referer
  }
  else(
    referer = '/'
  )

  const [forgot, setForgot] = useState(false)
  const [signUp, setSignUp] = useState(false)
  const [email, setEmail] = useState('')

  const forgotPassword = () => {
    setForgot(true)
  }

  const recoverPassword = () => {
    const form = new FormData();
    form.set('email', email)

    fetch('/api/user/changePassword', {
      method: 'POST',
      body: form,
    })
    .then((response) => auth(response))
    .then((data) => {
      setMessage(data.message);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

    handleClose()
  }

  const closeSignUp = () => {
    setSignUp(false)
  }

  const signUpClick = () => {
      setSignUp(true)
  }

  const handleClose = () => {
    setForgot(false)
    setEmail('')
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);

    setLoading(true);
    fetch('/api/login', {
      method: 'POST',
      body: form,
    })
    .then((response) => auth(response))
    .then((data) => {
      if (data.isAuthenticated) {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('username', data.username);
        sessionStorage.setItem('category', data.category);
        sessionStorage.setItem('user_id', data.user_id);
        sessionStorage.setItem('team_id', data.team_id);
        sessionStorage.setItem('tutorialStep', data.tutorial_step)
        sessionStorage.setItem('dataSub', data.data_sub)
        sessionStorage.setItem('tankwatch_api', data.tankwatch_api)
        sessionStorage.setItem('overlayAccess', data.overlayAccess)

        setIsAuthenticated(data.isAuthenticated);
        setUsername(data.username);
        setUserCategory(data.category);
        setUser_id(data.user_id)
        setTeam_id(data.team_id);
        setTutorialStep(data.tutorial_step)
        setTankwatch(data.tankwatch_api)
        setOverlayAccess(data.overlayAccess)
        setDataSub(data.data_sub)
        setLoading(false);
        setExpired(false);
        setFetchFields(!fetchFields)
      } else {
        setMessage(data.message);
        setIsAuthenticated(data.isAuthenticated);
        setLoading(false);
    }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  if (isAuthenticated) {
    return <Redirect to={referer} />
  }

  return (
    <Container component="main" maxWidth="xs">
      <Dialog open={signUp} onClose={closeSignUp}>
        <DialogTitle className = {classes.closeButton} onClick={closeSignUp}>
          <IconButton aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <SignUp close = {closeSignUp}/>
        </DialogContent>
      </Dialog>
      <Dialog open={forgot} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className = {classes.heading}>Recover Password</DialogTitle>
        <DialogContent>
          <Grid container spacing = {3}>
            <Grid item>
              <Typography>
                Please enter email address.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing = {2}>
            <Grid item>
            <TextField
                id = 'email'
                label='Email Address'
                variant='outlined'
                value={email}
                className = {classes.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={recoverPassword} form = "email">
            Submit
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {loading && <CircularProgress size={circleSize} className={classes.buttonProgress}/>}
        </div>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link variant="body2" onClick = {forgotPassword} className = {classes.link}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs>
              <Link variant="body2" onClick = {signUpClick} className = {classes.signUp}>
                Sign up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
