import React, { useState, useEffect } from 'react'
import { useMessage } from '../../context/message'
import { useTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { useAuth } from '../../context/auth'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DateRangeFilter from '../inputs/DateRangeFilter'
import CustomLegend from '../display/CustomLegend'
import { LineChart, Legend, Line, Label, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 500,
    minWidth: 1150
  },
  filter: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}));

const today = new Date();

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {

    return (
      <Paper style={{
        backgroundColor: '#fff',
        padding: '2.5px',
        border: '1px solid #ccc',

      }}>
        <p className="label" style = {{'color': 'black'}}>{`Date: ${label}`}</p> {/* X-axis value shown here */}
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color, margin:0.5 }}>
            {`${entry.name}: ${entry.value.toLocaleString(undefined, {maximumFractionDigits: 2})}`}
          </p>
        ))}
      </Paper>
    );
  }

  return null;
};

export default function TankWatch(){
  const theme = useTheme();
  const [data, setData] = useState([])
  const [volumeData, setVolumeData] = useState([])
  const classes = useStyles();
  const { setMessage } = useMessage()
  const { auth, tankwatch_api } = useAuth()
  const [dateRange, setDateRange] = useState({
    startDate: addDays(today, -365),
    endDate: addDays(today, 0)
  });
  const [display, setDisplay] = useState({
    'Barcas': true,
    'Centurion': true,
    'Enbridge': true,
    'Energy Transfer Partners': true,
    'Enterprise': true,
    'Getka': true,
    'HCS Holdings': true,
    'Keyera': true,
    'Kinder Morgan': true,
    'Magellan': true,
    'NGL Energy Partners': true,
    'Phillips66': true,
    'Plains All American': true,
    'Rose Rock Midstream': true,
    'Transcanada oil': true,
    'Open Interest(Tank Tiger)': true
  })

  const colors = [
    'orange',
    'gold',
    'yellow',
    '#03fc77',
    'mediumspringgreen',
    'mediumseagreen',
    '#3f51b5',
    'darkcyan',
    'cornflowerblue',
    'aqua',
    'paleturquoise',
    'red',
    'orangered',
    'black',
    'grey',
    '#fc6c05',
  ]

  useEffect(() => {
    fetch(`/api/volume_monthly?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if(!response.message)
        setVolumeData(response);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch volume data.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tankwatch_api, dateRange])

  useEffect ( () => {
    if (tankwatch_api){
      fetch(`https://tw-cushing-api.gsi-vip.com/data?dateFrom=${dateRange.startDate}&dateTo=${dateRange.endDate}`, {
        method: 'GET',
        headers: {
          'authorization': tankwatch_api
        }
      })
      .then((response) => response.json())
      .then((response) => {
          setData(cleanData(response));
      })
      .catch((error) => {
        console.error('Error:', error);
      })
    }
  }, [tankwatch_api, dateRange])

  useEffect(() => {
    if(data && volumeData){
      setData(addOpenInterestField(data, volumeData))
    }
  },[volumeData,])

  function addOpenInterestField(data, volumeData) {
    return data.map(entry => {
      // Find the closest matching date in volumeData
      const closestVolumeData = volumeData.reduce((prev, curr) => {
        return Math.abs(new Date(curr.date) - new Date(entry.date)) < Math.abs(new Date(prev.date) - new Date(entry.date)) ? curr : prev;
      });

      // Add the Open Interest field to the entry
      return {
        ...entry,
        'Open Interest(Tank Tiger)': closestVolumeData ? closestVolumeData.volume : null // Add Open Interest, or null if no match
      };
    });
  }

  const cleanData = (data) => {
    const transformedData = {};

  // Iterate through each entry in the original data
  data.forEach(entry => {
    const { operator, storage, measured_at } = entry;
    const date = measured_at.slice(0, 10); // Extract YYYY-MM-DD from measured_at

    // Create a new entry in transformedData if it doesn't exist
    if (!transformedData[date]) {
      transformedData[date] = {};
    }

    // Add storage value for the operator on that date
    transformedData[date][operator] = storage;
  });

  // Convert transformedData into an array of objects with date and operator storage values
  const result = Object.keys(transformedData).map(date => {
    const storageObj = transformedData[date];
    return { date, ...storageObj };
  });

  // Sort the result by date
  result.sort((a, b) => a.date.localeCompare(b.date));
  return result;
  }

  const handleClick = (e) => {
    setDisplay({...display, [e.dataKey]: false})
    console.log('event: ', display)
  }

  return (
    <Paper className = {classes.root}>
      <Grid container justify='center'>
        <Typography>
          TankWatch Storage Data
        </Typography>
      </Grid>
      <Grid container justify = 'center'>
        <FormControlLabel
          className={classes.filter}
          control={<DateRangeFilter
            value = {dateRange}
            onChange = {setDateRange}
            definedRanges = {[
              {
                label: 'YTD',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: today
              },
              {
                label: 'This Month',
                startDate: new Date(today.getFullYear(), today.getMonth(), 1),
                endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0)
              },
              {
                label: 'This Quarter',
                startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4) * 3, 1),
                endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 0)
              },
              {
                label: 'This Year',
                startDate: new Date(today.getFullYear(), 0, 1),
                endDate: new Date(today.getFullYear(), 12, 0)
              },
              {
                label: 'Reset',
                startDate: addDays(today, -183),
                endDate: addDays(today, 182)
              },
            ]}
          />}
        />
      </Grid>
      <ResponsiveContainer height = '90%'>
        <LineChart
          data = {data}
          margin={{
            top: 16,
            right: 16,
            bottom: 80,
            left: 24,
          }}
        >
          {display['Barcas'] &&
            <Line dataKey= 'Barcas' stroke = 'orange'/>
          }
          {display['Centurion'] &&
            <Line dataKey = 'Centurion' stroke = 'gold'/>
          }
          {display['Enbridge'] &&
            <Line dataKey = 'Enbridge' stroke = 'yellow'/>
          }
          {display['Energy Transfer Partners'] &&
            <Line dataKey = 'Energy Transfer Partners' stroke = '#03fc77'/>
          }
          {display['Enterprise'] &&
            <Line dataKey = 'Enterprise' stroke = 'mediumspringgreen'/>
          }
          {display['Getka'] &&
            <Line dataKey = 'Getka' stroke = 'mediumseagreen'/>
          }
          {display['HCS Holdings'] &&
            <Line dataKey = 'HCS Holdings' stroke = '#3f51b5'/>
          }
          {display['Keyera'] &&
            <Line dataKey = 'Keyera' stroke = 'darkcyan'/>
          }
          {display['Kinder Morgan'] &&
            <Line dataKey = 'Kinder Morgan' stroke = 'cornflowerblue'/>
          }
          {display['Magellan'] &&
            <Line dataKey = 'Magellan' stroke = 'aqua'/>
          }
          {display['NGL Energy Partners'] &&
            <Line dataKey = 'NGL Energy Partners' stroke = 'paleturquoise'/>
          }
          {display['Phillips66'] &&
            <Line dataKey = 'Phillips66' stroke = 'red'/>
          }
          {display['Plains All American'] &&
            <Line dataKey = 'Plains All American' stroke = 'orangered'/>
          }
          {display['Rose Rock Midstream'] &&
            <Line dataKey = 'Rose Rock Midstream' stroke = 'black'/>
          }
          {display['Transcanada oil'] &&
            <Line dataKey = 'Transcanada oil' stroke = 'grey'/>
          }
          {display['Open Interest(Tank Tiger)'] &&
            <Line dataKey = 'Open Interest(Tank Tiger)' stroke = '#fc6c05' yAxisId="right"/>
          }
          <XAxis dataKey = 'date'/>
          <YAxis orientation='left'>
            <Label
              angle = {270}
              position="insideLeft"
              offset={-15}
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Volume
            </Label>
          </YAxis>
          {display['Open Interest(Tank Tiger)'] && (
            <YAxis yAxisId="right" orientation="right">
              <Label
                angle={270}
                position="right"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                Open Interest
              </Label>
            </YAxis>
          )}
          <Tooltip content={<CustomTooltip />} />
          <Legend content = {<CustomLegend display = {display} setDisplay = {setDisplay} colors = {colors}/>}/>
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  )
}
