import React, { useState, useEffect, useRef, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import CustomFilter from '../CustomFilter';
import RefreshIcon from '@material-ui/icons/Refresh';
import HelpIcon from '@material-ui/icons/Help'
import TutorialStep from '../display/TutorialStep'
import FAQ from '../display/FAQ'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

export default function EditableTable({ title, table, columns, actions, setBounds, ownership, toggle, fetchData, detailPanel, components, options, editable, openAdd, setOpenAdd}) {
  const { setMessage } = useMessage();
  const { auth, tutorialStep } = useAuth();
  const [ openFAQ, setOpenFAQ ] = useState(false)
  const [data, setData] = useState([]);
  const addRef = useRef()

  ownership = ownership ? ownership : 'my';

  useEffect(() => {
    fetch(`/api/${table}/${ownership}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds && setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch '.concat(table,' data.')})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, table, setMessage, setBounds]);

  return (
    <div>
      <FAQ open = {openFAQ} setOpen = {setOpenFAQ}/>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        options={{
          filtering: true,
          draggable: false,
          exportButton: false,
          ...(options ? options : {})
        }}
        components={{
          FilterRow: props => <CustomFilter {...props} />,
          ...(components ? components : {})
        }}
        actions={[
          {
            icon: () => <HelpIcon color = 'primary'/>,
            tooltip: 'FAQ',
            isFreeAction: true,
            onClick: (event) => setOpenFAQ(true)
          },
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: (event) => fetchData(!toggle)
          },
          ...(actions ? actions : [])
        ]}
        detailPanel = {detailPanel && ((rowData) => detailPanel(rowData))}
        onRowClick={detailPanel && ((event, rowData, togglePanel) => togglePanel())}
      />
      {tutorialStep === 10 && table === 'storage' &&
        <TutorialStep refPosition = {addRef.current} text = 'Click here to add Storage'/>
      }
      {tutorialStep === 16 && table === 'listing' &&
        <TutorialStep refPosition = {addRef.current} text = 'Click here to add Listing'/>
      }
    </div>
  )
}
