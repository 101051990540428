import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  const {isAuthenticated} = useAuth();
  const {userCategory} = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        (isAuthenticated && userCategory==='Admin') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { referer: props.location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
