import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MainList from './drawer/MainList.js';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { useAuth } from "../../context/auth";
import Pagination from '@material-ui/lab/Pagination';
import ErrorBoundary from '../error/ErrorBoundary';
import { useMessage } from '../../context/message';
import { useHistory } from 'react-router-dom';
import ReportIssue from './ReportIssue'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
  notificationDialog: {
      marginTop:'15px',
  },
  read: {
    'color' : 'gray'
  },
  unread: {

  },
}));

export default function Navigation({notify}) {
  const classes = useStyles();
  const theme = useTheme();
  const { setIsAuthenticated, username, setExpired, auth, tutorialStep, setTutorialStep, userCategory } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [toggleNotificationPanel, setToggleNotificationPanel] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const { setMessage } = useMessage();
  const [open, setOpen] = useState({
    drawer: true,
    account: true,
    admin: true,
    team: true
  });
  const [anchorEl, setAnchorEl] = useState(null);
  let history = useHistory();
  const [report, setReport] = useState(false)

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const viewProfile = () => {
    history.push('/profile')
  }

  const reportIssue = () => {
    setReport(true)
  }

  const handleDrawerOpen = () => {
    setOpen({...open, drawer: true});
  };

  const handleDialogClose = () => {
    setToggleNotificationPanel(false)
  };

  const handlePageChange = (event, value) => {
    setPage(value)
    setToggleNotificationPanel(true);
  }

  const handleDrawerClose = () => {
    setOpen({...open, drawer: false});
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    setIsAuthenticated(false);
    setExpired(false);
  };

  const displayNotifications = () => {
    if (tutorialStep === 20){
      setTutorialStep(21)
    }
    setToggleNotificationPanel(true);
    setPageCount(Math.ceil(notifications.length/10))
  };

  const read = (event, id) => {
    fetch(`/api/notify/${id}`, {
      method: 'PUT', // or 'PUT'
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
      },
    })
    .then(response => auth(response))
    .then(data => {
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to update notification.'})
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    fetch('/api/notify', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then((data) => {
      setNotifications(data.data);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch notifications.'})
      console.error('Error:', error);
    })
    fetch('/api/notify/count', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then((data) => {
      if (data.data) {setNotificationCount(data.data['count'])}
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch notification count.'})
      console.error('Error:', error);
    })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[setMessage, notify]);

  return (
    <div className={classes.root}>
      <ErrorBoundary>
        <ReportIssue open = {report} setOpen = {setReport} />
      <Dialog
        open = {toggleNotificationPanel}
        onClose = {handleDialogClose}
        maxWidth = 'xs'
        fullWidth = {true}
      >
        <DialogTitle id="notifications_title" onClose={displayNotifications}>
          Notifications
        </DialogTitle>
        <DialogContent>
          {notifications && notifications.slice((page-1)*10,(page*10)).map( (note, index) =>
            <ListItem button key={index} onClick={(event) => read(event,note.notification_id)} component = "a" href = {note.endpoint}>
              {note.read ?
                <ListItemText primary = {note.description} className={classes.read}/> :
                <ListItemText primary = {note.description} className={classes.unread}/>
              }
            </ListItem>
          )}
          <Pagination count={pageCount} size='large' page={page} onChange={handlePageChange} showFirstButton showLastButton />
        </DialogContent>
      </Dialog>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open.drawer,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open.drawer && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            The Tank Tiger
          </Typography>
          {userCategory !== 'Viewer' &&
            <IconButton
              color = "inherit"
              onClick = {displayNotifications}
            >
              <Badge badgeContent = {notificationCount} color="error">
                <NotificationsIcon/>
              </Badge>
            </IconButton>
          }
          <Button
            color="inherit"
            onClick = {openMenu}
            >
            {username}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open = {Boolean(anchorEl)}
            onClose = {closeMenu}
          >
            <MenuItem onClick = {reportIssue}>
              Report an issue
            </MenuItem>
            <MenuItem onClick = {viewProfile}>
              Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <MainList open={open} setOpen={setOpen}/>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open.drawer,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </ErrorBoundary>
    </div>
  );
}
