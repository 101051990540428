import React, { useState, useEffect } from 'react';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import MaterialTable from 'material-table';

export default function TeamMembers() {
  const { setMessage } = useMessage();
  const { auth, team_id } = useAuth();
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetch(`/api/team/${team_id}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setMembers(response.data || []);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch members'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage])

  return (
    <MaterialTable
      columns={[
        {
          title: 'User ID',
          field: 'user_id',
        },
        {
          title: 'First Name',
          field: 'first_name',
        },
        {
          title: 'Last Name',
          field: 'last_name',
        },
        {
          title: 'Username',
          field: 'username',
        },
        {
          title: 'Email',
          field: 'email',
        },
      ]}
      options={{
        filtering: true,
        draggable: false,
        exportButton: true
      }}
      title='Members'
      data={members}
    />
  )
}
