import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from '../../context/message';
import { useAuth } from "../../context/auth";
import { useChat } from '../../context/chat'
import { useField } from '../../context/context'
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import InquiryBidForm from './InquiryBidForm';
import Button from '@material-ui/core/Button';
import AccessModeTable from '../display/AccessModeTable';
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import Typography from '@material-ui/core/Typography'
import AlertDialog from '../inputs/AlertDialog'
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import BidFileSelect from '../inputs/BidFileSelect'
import CircularProgress from '@material-ui/core/CircularProgress'
import FAQ from '../display/FAQ'
import HelpIcon from '@material-ui/icons/Help'
import MessageIcon from '@material-ui/icons/Message'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'

const useStyles = makeStyles((theme) => ({
  place_bid: {
    marginLeft: 5,
    marginTop: 5,
    display: 'flex',
    'flex-direction': 'row',
  },
  attach: {
    height: 30,
    width: 30,
    top: -5,
    left: 5
  },
  chip: {
    bottom: 20,
    left: 20
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export default function AllInquiriesTable() {
  const { setMessage } = useMessage();
  const classes = useStyles()
  const { auth } = useAuth();
  const { openConversations, setOpenConversations, setChatroom, setCurrentChat, anchorRef } = useChat()
  const { products, fetchFields, setFetchFields} = useField()
  const [data, setData] = useState([]);
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFAQ, setOpenFAQ] = useState(false)
  const [openInfo, setOpenInfo] = useState(false)
  const [product_lookup, setProduct_lookup] = useState({});
  const [inquire, setInquire] = useState(false)
  const [inquiryId, setInquiryId] = useState()
  const [loading, setLoading] = useState(false)
  const [bid, setBid] = useState({
    inquiry_id: '',
    storage_id: '',
    volume: '',
    ask_price: '',
    term: '',
    date_available: new Date(),
    comment: '',
    file:'',
    name:'',
    allow_sublet: ''
  });
  //file select
  const [bidAttach, setBidAttach] = useState(false)
  const [byteArrays, setByteArrays] = useState([])
  const [previewURLs, setPreviewURLs] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [bidToggle, setBidToggle] = useState(false)

  const handleClose = () => {
    setOpen(false);
    setFileNames([])
    setPreviewURLs([])
    setByteArrays([])
    fetchData(!toggle);
  };

  const updateField = e => {
    setBid({
      ...bid,
      [e.target.name]: e.target.value
    });
  };

  const closeInquire = () => {
    setInquire(false)
  }

  const handleCloseInfo = () => {
    setOpenInfo(false)
  }

  const submitInquiry = () => {
    fetch(`/api/message/inquire/inquiry/${inquiryId}`, {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      },
    })
    .then(response => auth(response))
    .then(response => {
      if (response.message.type === 'success'){
        let message = response.data
        message['last_read'] = 0
        anchorRef.current[message.conversation_id] = React.createRef()
        openConversations.push(message)
        setOpenConversations([...openConversations])
        setCurrentChat(message)
        setChatroom(true)
      }
      else(
        setMessage(response.message)
      )
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to start inquiry.'})
      console.error('Error:', error);
    })
    closeInquire()
  }

  const attach = () => {
    setBid({
      ...bid,
      'name': fileNames,
      'file': byteArrays,
    })
  }

  const handleSubmit = () => {
    if (!loading){
      setLoading(true)
      fetch(`/api/inquiry_bids/${bid.inquiry_id}`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bid)
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        setMessage(response.message);
        setLoading(false)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to submit bid'});
        setLoading(false)
        console.error('Error:', error);
      })
    }
  };

  useEffect(() => {
    fetch('/api/inquiry/all', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch data'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage, toggle]);

  useEffect(() => {
    if (products){
      let temp = {};
      products.map(item => temp[item.product] = item.product);
      setProduct_lookup(temp)
    } else {
      setFetchFields(!fetchFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const columns = [
    {
      title: 'Inquiry ID',
      field: 'inquiry_id'
    },
    {
      title: 'Region',
      field: 'region',
    },
    {
      title: 'Location',
      field: 'formatted_address',
    },
    {
      title: 'Radius',
      field: 'radius',
      bounds: bounds.radius,
      customFilterAndSearch: rangeFilter('radius')
    },
    {
      title: 'Product',
      field: 'product',
      lookup: product_lookup,
    },
    {
      title: 'Volume Range(MBBL)',
      field: 'volume_range',
      type: 'numeric',
      bounds: bounds.min_volume ? [bounds.min_volume[0], bounds.max_volume[1]] : [0,1],
      customFilterAndSearch: (term, rowData) => (term[0] <= rowData.max_volume) && (term[1] >= rowData.min_volume),
    },
    {
      title: 'Price (USD/BBL)',
      field: 'bid_price',
      type: 'numeric',
      step: .1,
      bounds: bounds.bid_price,
      customFilterAndSearch: rangeFilter('bid_price')
    },
    {
      title: 'Start Date',
      field: 'start_date',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('start_date'),
      customSort: (a,b) => {
        var date1 = new Date(a.start_date)
        var date2 = new Date(b.start_date)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
    },
    {
      title: 'Term (Months)',
      field: 'term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term')
    },
    {
      title: 'Date Requested',
      field: 'date_placed',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('date_placed'),
      render: rowData => <p>{(new Date(rowData.date_placed)).toLocaleDateString()}</p>
    },
    {
      title: 'Comment',
      field: 'comment',
      render: rowData => (
        rowData.comment && (rowData.comment.length > 30 ? rowData.comment.substring(0,30) + '...' : rowData.comment)
      )
    }
  ];

  const accessModes = (rowData) => {
    return (
      <AccessModeTable inquiry_id={rowData.inquiry_id} />
    )
  }

  const chipDelete = (index) => {
    fileNames.splice(index,1)
    byteArrays.splice(index,1)
    previewURLs.splice(index,1)
    setBidToggle(!bidToggle)
  }

  return (
    <div>
      <FAQ open = {openFAQ} setOpen = {setOpenFAQ}/>
      <MaterialTable
        title='Inquiries'
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        columns={columns}
        data={data}
        options={{
          filtering: true,
          draggable: false,
          actionsColumnIndex: 0
        }}
        actions={[
          {
            icon: () => <HelpIcon color = 'primary'/>,
            tooltip: 'FAQ',
            isFreeAction: true,
            onClick: (event) => setOpenFAQ(true)
          },
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: (event) => fetchData(!toggle)
          },
          {
            icon: () => <MonetizationOnIcon color = 'primary'/>,
            tooltip: 'Place Bid',
            onClick: (event, rowData) => {
              setOpen(true);
              setBid({ ...bid, inquiry_id: rowData.inquiry_id });
            }
          },
          {
            icon: () => <MessageIcon color = 'primary'/>,
            tooltip: 'Send Message',
            onClick: (event, rowData) => {
              setInquiryId(rowData.inquiry_id)
              setInquire(true)
            }
          },
          {
            icon: () => <InfoIcon color = 'primary'/>,
            tooltip: 'More Info',
            onClick: (event, rowData) => {
              setInquiryId(rowData.inquiry_id)
              setOpenInfo(true)
            }
          }
        ]}
        components= {{
          FilterRow: props => <CustomFilter {...props} />
        }}
        detailPanel={accessModes}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
      <AlertDialog
        open = {inquire}
        setOpen = {setInquire}
        title = 'Start Chat'
        content = 'Are you sure you wish to start a chat with the owner of this inquiry?'
        submitText = 'Submit'
        submit = {submitInquiry}
      />
      <InfoDialog
        open = {openInfo}
        onClose = {handleCloseInfo}
        type = 'inquiry_info'
        id = {inquiryId}
      />
      <Dialog
        maxWidth='md'
        fullWidth={true}
        open={open}
        >
        <DialogContent>
          <Grid container spacing = {2} className = {classes.place_bid}>
            <Typography>
              Place Bid
            </Typography>
            <Tooltip title='Attach Files'>
              <IconButton
                onClick = {() => setBidAttach(true)}
                className = {classes.attach}
              >
                <AttachFileIcon color = 'primary'/>
              </IconButton>
            </Tooltip>
            <Grid item xs = {12}>
              {fileNames.length > 0 && fileNames.map((name, index) =>
                <Chip color='primary' key = {index} label={name} onDelete={() => chipDelete(index)}/>
              )}
            </Grid>
          </Grid>
          <InquiryBidForm
            bid={bid}
            updateField={updateField}
            setBid={setBid}
          />
          <BidFileSelect
            open = {bidAttach}
            setOpen = {setBidAttach}
            type = 'inquiryBid'
            byteArrays = {byteArrays} setByteArrays = {setByteArrays}
            previewURLs = {previewURLs} setPreviewURLs = {setPreviewURLs}
            fileNames = {fileNames} setFileNames = {setFileNames}
            attach = {attach}
          />
        </DialogContent>
        <DialogActions>
            <div className = {classes.wrapper}>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
              {loading &&
                <CircularProgress size={32} className = {classes.loading}/>
              }
            </div>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
