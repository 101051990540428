import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UserTable from '../user/UserTable';
import PendingUser from '../user/PendingUser';
import SuspendedUser from '../user/SuspendedUser';
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: 10
  },
  header: {
    flexGrow: 1,
    backgroundColor: '#545454',
  },
  tabSelect: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      textColor: theme.palette.secondary.main,
    },
  },
  map: {
    marginBottom: theme.spacing(8),
  }
}));

export default function UsersPage() {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <div>
      <Paper className={classes.tables}>
        <Tabs variant='fullWidth' textColor='primary' indicatorColor='primary' value={value} onChange={handleChange}>
          <Tab className={value!==0 ? classes.tabSelect : classes.root} label="Current Users" {...a11yProps(0)} />
          <Tab className={value!==1 ? classes.tabSelect : classes.root} label="Pending Users" {...a11yProps(1)} />
          <Tab className={value!==2 ? classes.tabSelect : classes.root} label="Suspended Users" {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <Paper>
        <TabPanel value={value} index={0}>
          <UserTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PendingUser />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SuspendedUser />
        </TabPanel>
      </Paper>
    </div>
  )
}
