import React, { useState, useEffect } from 'react'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import GoogleMaps from '../inputs/GoogleMaps'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { useField } from '../../context/context'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AccessMode from '../inputs/AccessMode'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: 300
  },
  label: {
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(1.5)
  },
  heading: {
    fontSize: 16
  },
  select: {
    width: 300
  },
  button: {
    margin: theme.spacing(2),
  },
  remove: {
    marginTop: theme.spacing(2)
  },
  textfield: {
    width: 300
  }
}));

const tank_material_lookup = [
  'Carbon Steel',
  'Stainless Steel',
  'Carbon Steel Lined',
  'Mild Steel',
  'Coated Steel',
  'Other'
]
const tank_type_lookup = [
  'Internal Floating Roof',
  'External Floating Roof',
  'Coned Roof',
  'Cavern',
  'Sphere',
  'Other'
]

const tank_features_lookup = [
  'Pressurized',
  'Nitrogen Blanket',
  'Chiller',
  'Insulated',
  'Steam',
  'DOT',
  'FDA',
  'Kosher Service',
  'Sparging and Circulation',
  'Filtering'
]

export default function AddStorage({open, setOpen, storage_id, setStorage_id, toggle, fetchData}) {
  const classes = useStyles()
  const { auth, tutorialStep, setTutorialStep } = useAuth()
  const { setMessage } = useMessage()
  const { product_categories, fetchFields, setFetchFields } = useField()
  const [access_modes, setAccess_modes] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [location, setLocation] = useState('')
  const [editLocation, setEditLocation] = useState(false)
  const [storage, setStorage] = useState({
      storage_name: '',
      formatted_address: {},
      product_category: [],
      tank_features: [],
      total_capacity: '',
      tank_material: '',
      tank_type: '',
      heel_requirement: '',
      blending_capability: '',
      heating_capability: '',
      commingled: '',
      number_of_tanks: ''
  })

  useEffect(() => {
    //set storage for editing
    if (open){
      if (storage_id !== 0)
      {
        //fetch storage + access modes
        fetch(`/api/storage/${storage_id}`, {
          method: 'GET',
          headers: {
            'x-access-token': sessionStorage.getItem('token')
          }
        })
        .then((response) => auth(response))
        .then((response) => {
          if (response.storage){
            setStorage(response.storage)
            setAccess_modes(response.modes)
            setSelectedProducts(response.storage['product_category'].split(', '))
            if (response.storage['tank_features'])
              setSelectedFeatures(response.storage['tank_features'].split(', '))
            setLocation(response.storage['formatted_address'])
            setEditLocation(!editLocation)
          }
          else
          {
            setMessage({'type': 'error', 'text':'Must be the owner of storage location to edit'})
            handleClose()
          }
        })
        .catch((error) => {
          setMessage({'type': 'error', 'text': 'Error! Unable to fetch storage details.'})
          handleClose()
          console.error('Error:', error);
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage_id, open])

  const submit = () => {
    if (storage_id === 0){
      //add
      fetch(`/api/storage`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...storage,
          access_modes: access_modes,
          formatted_address: location
        })
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        if(tutorialStep === 11)
          setTutorialStep(12)
        setMessage(response.message);
        fetchData(!toggle)
        setFetchFields(!fetchFields)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to create storage'});
        console.error('Error:', error);
      })
    } else {
      //edit
      fetch(`/api/storage/${storage_id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...storage,
          access_modes: access_modes,
          formatted_address: location
        })
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        setMessage(response.message);
        fetchData(!toggle)
        setFetchFields(!fetchFields)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to update storage'});
        console.error('Error:', error);
      })
    }
  }


  const handleClose = () => {
    setSelectedProducts([])
    setSelectedFeatures([])
    if (storage_id !== 0)
      setStorage_id(0)
    setAccess_modes([])
    setLocation('')
    setStorage({
      storage_name: '',
      product_category: [],
      tank_features: [],
      total_capacity: '',
      tank_material: '',
      tank_type: '',
      heel_requirement: '',
      blending_capability: '',
      heating_capability: '',
      commingled: '',
      number_of_tanks: ''
    })
    setOpen(false)
  }

  const updateField = e => {
    setStorage({
      ...storage,
      [e.target.name]: e.target.value
    });
  };

  const updateProduct = (e) => {
    setSelectedProducts(e.target.value);
    setStorage({
      ...storage,
      product_category: e.target.value
    });
  }

  const updateFeatures = (e) => {
    setSelectedFeatures(e.target.value)
    setStorage({
      ...storage,
      tank_features: e.target.value
    })
  }

  const addMode = () => {
    let mode = {
      mode_name:'',
      inbound_outbound:'',
      type:'',
      pump_over_rate:'',
      dock_draft:'',
      length_overall:'',
      class_one_rail:'',
      rail_spots:''
    }
    setAccess_modes([...access_modes, mode])
  };

  const removeMode = (index) => {
    let array = [...access_modes];
    array.splice(index, 1);
    setAccess_modes(array);
  };

  return(
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='lg'
    >
      <DialogTitle>
        {storage_id !== 0 ? 'Update Storage' : 'Add Storage'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item className={classes.root}>
            <GoogleMaps
              location={location}
              setLocation={setLocation}
              editLocation={editLocation}
            />
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              className={classes.textfield}
              required
              name = "storage_name"
              label = "Storage Name"
              value =  {storage.storage_name}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Product Category</InputLabel>
              <Select
                className={classes.select}
                multiple
                value={selectedProducts}
                onChange={updateProduct}
                renderValue={(selectedProducts) => (Array.isArray(selectedProducts) && selectedProducts.join(', '))}
                variant="outlined"
                label = 'Product Category'
              >
                {product_categories && product_categories.map((item) => (
                  <MenuItem key={item.product_category} value={item.product_category}>
                    <Checkbox checked = {selectedProducts.indexOf(item.product_category) > -1} />
                    {item.product_category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              required
              className={classes.textfield}
              name = 'total_capacity'
              label = 'Total Capacity (MBBL)'
              value =  {storage.total_capacity}
              onChange={updateField}
              variant="outlined"
              type = 'number'
            />
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Tank Material</InputLabel>
              <Select
                className={classes.select}
                value = {storage.tank_material}
                onChange = {updateField}
                name = 'tank_material'
                label = 'Tank Material'
                variant="outlined"
              >
                {tank_material_lookup && tank_material_lookup.map((item) =>
                  <MenuItem key = {item} value = {item}> {item} </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Tank Type</InputLabel>
              <Select
                className = {classes.select}
                value = {storage.tank_type}
                onChange = {updateField}
                name = 'tank_type'
                label = 'Tank Type'
                variant="outlined"
              >
                {tank_type_lookup && tank_type_lookup.map((item) =>
                  <MenuItem key = {item} value = {item}> {item} </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Tank Features</InputLabel>
              <Select
                className={classes.select}
                multiple
                value={selectedFeatures}
                onChange={updateFeatures}
                renderValue={(selectedFeatures) => (Array.isArray(selectedFeatures) && selectedFeatures.join(', '))}
                variant="outlined"
                label = 'Tank Features'
              >
                {tank_features_lookup && tank_features_lookup.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked = {selectedFeatures.indexOf(item) > -1} />
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              required
              className={classes.textfield}
              name = 'heel_requirement'
              label = 'Heel Requirement (BBL)'
              value =  {storage.heel_requirement}
              onChange={updateField}
              variant="outlined"
              type = 'number'
            />
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Blendable (Y/N)</InputLabel>
              <Select
                className = {classes.select}
                value = {storage.blending_capability}
                onChange = {updateField}
                name = 'blending_capability'
                label = 'Blendable (Y/N)'
                variant="outlined"
              >
                <MenuItem key = 'Y' value = '1'> Y </MenuItem>
                <MenuItem key = 'N' value = '0'> N </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Heated (Y/N)</InputLabel>
              <Select
                className = {classes.select}
                value = {storage.heating_capability}
                onChange = {updateField}
                name = 'heating_capability'
                label = 'Heated (Y/N)'
                variant="outlined"
              >
                <MenuItem key = 'Y' value = '1'> Y </MenuItem>
                <MenuItem key = 'N' value = '0'> N </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Com or Seg</InputLabel>
              <Select
                className = {classes.select}
                value = {storage.commingled}
                onChange = {updateField}
                name = 'commingled'
                label = 'Com or Seg'
                variant="outlined"
              >
                <MenuItem key = 'Com' value = '1'>Commingled</MenuItem>
                <MenuItem key = 'Seg' value = '0'>Segregated</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              required
              className={classes.textfield}
              name = 'number_of_tanks'
              label = 'Number of Tanks'
              value =  {storage.number_of_tanks}
              onChange={updateField}
              variant="outlined"
              type = 'number'
            />
          </Grid>
        </Grid>
          {/*Access Modes*/}
          <Typography style = {{marginTop: 10, marginBottom: 10}}>
            Access Modes
          </Typography>
          <Grid container>
                <IconButton onClick={addMode}>
                  <AddIcon />
                </IconButton>
                {access_modes.map((mode, index) => (
                  <Grid container key = {index}>
                    <Grid item className = {classes.remove}>
                      <IconButton onClick={() => removeMode(index)}>
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs>
                      <AccessMode
                        index={index}
                        mode={mode}
                        access_modes={access_modes}
                        setAccess_modes={setAccess_modes}
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick = {submit} color = 'primary'>
          {storage_id !== 0 ? 'Update' : 'Add'}
        </Button>
        <Button
          onClick = {() => {
            if (tutorialStep === 11)
              setTutorialStep(10)
            handleClose()
          }}
          color = 'primary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>

  )
}
