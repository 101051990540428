export const rangeFilter = (field, bounds = null) => (
    (term, rowData) => {
      if (bounds){
        if (term[0] == bounds[0] && term[1] == bounds[1])
          return true
        else
          return rowData[field] >= term[0] && rowData[field] <= term[1]
      }
      if (typeof term === 'string')
        return parseInt(term) === rowData[field]
      else
        return rowData[field] >= term[0] && rowData[field] <= term[1]
    }
)

export const dateRangeFilter = (field) => (
  (term, rowData) => {
    if (typeof term === 'string')
      return rowData[field].toLowerCase().includes(term.toLowerCase())
    else
      return !term.startDate || (Date.parse(rowData[field]) >= Date.parse(term.startDate) && Date.parse(rowData[field]) <= Date.parse(term.endDate))
  }
)
