import React, { useState } from 'react';
import TerminalBookings from './TerminalBookings';
import TraderBookings from './TraderBookings';
import MyRenewals from './MyRenewals';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import BookingCalendar from '../display/BookingCalendar'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BookedPieChart from '../dashboard/BookedPieChart';
import Forecast from '../dashboard/Forecast';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(5)
  },
  tab: {
    backgroundColor: theme.palette.secondary.secondary,
    },
  tabSelect: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      textColor: theme.palette.secondary.main,
    },
  },
  paper: {
    'marginTop':theme.spacing(15),
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
      </div>
  );
}

export default function MyBookingsPage() {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0)
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (event, newValue) => (
    setValue2(newValue)
  )

  return (
    <Grid container spacing={3} >
      <Grid item xs={3}>
        <BookedPieChart />
      </Grid>
      <Grid item xs={9}>
        <Forecast />
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.table}>
          <Tabs
            indicatorColor = 'primary'
            textColor = 'primary'
            variant = 'fullWidth'
            onChange = {handleChange}
            value = {value}
            className = {classes.tab}
            >
              <Tab label = 'Calendar' id = {0} className = {value!==0 ? classes.tabSelect : classes.tab}/>
              <Tab label = 'Table' id = {1} className = {value!==1 ? classes.tabSelect : classes.tab}/>
            </Tabs>
            <TabPanel value={value} index={0}>
              <BookingCalendar type = 'terminal'/>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TerminalBookings />
            </TabPanel>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className = {classes.table}>
            <Tabs
              indicatorColor = 'primary'
              textColor = 'primary'
              variant = 'fullWidth'
              onChange = {handleChange2}
              value = {value2}
              className = {classes.tab}
              >
                <Tab label = 'Calendar' id = {0} className = {value2!==0 ? classes.tabSelect : classes.tab}/>
                <Tab label = 'Table' id = {1} className = {value2!==1 ? classes.tabSelect : classes.tab}/>
              </Tabs>
              <TabPanel value={value2} index={0}>
                <BookingCalendar type = 'trader'/>
              </TabPanel>
              <TabPanel value={value2} index={1}>
                <TraderBookings />
              </TabPanel>
            </Box>
            <Box className={classes.table}>
              <MyRenewals />
            </Box>
          </Grid>
        </Grid>
      )
}
