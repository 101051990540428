import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useChat } from '../../context/chat'
import { useAuth } from '../../context/auth'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Chatroom from '../chat/Chatroom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ButtonBase from '@material-ui/core/ButtonBase';
import Popover from '@material-ui/core/Popover';
import AdjustRoundedIcon from '@material-ui/icons/AdjustRounded';
import Pagination from '@material-ui/lab/Pagination'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'

const useStyles = makeStyles((theme) => ({
  div: {
    padding: '10 px 10 px 10 px 10 px'
  },
  root: {
  },
  container: {
    'height': '425px',
    width: '275px',
    overflow: 'auto',
    'overflowAnchor': 'none',
  },
  chatroom: {
    'height': '500px',
    overflow: 'auto',
    'overflowAnchor': 'none',
    display: 'flex',
  },
  closeButtonSmall: {
    position: 'absolute',
    left: -2,
    top: -2,
    height: 10,
    width: 10,
    'z-index': 2,
    color: 'black'
  },
  closeIconSmall: {
    height: 15,
    width: 15
  },
  pagination: {
    position: 'absolute',
    bottom: 10,
    'text-align': 'center'
  },
  buttonGroup: {
  }
}));

export default function Messages({message}) {
  const classes = useStyles()
  const { auth } = useAuth();
  //Pagination
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const { anchorRef, openConversations, setOpenConversations, currentChat, setCurrentChat, chatroom, setChatroom, fetchConversations, setFetchConversations } = useChat()

  const [myConversations, setMyConversations] = useState([])
  const [messageList, setMessageList] =  useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [defaultAnchorEl, setDefaultAnchorEl] = useState(null)
  const [menu, setMenu] = useState(false)
  const [unreadChats, setUnreadChats] = useState(0)
  const [toggle, setToggle] = useState(false)
  const [focus, setFocus] = useState(false)

  const [messages, setMessagesNoScroll] = useState([])

  const setMessages = (message) => {
    setMessagesNoScroll(message)
    setFocus(!focus)
  }

  const onMessageReceived = (msg) => {
      console.log('msg: ', msg)
      var oc = openConversations
      var id
      var check = true
      try {
        id = String(currentChat.conversation_id)
      }
      catch(err){
        id = ''
      }
      console.log('oc: ', oc)
      console.log('id: ', id)
      console.log('msg_id: ', String(msg.conversation_id))
      if (id !== String(msg.conversation_id)) {
        console.log('if')
        for (var i = 0; i < oc.length; i++){
          if(String(oc[i].conversation_id) === String(msg.conversation_id)){
            check = false
            oc[i].last_read = 1
            setOpenConversations(oc)
          }
        }
      } else {
        console.log('else')
        check = false
        setMessages([...messages, {'message':msg.message, 'author': false}])
      }
      if (check){
        setUnreadChats(unreadChats + 1)
      }
  }

  useEffect(() => {
    for (var i = 0; i < openConversations.length; i++){
      anchorRef.current[openConversations[i].conversation_id] = React.createRef()
    }
    setToggle(!toggle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConversations])

  useEffect(() => {
    fetch('/api/message', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      console.log('response: ', response)
      setMyConversations(response.data)
      setUnreadChats(response.count)
      if(response.data)
        setPageCount(Math.ceil(response.data.length/5))
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchConversations] )

  useEffect(() => {
    onMessageReceived(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  const toggleMessageList = (event) => {
    if(event){
      setAnchorEl(event.currentTarget)
    }
    if (!defaultAnchorEl){
      setDefaultAnchorEl(event.currentTarget)
    }
    setMenu(true)
    setMessageList(!messageList)
    setFetchConversations(!fetchConversations)
  }

  const handleCloseChat = () => {
    setCurrentChat({})
    setChatroom(false)
  }

  const updateRead = (conversation) => {
    if (conversation.last_read !== 0){
      fetch(`/api/message/conversation/${conversation.conversation_id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
        },
      })
      .catch((error) => {
        console.error('Error:', error)
      })
      setFetchConversations(!fetchConversations)
    }
  }

  const open = (conversation) => () => {
    if (conversation){
      var temp = openConversations;
      var i;
      for (i = 0; i < temp.length; i++) {
        if(conversation.conversation_id === temp[i].conversation_id) {
          openChat(null,conversation)
          toggleMessageList()
          setCurrentChat(conversation)
          updateRead(conversation)
          setChatroom(true)
          return
        }
      }
      updateRead(conversation)
      if (temp.length >= 5){
        temp.shift()
      }
      conversation.last_read = 0
      temp.push(conversation)
      setOpenConversations(temp)
      toggleMessageList()
      setCurrentChat(conversation)
      setChatroom(true)
      anchorRef.current[conversation.conversation_id] = React.createRef()
    }
  }

  const openChat = (event, conversation) => {
    var oc = openConversations
    for( var i = 0; i < oc.length; i++){
      if (oc[i].conversation_id === conversation.conversation_id){
        if (oc[i].last_read === 1){
          oc[i].last_read = 0
          if (unreadChats !== 0){
            setUnreadChats(unreadChats - 1)
          }
        }
      }
    }
    setOpenConversations(oc)
    setMenu(false)
    setCurrentChat(conversation)
    setChatroom(true)
  }

  const closeChat = (conversation) => () => {
    if (conversation){
      var temp = [];
      var i;
      for (i = 0; i < openConversations.length; i++){
        if (openConversations[i].conversation_id !== conversation.conversation_id){
          temp.push(openConversations[i])
        }
      }
      setOpenConversations(temp)
    }
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  return (
    <div>
      <Popover
        open = {messageList}
        onClose = {toggleMessageList}
        anchorEl = {anchorEl}
        anchorOrigin = {{vertical: 'top', horizontal: 'right',}}
        transformOrigin = {{vertical: 'bottom', horizontal: 'right',}}
      >
        <div className={classes.container}>
          <List>
            {myConversations && myConversations.slice((page-1)*5,(page*5)).map((c) => (
              <ListItem button onClick = {open(c)} key = {c.conversation_id}>
                <ListItemText
                  primary = {
                    <Typography variant = 'body2' style = {c.closed ? {'color': '#d3d3d3'} : null}>
                        {`${c.conversation_id}. ${c.conversation_title}`}
                    </Typography>
                  }
                  secondary = {c.closed ? 'Chat has been closed!' : c.lastMessage}
                  className = {c.last_read !== 0 ? classes.unread : null}
                />
                {(c.last_read !== 0 && c.conversation_id !== currentChat.conversation_id) &&
                  <AdjustRoundedIcon color = 'primary'/>
                }
              </ListItem>
            ))}
          </List>
          <Pagination count={pageCount} size = 'small' page={page} onChange={handlePageChange} className = {classes.pagination}/>
        </div>
      </Popover>
      <Badge badgeContent = {unreadChats} color = 'error'>
        <ButtonGroup className = {classes.buttonGroup}>
          {openConversations && openConversations.map((c) => (
            <Badge invisible = {c.last_read === 0} color = 'error' badgeContent = {'!'} overlap = 'circle' key = {c.conversation_id}>
              <ButtonBase>
                <Button
                  variant = 'contained'
                  color = 'primary'
                  onClick = {(e) => openChat(e, c)}
                  ref = {anchorRef.current[c.conversation_id]}
                >
                  {c.conversation_id}
                </Button>
                <IconButton className = {classes.closeButtonSmall} onClick = {closeChat(c)}>
                  <CloseIcon className = {classes.closeIconSmall}/>
                </IconButton>
              </ButtonBase>
            </Badge>
          ))}
          <Button onClick = {toggleMessageList} variant = 'contained' color = 'primary'>
            All chats
          </Button>
        </ButtonGroup>
      </Badge>
      <Popover
        open = {chatroom}
        onClose = {handleCloseChat}
        anchorEl = {anchorRef.current[currentChat.conversation_id] ? anchorRef.current[currentChat.conversation_id].current : undefined}
        anchorOrigin = {menu ? {vertical: 'top', horizontal: 'left',} : {vertical: 'top', horizontal: 'right',}}
        transformOrigin = {menu ? {vertical: 'bottom', horizontal: 'left',} : {vertical: 'bottom', horizontal: 'right',}}
      >
        <Chatroom close={handleCloseChat} messages = {messages} setMessages = {setMessages} focus = {focus}/>
      </Popover>
    </div>

  )
}
