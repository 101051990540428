import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '../../context/auth'
import { useMessage } from '../../context/message'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(( theme ) => ({
  grid: {
    position: 'relative',
    width: '45%'
  },
  typography: {
    marginTop: 20
  },
  textfield: {
    position: 'relative',
    width: '80%',
    marginTop: 10,
    marginBottom: 10
  },
  button: {
    position: 'relative',
    width: '50%',
    height: 50,
    marginTop: 20,
    marginBottom: 20
  },
  container: {
    width: '100%'
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16,
    color: 'black'
  },
}))

export default function ContactUs() {
  const classes = useStyles()
  const { auth } = useAuth()
  const { setMessage } = useMessage()
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company: '',
    comments: ''
  })

  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value
    });
  }

  useEffect(() => {
    fetch('/api/user/profile', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      },
    })
    .then((response) => auth(response))
    .then((response) => {
      setInfo({
        'first_name': response.data.first_name,
        'last_name': response.data.last_name,
        'phone': response.data.cell_phone,
        'email': response.data.email,
        'company': response.data.company,
        'comments' : ''
      })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const contact = () => {
    if (!loading){
      setLoading(true)
      //submit form - email admin
      fetch('/api/user/contact', {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
      })
      .then((response) => auth(response))
      .then((data) => {
        setLoading(false)
        setMessage(data.message);
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error:', error);
      });
    }
  }

  return (
    <div>
      <Paper>
        <Grid container direction = 'row' className = {classes.container}>
          <Grid container justify = 'center' className = {classes.grid} >
            <TextField
              label = 'First Name'
              name = 'first_name'
              className = {classes.textfield}
              value = {info.first_name}
              onChange = {handleChange}
              variant = 'outlined'
              style = {{marginTop: 20}}
            />
            <TextField
              label = 'Last Name'
              name = 'last_name'
              className = {classes.textfield}
              value = {info.last_name}
              onChange = {handleChange}
              variant = 'outlined'
            />
            <TextField
              label = 'Phone'
              name = 'phone'
              className = {classes.textfield}
              value = {info.phone}
              onChange = {handleChange}
              variant = 'outlined'
            />
            <TextField
              label = 'Email'
              name = 'email'
              className = {classes.textfield}
              value = {info.email}
              onChange = {handleChange}
              variant = 'outlined'
            />
            <TextField
              label = 'Company'
              name = 'company'
              className = {classes.textfield}
              value = {info.company}
              onChange = {handleChange}
              variant = 'outlined'
            />
            <TextField
              label = 'Comments'
              name = 'comments'
              className = {classes.textfield}
              value = {info.comments}
              onChange = {handleChange}
              variant = 'outlined'
              style = {{marginBottom: 20}}
            />
          </Grid>
          <Grid container justify = 'center' className = {classes.grid}>
            <Typography className = {classes.typography}>
              Subscription Includes...
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon color = 'primary'/>
                </ListItemIcon>
                <ListItemText
                  primary = 'Price Index by Region and Product Category'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon color = 'primary'/>
                </ListItemIcon>
                <ListItemText
                  primary = 'Forward looking and historical Open Interest and Available Capacity'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon color = 'primary'/>
                </ListItemIcon>
                <ListItemText
                  primary = 'Forward looking and historical Price points by Region and Product Category'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon color = 'primary'/>
                </ListItemIcon>
                <ListItemText
                  primary = 'Volume Comparisons by Region and Product Category'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon color = 'primary'/>
                </ListItemIcon>
                <ListItemText
                  primary = 'Contact us to learn more...'
                />
              </ListItem>
            </List>
            <Button className = {classes.button} variant = 'contained' color = 'primary' onClick = {contact}>
              Contact us
              {loading &&
                <CircularProgress size={32} className = {classes.loading}/>
              }
            </Button>

          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
