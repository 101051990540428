import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useChat } from '../../context/chat';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AccessModeTable from '../display/AccessModeTable'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import FileViewer from '../display/FileViewer'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import BidForm from '../bid/BidForm'
import BidFileSelect from '../inputs/BidFileSelect'
import AlertDialog from '../inputs/AlertDialog'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  input: {
    marginTop: 10,
    marginLeft: 10,
  },
  attach: {
    height: 30,
    width: 30,
    top: -5,
    left: 5,
  },
  place_bid: {
    marginLeft: 5,
    marginTop: 5,
    display: 'flex',
    'flex-direction': 'row'
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  },
  attachments: {
    top: 20,
    height: 30,
    width: 30
  },
  button: {
    marginTop: 20,
    marginBottom:10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 20,
    marginLeft: 20,
    marginRight:10,
    color: theme.palette.primary.main
  },
  reason: {
    marginTop: 20,
    marginLeft: 40,
    marginBottom:10,
  },
  text: {
    marginLeft : 20,
    marginTop : 20,
  },
  multiline: {
    marginLeft : 20,
    marginTop : 20,
    width : '77%'
  },
  multiline2: {
    marginTop: 10,
    marginLeft: 10,
    width: '98%'
  }
}));

export default function Listing({id, disablePlaceBid}){
  const {setMessage} = useMessage();
  const { auth } = useAuth();
  const classes = useStyles();
  const [status, setStatus] = useState('');
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false)
  const [bid, setBid] = useState({
    listing_id: '',
    product: '',
    bid_price: '',
    volume: '',
    comment: '',
    start_date: new Date(),
    term: '',
    file: [],
    name: []
  });
  //file attach
  const [loading, setLoading] = useState(false)
  const [bidAttach, setBidAttach] = useState(false)
  const [byteArrays, setByteArrays] = useState([])
  const [previewURLs, setPreviewURLs] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [bidToggle, setBidToggle] = useState(false)
  //file viewer
  const [viewFile, setViewFile] = useState(false)
  //message button
  const [inquire, setInquire] = useState(false)
  const { setChatroom, setCurrentChat, setOpenConversations, openConversations } = useChat()

  const [bidData, setBidData] = useState({
    //Listing
    'listing_id': '',
    'working_capacity': '',
    'date_available': '',
    'listing_end_date': '',
    'term': '',
    'ask_price': '',
    'heel_product': '',
    'comment': '',
    //Location
    'visibility': '',
    'formatted_address': '',
    'locality': '',
    'administrative_area_level_1': '',
    'country': '',
    'region': '',
    //Storage
    'total_capacity': '',
    'product_category': '',
    'tank_type': '',
    'tank_material': '',
    'tank_features': '',
    'heel_requirement': '',
    'blending_capability': '',
    'heating_capability': '',
    'commingled': '',
    'number_of_tanks': ''
  });

  const handleClose = () => {
    setOpen(false);
    setByteArrays([])
    setPreviewURLs([])
    setFileNames([])
  };

  const send_message = () => {
    setInquire(true)
  }

  const closeInquire = () => {
    setInquire(false)
  }

  const submitInquiry = () => {
    fetch(`/api/message/inquire/listing/${bidData.listing_id}`, {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      },
    })
    .then(response => auth(response))
    .then(response => {
        console.log(response)
        if (response.message.type === 'success'){
          openConversations.push(response.data)
          setOpenConversations([...openConversations])
          setCurrentChat(response.data)
          setChatroom(true)
        }
        setMessage(response.message)
    })
    .catch((error) => {
      setMessage({'type':'error', 'text': 'Error! Unable to start conversation'})
      console.log('Error', error)
    })
    closeInquire()
  }

  const updateField = e => {
    setBid({
      ...bid,
      [e.target.name]: e.target.value
    });
  };

  const attach = () => {
    setBid({
      ...bid,
      'name': fileNames,
      'file': byteArrays,
    })
  }

  const chipDelete = (index) => {
    fileNames.splice(index,1)
    byteArrays.splice(index,1)
    previewURLs.splice(index,1)
    setBidToggle(!bidToggle)
  }

  useEffect(() => {
    fetch(`/api/listing/${id}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.data){
        setStatus(response.data['status'])
        if(!response.data['comment']){
          response.data['comment'] = ''
        }
        setBidData(response.data)
      }
      else
        setStatus('Does not exist!')
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch listing info.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id, setMessage]);

  const handleSubmit = () => {
    if (!loading) {
      setLoading(true)
      fetch(`/api/bid/${id}`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bid)
      })
      .then(response => response.json())
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        setLoading(false)
        setMessage(response.message);
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to submit bid'});
        setLoading(false)
        console.error('Error:', error);
      })
    }
  };

  const place_bid = () => {
    setBid({ ...bid, listing_id: id });
    fetch(`/api/bid/${id}/products`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
      },
    })
    .then(response => auth(response))
    .then(response => {
      setProducts(response.data.map(item => item.product));
      setOpen(true)
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch products.'})
      console.error('Error:', error);
    });
  }

  return(
    <div>
      <FileViewer
        open = {viewFile}
        setOpen = {setViewFile}
        id = {bidData['listing_id']}
        type = 'listing'
      />
      <Dialog
        disableBackdropClick={true}
        maxWidth='md'
        fullWidth={true}
        open={open}
        onClose={handleClose}
        >
        <DialogContent>
          <Grid container spacing = {2} className = {classes.place_bid}>
            <Typography>
              Place Bid
            </Typography>
            <Tooltip title='Attach Files'>
              <IconButton
                onClick = {() => setBidAttach(true)}
                className = {classes.attach}
              >
                <AttachFileIcon color = 'primary'/>
              </IconButton>
            </Tooltip>
            <Grid item xs = {12}>
              {fileNames.length > 0 && fileNames.map((name, index) =>
                <Chip color='primary' key = {index} label={name} onDelete={() => chipDelete(index)}/>
              )}
            </Grid>
          </Grid>
          <BidFileSelect
            open = {bidAttach}
            setOpen = {setBidAttach}
            type = 'bid'
            byteArrays = {byteArrays} setByteArrays = {setByteArrays}
            previewURLs = {previewURLs} setPreviewURLs = {setPreviewURLs}
            fileNames = {fileNames} setFileNames = {setFileNames}
            attach = {attach}
          />
          <BidForm
            bid={bid}
            products={products}
            updateField={updateField}
            setBid={setBid}
          />
        </DialogContent>
        <DialogActions>
            <div className = {classes.wrapper}>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
              {loading &&
                <CircularProgress size={32} className = {classes.loading}/>
              }
            </div>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing = {1}>
        <Typography className = {classes.heading} align= "right">
          Status : {status}
        </Typography>
      </Grid>
      <Grid container spacing = {3}>
          {status === 'Rejected' &&
            <Typography className = {classes.reason}>
              Reason : {bidData['reason']}
            </Typography>
          }
      </Grid>
    <Grid container spacing = {1}>
      <Typography className = {classes.heading}>
        Listing Info
      </Typography>
      <Tooltip title='View Attachments'>
        <IconButton
          className = {classes.attachments}
          onClick = {(event) => {
            setViewFile(true)
          }}
        >
          <AttachFileIcon color = 'primary'/>
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid container spacing = {3}>
      <Grid item>
        <TextField
          value = {bidData['listing_id']}
          label = 'Listing Id'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['working_capacity']}
          label = 'Available Volume (MBBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['date_available']}
          label = 'Date Available'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['term']}
          label = 'Term (Months)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['ask_price']}
          label = 'Ask Price (USD/BBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['heel_product'] || ''}
          label = 'Heel Product'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item xs = {6}>
        <TextField
          value = {bidData['comment']}
          label = 'Comments'
          variant="outlined"
          multiline
          InputProps={{
            readOnly: true,
          }}
          className = {classes.multiline}
        />
      </Grid>
    </Grid>
    <Grid container spacing = {3}>
      <Grid item xs = {12}>
        <Typography className = {classes.heading}>
          Storage Info
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['region']}
          label = 'Region'
          variant = 'outlined'
          InputProps = {{
            readOnly: true
          }}
          className = {classes.text}
        />
      </Grid>
      {parseInt(bidData['visibility']) === 2 &&
        <Grid item>
          <TextField
            value = {bidData['locality']}
            label = 'City'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
      }
      {bidData['visibility'] >= 1 &&
        <Grid item>
          <TextField
            value = {bidData['administrative_area_level_1']}
            label = 'State'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
      }
      {bidData['visibility'] >= 1 &&
        <Grid item>
          <TextField
            value = {bidData['country']}
            label = 'Country'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
      }
      <Grid item>
        <TextField
          value = {bidData['total_capacity']}
          label = 'Total Capacity (MBBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['product_category']}
          label = 'Product Category'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['tank_type']}
          label = 'Tank Type'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['tank_material']}
          label = 'Tank Material'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['tank_features'] || ''}
          label = 'Tank Features'
          variant = 'outlined'
          InputProps = {{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['heel_requirement']}
          label = 'Heel Requirement (BBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['blending_capability']}
          label = 'Blendable (Y/N)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['heating_capability']}
          label = 'Heated (Y/N)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['commingled']}
          label = 'Commingled'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {bidData['number_of_tanks']}
          label = 'Number of Tanks'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
    </Grid>

    {/* Access Modes */}
    {bidData['access_modes'] && bidData['access_modes'].length > 0 && (
      <Grid container spacing = {1}>
        <Grid item xs = {12}>
          <Typography className = {classes.heading}>
            Access Modes
          </Typography>
        </Grid>
        <Grid item>
          <AccessModeTable access_modes = {bidData['access_modes']}/>
        </Grid>
      </Grid>
    )}

    {status === 'Open' && !disablePlaceBid &&
      <Grid container spacing = {3} justify='center'>
        <Grid item>
          <Button variant="contained" color="primary" className = {classes.button} onClick = {place_bid}>
            Place Bid
          </Button>
        </Grid>
        <Grid item>
          <Button variant = 'contained' color = 'primary' className = {classes.button} onClick = {send_message}>
            Send Message
          </Button>
        </Grid>
      </Grid>
    }
    <AlertDialog
      open = {inquire}
      setOpen = {setInquire}
      title = 'Start Chat'
      content = 'Are you sure you wish to start a chat with the owner of this listing?'
      submitText = 'Submit'
      submit = {submitInquiry}
    />
  </div>
  )
}
