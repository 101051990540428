import React, { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import AccessModeTable from '../display/AccessModeTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { rangeFilter } from '../FilterFunctions';
import CustomFilter from '../CustomFilter';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

export default function TeamStorageTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/api/storage/team', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setData(response.data || []);
      setBounds && setBounds(response.bounds || {});
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch storage data'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage, setBounds])

  const columns = [
    {
      title: 'Username',
      field: 'username',
      defaultGroupOrder: 0
    },
    {
      title: 'Location',
      field: 'formatted_address',
    },
    {
      title: 'Storage Name',
      field: 'storage_name',
    },
    {
      title: 'Product Category',
      field: 'product_category',
    },
    {
      title: 'Total Capacity (MBBL)',
      field: 'total_capacity',
      type: 'numeric',
      bounds: bounds.total_capacity,
      customFilterAndSearch: rangeFilter('total_capacity'),
    },
    {
      title: 'Tank Material',
      field: 'tank_material',
      lookup: {
        'Carbon Steel': 'Carbon Steel',
        'Stainless Steel': 'Stainless Steel',
        'Carbon Steel Lined': 'Carbon Steel Lined',
        'Nitrogen Blanket': 'Nitrogen Blanket',
        'Chiller': 'Chiller',
        'Insulated': 'Insulated',
      },
    },
    {
      title: 'Tank Type',
      field: 'tank_type',
      lookup: {
        'Internal Floating Roof': 'Internal Floating Roof',
        'External Floating Roof': 'External Floating Roof',
        'Coned Roof': 'Coned Roof',
        'Cavern': 'Cavern',
        'Pressurized': 'Pressurized',
      },
    },
    {
      title: 'Tank Features',
      field: 'tank_features'
    },
    {
      title: 'Heel Requirement (BBL)',
      field: 'heel_requirement',
      type: 'numeric',
      bounds: bounds.heel_requirement,
      customFilterAndSearch: rangeFilter('heel_requirement'),
    },
    {
      title: 'Blendable (Y/N)',
      field: 'blending_capability',
      lookup: {
        1: 'Y',
        0: 'N',
      },
    },
    {
      title: 'Heated (Y/N)',
      field: 'heating_capability',
      lookup: {
        1: 'Y',
        0: 'N',
      },
    },
    {
      title: 'Com or Seg',
      field: 'commingled',
      lookup: {
        1: 'Commingled',
        0: 'Segregated',
      },
    },
    {
      title: 'Number of Tanks',
      field: 'number_of_tanks',
      type: 'numeric',
      bounds: bounds.number_of_tanks,
      customFilterAndSearch: rangeFilter('number_of_tanks'),
    },
  ];

  const accessModes = (rowData) => {
    return (
      <AccessModeTable storage_id={rowData.storage_id} />
    )
  }

  return (
    <MaterialTable
      title='Our Storage'
      columns={columns}
      data={data}
      icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
      options={{
        filtering: true,
        draggable: false,
        exportButton: true,
        grouping: true,
      }}
      components={{
        Groupbar: () => null,
        FilterRow: props => <CustomFilter {...props} />,
      }}
      actions={[
        {
          icon: () => <RefreshIcon color = 'primary'/>,
          tooltip: 'Refresh',
          isFreeAction: true,
          onClick: event => fetchData(!toggle)
        }
      ]}
      detailPanel={accessModes}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
    />
  );
}
