import React, { useState, useEffect } from 'react';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function TeamMembers({ team_id }) {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [members, setMembers] = useState([]);
  const [toggle, fetchData] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({user_id: '', username: ''});

  const handleClose = () => {
    setUser({user_id: '', username: ''});
    setOpen(false);
  }

  useEffect(() => {
    fetch('/api/team/available', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setUsers(response.data || []);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch users'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage])

  useEffect(() => {
    fetch(`/api/team/${team_id}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setMembers(response.data || []);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch members'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage, team_id])

  const handleSubmit = () => {
    fetch(`/api/team/assign/${user.user_id}/${team_id}`, {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
      },
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        handleClose();
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to add member'})
      console.error('Error:', error);
    })
  }

  return (
    <div>
      <MaterialTable
        columns={[
          {
            title: 'User ID',
            field: 'user_id',
          },
          {
            title: 'First Name',
            field: 'first_name',
          },
          {
            title: 'Last Name',
            field: 'last_name',
          },
          {
            title: 'Company',
            field: 'company',
          },
          {
            title: 'Username',
            field: 'username',
          },
          {
            title: 'Email',
            field: 'email',
          },
        ]}
        components={{
          Container: props => (
            <div
              style={{
                background: '#545454',
              }}>
              {props.children}
            </div>
          ),
        }}
        options={{
          filtering: true,
          draggable: false,
          exportButton: true
        }}
        actions={[
          {
            icon: () => <AddBoxIcon/>,
            tooltip: 'Add Member',
            isFreeAction: true,
            onClick: () => setOpen(true)
          },
        ]}
        editable={{
          onRowDelete: oldData =>
            fetch(`/api/team/remove/${oldData.user_id}/${team_id}`, {
              method: 'DELETE',
              headers: {
                'x-access-token': sessionStorage.getItem('token')
              }
            })
            .then((response) => auth(response))
            .then((response) => {
              if (response.message.type === 'success') {
                fetchData(!toggle);
              }
              setMessage(response.message);
            })
            .catch((error) => {
              setMessage({'type': 'error', 'text': 'Error! Unable to delete.'})
              console.error('Error:', error);
            }),
        }}
        title='Members'
        data={members}
      />
      <Dialog
        disableBackdropClick={true}
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        >
        <DialogTitle>Assign Member</DialogTitle>
        <DialogContent>
          <Autocomplete
            options = {users}
            style={{ width: 210 }}
            getOptionLabel={option => option.username}
            onChange={(e, input) => setUser(input ? input : {user_id: '', username: ''})}
            renderInput={(params) => <TextField {...params} value={user.username} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Submit
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
