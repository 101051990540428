import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import Bid from '../landing/Bid'
import InquiryBid from '../landing/InquiryBid'
import Renewal from '../landing/Renewal'
import Listing from '../landing/Listing'
import Inquiry from '../landing/Inquiry'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
}));

export default function InfoDialog({open, onClose, type, id, disablePlaceBid}) {
  const classes = useStyles()

  return(
    <Dialog fullWidth={true} maxWidth='md' open = {open} onClose = {onClose}>
      <DialogTitle className = {classes.closeButton}>
        <IconButton onClick = {onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {type === 'listing' &&
          <Bid id = {id} disable = {disablePlaceBid}/>
        }
        {type === 'inquiry' &&
          <InquiryBid id = {id} disable = {disablePlaceBid}/>
        }
        {type === 'renewal' &&
          <Renewal id = {id} />
        }
        {type === 'listing_info' &&
          <Listing id = {id} disablePlaceBid = {disablePlaceBid}/>
        }
        {type === 'inquiry_info' &&
          <Inquiry id = {id} disablePlaceBid = {disablePlaceBid}/>
        }
      </DialogContent>
    </Dialog>
  )
}
