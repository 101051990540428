import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from '../../context/message';
import { useAuth } from "../../context/auth";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

export default function RenewalForm({open, setOpen, id, type}) {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const classes = useStyles()
  const [term, setTerm] = useState('')
  const [startDate, setStartDate] = useState(Date(0))
  const [price, setPrice] = useState('')
  const [volume, setVolume] = useState('')

  const handleSubmit = (rowData) => {
    fetch(`/api/renew/${id}/${type}`, {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({'start_date' : startDate, 'term' : term, 'price' : price, 'volume' : volume})
    })
    .then(response => auth(response))
    .then(response => {
      if (response.message.type === 'success') {
        handleClose();
      }
      setMessage(response.message);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to submit renewal request'});
      console.error('Error:', error);
    })
  }

  const handleClose = () => {
    setTerm('')
    setStartDate(Date(0))
    setVolume('')
    setPrice('')
    setOpen(false)
  }

  return (
      <Dialog open = {open} onClose = {handleClose}>
        <DialogTitle>
          Renewal Request
        </DialogTitle>
        <DialogContent>
          <TextField
            required
            className={classes.root}
            name = "price"
            label = "Price"
            value =  {price}
            type = 'number'
            onChange= {(event) => setPrice(event.target.value)}
          />
          <TextField
            required
            className={classes.root}
            name = "volume"
            label = "Volume"
            value =  {volume}
            type = 'number'
            onChange= {(event) => setVolume(event.target.value)}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.root}
              format = "MM/dd/yyyy"
              margin = "normal"
              label = "Start Date"
              name = "date_available"
              value = {startDate}
              onChange = {(long, short) => setStartDate(short)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            required
            className={classes.root}
            name = "term"
            label = "Term (Months)"
            value =  {term}
            type = 'number'
            onChange= {(event) => setTerm(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick = {handleClose} color = 'primary'>
            Cancel
          </Button>
          <Button onClick = {handleSubmit} color = 'primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  )
}
