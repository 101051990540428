import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import UndoIcon from '@material-ui/icons/Undo';
import ReportsTable from '../display/ReportsTable'

const columns = [
  {
    'title' : 'User ID',
    'field' : 'user_id'
  },
  {
    'title' : 'First Name',
    'field' : 'first_name'
  },
  {
    'title' : 'Last Name',
    'field' : 'last_name'
  },
  {
    'title' : 'Company',
    'field' : 'company'
  },
  {
    'title' : 'Username',
    'field' : 'username'
  },
  {
    'title' : 'Email',
    'field' : 'email'
  },
  {
    'title' : 'Permission Level',
    'field' : 'category'
  },
  {
    'title' : 'Office Phone',
    'field' : 'office_phone'
  },
  {
    'title' : 'Cell Phone',
    'field' : 'cell_phone'
  },
  {
    'title' : 'Reports',
    'field' : 'report_total'
  }
]

export default function SuspendedUser(){
  const { setMessage } = useMessage()
  const { auth } = useAuth();
  const [data, setData] = useState([])
  const [fetchUsers, setFetchUsers] = useState(false)

  useEffect(() => {
    fetch('/api/user/suspend',{
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch suspend users'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUsers, setMessage])

  return (
    <MaterialTable
      title={'Suspended User'}
      columns={columns}
      data={data}
      options={{
        filtering: true,
        draggable: false,
        exportButton: true,
      }}
      detailPanel={
        rowData=> <ReportsTable user_id = {rowData.user_id}/>
      }
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      actions ={[
        rowData => ({
          //un suspend
          tooltip: 'Unsuspend',
          icon: () => <UndoIcon/>,
          onClick: (event, rowData) => {
            if (window.confirm('Are you sure you want to unsuspend user?')){
              fetch(`/api/user/unsuspend/${rowData.user_id}`, {
                method: 'POST',
                headers: {
                  'x-access-token': sessionStorage.getItem('token')
                }
              })
              .then((response) => auth(response))
              .then((response) => {
                setFetchUsers(!fetchUsers)
                setMessage(response.message)
              })
              .catch((error) => {
                console.log('Error:', error)
              })
            }
          }
        })
      ]}
    />
  )
}
