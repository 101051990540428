import React, { useState, useEffect } from 'react';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';

export default function TeamAdminTable() {
  const { setMessage } = useMessage();
  const { auth, team_id } = useAuth();
  const [members, setMembers] = useState([]);
  const [toggle, fetchData] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleClose = () => {
    setEmail('');
    setOpen(false);
  }

  useEffect(() => {
    fetch(`/api/team/${team_id}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setMembers(response.data || []);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch members'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage, team_id])

  const handleInvite = () => {
    fetch('/api/team/invite', {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email})
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        handleClose();
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to invite member'})
      console.error('Error:', error);
    })
  }

  return (
    <div>
      <MaterialTable
        columns={[
          {
            title: 'User ID',
            field: 'user_id',
            editable: 'never'
          },
          {
            title: 'First Name',
            field: 'first_name',
          },
          {
            title: 'Last Name',
            field: 'last_name',
          },
          {
            title: 'Username',
            field: 'username',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Password',
            field: 'password',
            editable: 'onAdd',
            editComponent: props => (
              <TextField
                label='Password'
                type='password'
                value={props.value || ''}
                onChange={e => props.onChange(e.target.value)}
              />
            )
          },
          {
            title: 'Office Phone',
            field: 'office_phone',
            type: 'numeric',
          },
          {
            title: 'Cell Phone',
            field: 'cell_phone',
            type: 'numeric',
          },
        ]}
        options={{
          filtering: true,
          draggable: false,
          exportButton: true
        }}
        actions={[
          {
            icon: () => <SendIcon color = 'primary'/>,
            tooltip: 'Invite Member',
            isFreeAction: true,
            onClick: () => setOpen({...open, add: true})
          },
        ]}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              fetch(`/api/team/user/${team_id}`, {
                method: 'POST',
                headers: {
                  'x-access-token': sessionStorage.getItem('token'),
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(newData),
              })
              .then((response) => auth(response))
              .then((response) => {
                if (response.message.type === 'success') {
                  fetchData(!toggle);
                } else {
                  reject();
                }
                setMessage(response.message);
                resolve();
              })
              .catch((error) => {
                setMessage({'type': 'error', 'text': 'Error! Unable to create record.'})
                console.error('Error:', error);
                reject();
              })
            }),
          onRowDelete: oldData =>
            fetch(`/api/team/remove/${oldData.user_id}/${team_id}`, {
              method: 'DELETE',
              headers: {
                'x-access-token': sessionStorage.getItem('token')
              }
            })
            .then((response) => auth(response))
            .then((response) => {
              if (response.message.type === 'success') {
                fetchData(!toggle);
              }
              setMessage(response.message);
            })
            .catch((error) => {
              setMessage({'type': 'error', 'text': 'Error! Unable to delete.'})
              console.error('Error:', error);
            }),
        }}
        title='Members'
        data={members}
      />
      <Dialog
        disableBackdropClick={true}
        maxWidth='md'
        open={open}
        onClose={handleClose}
        >
        <DialogTitle>Invite Users</DialogTitle>
        <DialogContent>
          <TextField
            label='Email'
            name='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInvite} color="primary">
            Invite
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
