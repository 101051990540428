import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StorageIcon from '@material-ui/icons/Storage';
import BatteryStdIcon from '@material-ui/icons/BatteryStd';
import ListIcon from '@material-ui/icons/List';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useAuth } from "../../../context/auth";
import { Link } from 'react-router-dom';
import TutorialStep from '../../display/TutorialStep'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function StorageList({ open, setOpen}) {
  const classes = useStyles();
  const { tutorialStep, setTutorialStep } = useAuth();
  const storageRef = useRef(null)
  const listingRef = useRef(null)
  const inquiryRef = useRef(null)
  const bidRef = useRef(null)
  const bookingRef = useRef(null)

  const handleClick = () => {
    setOpen({...open, account: !open.account});
  };

  const advanceTutorial = () => {
    if (tutorialStep === 8 || tutorialStep === 13 || tutorialStep === 22 || tutorialStep === 24 || tutorialStep === 27){
      setTutorialStep(tutorialStep + 1)
    }
  }

  return (
    <div>
      <ListItem button onClick={handleClick} disabled = {tutorialStep !== 0}>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="My Account" />
        {open.account ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open.account} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink button onClick = {advanceTutorial} disabled = {tutorialStep !== 0 && tutorialStep !== 8} className={classes.nested} component={Link} to='/my/storage' >
            <ListItemIcon>
              <BatteryStdIcon />
            </ListItemIcon>
            <ListItemText primary="My Storage" ref = {storageRef}/>
          </ListItemLink>
          <ListItemLink button onClick = {advanceTutorial} disabled = {tutorialStep !== 0 && tutorialStep !== 13} className={classes.nested} component={Link} to='/my/listing'>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="My Listings" ref = {listingRef}/>
          </ListItemLink>
          <ListItemLink button onClick = {advanceTutorial} disabled = {tutorialStep !== 0 && tutorialStep !== 22} className={classes.nested} component={Link} to='/my/inquiry'>
            <ListItemIcon>
              <BatteryUnknownIcon />
            </ListItemIcon>
            <ListItemText primary="My Inquiries" ref = {inquiryRef}/>
          </ListItemLink>
          <ListItemLink button onClick = {advanceTutorial} disabled = {tutorialStep !== 0 && tutorialStep !== 24} className={classes.nested} component={Link} to='/my/bid'>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="My Bids" ref = {bidRef}/>
          </ListItemLink>
          <ListItemLink button onClick = {advanceTutorial} disabled = {tutorialStep !== 0 && tutorialStep !== 27} className={classes.nested} component={Link} to='/my/booking'>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="My Bookings" ref = {bookingRef}/>
          </ListItemLink>
        </List>
      </Collapse>
      {tutorialStep === 8 &&
          <TutorialStep text = 'click here' refPosition = {storageRef.current}/>
      }
      {tutorialStep === 13 &&
        <TutorialStep text = 'click here' refPosition = {listingRef.current}/>
      }
      {tutorialStep === 22 &&
        <TutorialStep text = 'click here' refPosition = {inquiryRef.current}/>
      }
      {tutorialStep === 24 &&
        <TutorialStep text = 'click here' refPosition = {bidRef.current}/>
      }
      {tutorialStep === 27 &&
        <TutorialStep text = 'click here' refPosition = {bookingRef.current}/>
      }
    </div>
  );
}
