import React, { useState, useEffect } from 'react'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import BidFileSelect from '../inputs/BidFileSelect'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { useField } from '../../context/context'
import AddStorage from '../storage/AddStorage'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'
import CloseIcon from '@material-ui/icons/Close'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: 350
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    top: -5,
    width: 12,
    height: 12
  },
  title: {
    marginLeft: 5,
    marginTop: 5,
    display: 'flex',
    'flex-direction': 'row'
  },
  attach: {
    height: 30,
    width: 30,
    top: -5,
    left: 5,
  },
  label: {
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(1.5)
  },
  heading: {
    fontSize: 16
  },
  select: {
    width: 350
  },
  button: {
    margin: theme.spacing(2),
  },
  remove: {
    marginTop: theme.spacing(2)
  },
  textfield: {
    width: 350
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const datePlusOneYear = (date) => {
  let temp = new Date(date)
  temp.setMonth(temp.getMonth() + 12)
  return temp
}

export default function AddListing({open, setOpen, fetchData, toggle, myStorageID, setMyStorageID}) {
  const classes = useStyles()
  const { auth, tutorialStep, setTutorialStep } = useAuth()
  const { setMessage } = useMessage()
  const { products } = useField()
  const [loading, setLoading] = useState(false)
  const [product_list, setProduct_list] = useState([])
  const [hidePriceDialog, setHidePriceDialog] = useState(false)
  const [expiration, setExpiration] = useState(true)
  const [listing, setListing] = useState({
      ask_price: '',
      hide_price: false,
      comment: '',
      date_available: Date(0),
      expiration: datePlusOneYear(Date(0)),
      delay_after_commit: '',
      heel_product: '',
      storage_id: '',
      term: '',
      visibility: '',
      working_capacity: '',
      allow_sublet: '',
      name: '',
      file:''
  })
  //file attach
  const [fileAttach, setFileAttach] = useState(false)
  const [byteArrays, setByteArrays] = useState([])
  const [previewURLs, setPreviewURLs] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [fileToggle, setFileToggle] = useState(false)
  //add storage
  const [storage_id, setStorage_id] = useState(0)
  const [addStorage, setAddStorage] = useState(false)
  const [storage, setStorage] = useState([])
  const [fetchStorage, setFetchStorage] = useState(false)

  useEffect(() => {
    fetch('/api/storage/my', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setStorage(response.data || []);
    })
    .catch((error) => {
      setMessage({type: 'error', text: 'Error! Unable to fetch storage'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStorage, myStorageID])

  useEffect(() => {
    if (products){
      let temp = {}
      temp = products.map(item => item.product)
      temp.push('Empty')
      setProduct_list(temp);
    }
  }, [products])

  useEffect(() => {setListing({...listing, storage_id: myStorageID})}, [myStorageID])

  const submit = () => {
    if(!loading){
      setLoading(true)
      fetch(`/api/listing`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(listing)
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
          if (tutorialStep === 17)
            setTutorialStep(18)
          fetchData(!toggle)
        }
        setMessage(response.message);
        setLoading(false)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to create listing'});
        setLoading(false)
        console.error('Error:', error);
      })
    }
  }

  const handleClosePriceDialog = () => {
    setHidePriceDialog(false)
  }

  const handleClose = () => {
    setExpiration(true)
    setListing({
      ask_price: '',
      hide_price: false,
      blending_fee: '',
      comment: '',
      date_available: Date(0),
      expiration: datePlusOneYear(Date(0)),
      delay_after_commit: '',
      heel_product: '',
      show_city: '',
      storage_id: '',
      tank_to_tank_transfer_fee: '',
      term: '',
      working_capacity: '',
      allow_sublet: '',
      'name': '',
      'file': '',
    })
    setByteArrays([])
    setPreviewURLs([])
    setFileNames([])
    if (setMyStorageID) {
      setMyStorageID(0)
    }
    setOpen(false)
  }

  const updateField = e => {
    setListing({
      ...listing,
      [e.target.name]: e.target.value
    });
  };

  const chipDelete = (index) => {
    fileNames.splice(index,1)
    byteArrays.splice(index,1)
    previewURLs.splice(index,1)
    setFileToggle(!fileToggle)
  }

  const switchChange = (event) => {
    setListing({...listing, 'hide_price': event.target.checked})
  }

  const attach = () => {
    setListing({
      ...listing,
      'name': fileNames,
      'file': byteArrays,
    })
  }

  return(
    <div>
      <Dialog open={hidePriceDialog} onClose={handleClosePriceDialog}>
        <DialogTitle className = {classes.closeButton} onClick={handleClosePriceDialog}>
          <IconButton aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant = 'h6' color = 'primary'>
            Hide Price
          </Typography>
          <Typography>
            You can choose to hide shell price. If hidden, it will show up as 'Undisclosed'.
          </Typography>
        </DialogContent>
      </Dialog>
      <AddStorage
        open = {addStorage}
        setOpen = {setAddStorage}
        storage_id = {storage_id}
        setStorage_id = {setStorage_id}
        fetchData = {setFetchStorage}
        toggle = {fetchStorage}
      />
      <BidFileSelect
        open = {fileAttach}
        setOpen = {setFileAttach}
        byteArrays = {byteArrays} setByteArrays = {setByteArrays}
        previewURLs = {previewURLs} setPreviewURLs = {setPreviewURLs}
        fileNames = {fileNames} setFileNames = {setFileNames}
        attach = {attach}
      />
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='lg'
      >
        <DialogTitle>
          <Grid container spacing = {2} className = {classes.title}>
              <Typography>
                Add Listing
              </Typography>
              <Tooltip title='Attach Files'>
                <IconButton
                  onClick = {() => setFileAttach(true)}
                  className = {classes.attach}
                >
                  <AttachFileIcon color = 'primary'/>
                </IconButton>
              </Tooltip>
              <Grid item xs = {12}>
                {fileNames.length > 0 && fileNames.map((name, index) =>
                  <Chip color='primary' key = {index} label={name} onDelete={() => chipDelete(index)} style={{margin:2.5}}/>
                )}
              </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item className={classes.root}>
              <FormControl>
                <InputLabel className={classes.label}>Storage Name</InputLabel>
                <Select
                  className={classes.select}
                  value = {listing.storage_id}
                  onChange = {updateField}
                  name = 'storage_id'
                  label = 'Storage Name'
                  variant="outlined"
                >
                  <MenuItem onClick = {()=>setAddStorage(true)}><Link>Add Storage</Link></MenuItem>
                  {storage && storage.map((item) =>
                    <MenuItem key = {item.storage_id} value = {item.storage_id}> {item.storage_name} </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                className={classes.textfield}
                required
                name = "working_capacity"
                label = "Working Capacity (MBBL)"
                value =  {listing.working_capacity}
                onChange={updateField}
                variant="outlined"
                type = 'number'
              />
            </Grid>
            <Grid item className = {classes.root} >
              <Grid container direction = 'row' justifyContent="space-evenly">
                <TextField
                  required
                  style = {{width: '65%'}}
                  name = 'ask_price'
                  label = 'Shell Price (USD/BBL)'
                  value = {listing.ask_price}
                  onChange = {updateField}
                  variant = 'outlined'
                  type = 'number'
                />
                <Grid container direction = 'row' alignItems="center" style = {{width: '35%', position: 'relative', left: '15px'}}>
                  <Typography variant= 'caption'>Show</Typography>
                  <Grid item>
                    <Switch size = 'small' checked={listing.hide_price} onChange={switchChange} name="hide_price" color = 'primary'/>
                  </Grid>
                  <Typography variant= 'caption'>
                    Hide
                    <IconButton color = 'primary' className = {classes.icon} onClick = {() => setHidePriceDialog(true)}>
                      <HelpIcon className = {classes.icon}/>
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className = {classes.root}
                  format='MM/dd/yyyy'
                  margin = "normal"
                  label = "Start Date"
                  name = "date_available"
                  value = {listing.date_available}
                  onChange = {(long, short) => {
                    if(expiration){
                      setListing({...listing, date_available: short, expiration: datePlusOneYear(short)})
                    } else {
                      setListing({...listing, date_available: short})
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputVariant = 'outlined'
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className = {classes.root}
                  format='MM/dd/yyyy'
                  margin = "normal"
                  label = "Expiration Date"
                  name = "expiration"
                  value = {listing.expiration}
                  onChange = {(long, short) => {
                    setListing({...listing, expiration: short})
                    setExpiration(false)
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  inputVariant = 'outlined'
                />
              </MuiPickersUtilsProvider>
            <Grid item className = {classes.root}>
              <TextField
                className = {classes.textfield}
                required
                name = 'term'
                label = 'Term (Months)'
                value = {listing.term}
                onChange = {updateField}
                variant = 'outlined'
                type = 'number'
              />
            </Grid>
            <Grid item className = {classes.root}>
              <TextField
                className = {classes.textfield}
                required
                name = 'delay_after_commit'
                label = 'Availability after Commitment (Months)'
                value = {listing.delay_after_commit}
                onChange = {updateField}
                variant = 'outlined'
                type = 'number'
              />
            </Grid>
            <Grid item className={classes.root}>
                <Autocomplete
                  options = {product_list}
                  onChange={(e, input) => setListing({...listing, heel_product: input})}
                  renderInput={(params) => <TextField {...params} value={listing.heel_product} variant = 'outlined' label = 'Heel Product'/>}
                />
            </Grid>
            <Grid item className = {classes.root}>
              <TextField
                className = {classes.textfield}
                required
                name = 'comment'
                label = 'Comment'
                value = {listing.comment}
                onChange = {updateField}
                variant = 'outlined'
              />
            </Grid>
            <Grid item className={classes.root}>
              <FormControl>
                <InputLabel className={classes.label}>Location Visibility</InputLabel>
                <Select
                  className={classes.select}
                  value = {listing.visibility}
                  onChange = {updateField}
                  name = 'visibility'
                  label = 'Location Visibility'
                  variant="outlined"
                >
                  <MenuItem value = {0}>Show Region Only</MenuItem>
                  <MenuItem value = {1}>Show State Only</MenuItem>
                  <MenuItem value = {2}>Show Full Location</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item className = {classes.root}>
              <FormControl>
                <InputLabel className={classes.label}>Allow Sublet</InputLabel>
                <Select
                  className = {classes.select}
                  value = {listing.allow_sublet}
                  onChange = {updateField}
                  name = 'allow_sublet'
                  label = 'Allow Sublet'
                  variant = 'outlined'
                >
                  <MenuItem value = {1}> Y </MenuItem>
                  <MenuItem value = {0}> N </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className = {classes.wrapper}>
            <Button onClick={submit} color="primary">
              Add
            </Button>
            {loading &&
              <CircularProgress size={32} className = {classes.loading}/>
            }
          </div>
          <Button
            onClick = {() => {
              if (tutorialStep === 17)
                setTutorialStep(16)
              handleClose()
            }}
            color = 'primary'
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
