import React from 'react';
import TeamPlacedBidsTable from './TeamPlacedBidsTable';
import TeamPlacedInquiryBidsTable from '../inquiry/TeamPlacedInquiryBidsTable';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(5)
  }
}))
export default function MyListingsPage() {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.table}>
        <TeamPlacedBidsTable />
      </Box>
      <Box className={classes.table}>
        <TeamPlacedInquiryBidsTable />
      </Box>
    </Box>
  )
}
