import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { useMessage } from '../../context/message';
import { Redirect } from 'react-router-dom';

export default function AcceptInvite() {
  let { token } = useParams();
  const { auth } = useAuth();
  const { setMessage } = useMessage();

  useEffect(() => {
    fetch(`/api/team/accept/${token}`, {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setMessage(response.message);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to find invitation'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage])

  return (
    <Redirect to='/team/members'/>
  );
}
