import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Popover from '@material-ui/core/Popover'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles((theme) => ({
  control_spacing: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(1.5)
  },
  div: {
    paddingTop: 20
  }
}))

export default function AccessModeFilter({filters, setFilters}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleChange = (event) => {
    setFilters({...filters, [event.target.name] : !filters[event.target.name]})
  }

  return (
    <div className = {classes.div}>
      <Button variant = 'contained' color = 'primary' onClick = {handleClick} endIcon = {<ArrowDropDownIcon/>}>
        Access Modes
      </Button>
      <Popover
        open = {open}
        anchorEl = {anchorEl}
        onClose = {handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid classes = {classes.control_spacing}>
          <Grid item className = {classes.control_spacing}>
            <FormControl>
              <FormLabel>
                Barge
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  name = 'barge_in'
                  control = {<Checkbox color = 'primary' checked = {filters.barge_in} size = 'small'/>}
                  label = 'Inbound'
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'barge_out'
                  control = {<Checkbox color = 'primary' checked = {filters.barge_out} size = 'small'/>}
                  label = 'Outbound'
                  onChange = {handleChange}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item className = {classes.control_spacing}>
            <FormControl>
              <FormLabel>
                Pipeline
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  name = 'pipe_in'
                  control = {<Checkbox color = 'primary' checked = {filters.pipe_in} size = 'small'/>}
                  label = 'Inbound'
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'pipe_out'
                  control = {<Checkbox color = 'primary' checked = {filters.pipe_out} size = 'small'/>}
                  label = 'Outbound'
                  onChange = {handleChange}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item className = {classes.control_spacing}>
            <FormControl>
              <FormLabel>
                Rail
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  name = 'rail_in'
                  control = {<Checkbox color = 'primary' checked = {filters.rail_in} size = 'small'/>}
                  label = 'Inbound'
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'rail_out'
                  control = {<Checkbox color = 'primary' checked = {filters.rail_out} size = 'small'/>}
                  label =  'Outbound'
                  onChange = {handleChange}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item className = {classes.control_spacing}>
            <FormControl>
              <FormLabel>
                Truck
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  name = 'truck_in'
                  control = {<Checkbox color = 'primary' checked = {filters.truck_in} size = 'small'/>}
                  label = 'Inbound'
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'truck_out'
                  control = {<Checkbox color = 'primary' checked = {filters.truck_out} size = 'small'/>}
                  label = 'Outbound'
                  onChange = {handleChange}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item className = {classes.control_spacing}>
            <FormControl>
              <FormLabel>
                Vessel
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  name = 'vessel_in'
                  control = {<Checkbox color = 'primary' checked = {filters.vessel_in} size = 'small'/>}
                  label = 'Inbound'
                  onChange = {handleChange}
                />
                <FormControlLabel
                  name = 'vessel_out'
                  control = {<Checkbox color = 'primary' checked = {filters.vessel_out} size = 'small'/>}
                  label = 'Outbound'
                  onChange={handleChange}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </div>
  )
}
