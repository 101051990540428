import React, { useState, useEffect, useRef } from 'react';
import EditableTable from '../inputs/EditableTable';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useField } from '../../context/context'
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import AddListing from './AddListing'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AlertDialog from '../inputs/AlertDialog'
import TutorialStep from '../display/TutorialStep'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'

export default function MyListingTable() {
  const { setMessage } = useMessage();
  const { auth, tutorialStep, setTutorialStep } = useAuth();
  const { products} = useField()
  const [storage_list, setStorage_list] = useState({});
  const [product_lookup, setProduct_lookup] = useState({});
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [open, openInfo] = useState(false)
  const [addListing, setAddListing] = useState(false)
  const [listing_id, setListingId] = useState('')
  const [deleteDialog, setDelete] = useState(false)
  const addRef = useRef()

  const handleCloseInfo = () => {
    openInfo(false)
    setListingId(0)
  }

  useEffect(() => {
    fetch('/api/storage/my', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      let temp = {};
      response.data.map(item => temp[item.storage_id] = item.storage_name);
      setStorage_list(temp);
    })
    .catch((error) => {
      setMessage({type: 'error', text: 'Error! Unable to fetch storage'});
      console.error('Error:', error);
    })
    if(products){
      let temp = {}
      temp = {};
      products.map(item => temp[item.product] = item.product);
      temp['Empty'] = 'Empty';
      setProduct_lookup(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[products])

  const columns = [
    {
      title: 'Listing ID',
      field: 'listing_id',
      editable: 'never',
    },
    {
      title: 'Storage Name',
      field: 'storage_id',
      lookup: storage_list,
    },
    {
      title: 'Working Capacity (MBBL)',
      field: 'working_capacity',
      type: 'numeric',
      bounds: bounds.working_capacity,
      customFilterAndSearch: rangeFilter('working_capacity'),
    },
    {
      title: 'Shell Price (USD/BBL)',
      field: 'ask_price',
      type: 'numeric',
      bounds: bounds.ask_price,
      customFilterAndSearch: rangeFilter('ask_price'),
    },
    {
      title: 'Term (Months)',
      field: 'term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Availability after Commitment (Months)',
      field: 'delay_after_commit',
      type: 'numeric',
      bounds: bounds.delay_after_commit,
      customFilterAndSearch: rangeFilter('delay_after_commit'),
    },
    {
      title: 'Heel Product',
      field: 'heel_product',
      lookup: product_lookup,
    },
    {
      title: 'Start Date',
      field: 'date_available',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('date_available'),
      customSort: (a,b) => {
        var date1 = new Date(a.date_available)
        var date2 = new Date(b.date_available)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.date_available)).toLocaleDateString()}</p>
    },
    {
      title: 'Comment',
      field: 'comment',
      render: rowData => (
        rowData.comment && (rowData.comment.length > 30 ? rowData.comment.substring(0,30) + '...' : rowData.comment)
      )
    },
    {
      title: 'Location Visibility',
      field: 'visibility',
      lookup: {
        0: 'Region Only',
        1: 'State Only',
        2: 'Full Location'
      }
    },
    {
      title: 'Allow Sublet',
      field: 'allow_sublet',
      lookup: {
        0: 'N',
        1: 'Y',
      }
    }
  ];

  const deleteListing = () => {
    fetch(`/api/listing/${listing_id}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        fetchData(!toggle)
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to delete listing.'})
      console.error('Error:', error);
    })
    setListingId(0)
  }

  return (
    <div>
      <AddListing
        open = {addListing}
        setOpen = {setAddListing}
        toggle = {toggle}
        fetchData = {fetchData}
      />
      <AlertDialog
        open = {deleteDialog}
        setOpen = {setDelete}
        title = 'Delete Listing'
        content = 'Are you sure you want to delete this listing?'
        submitText = 'Delete'
        submit = {deleteListing}
      />
      <InfoDialog
        open = {open}
        onClose = {handleCloseInfo}
        type = 'listing_info'
        id = {listing_id}
        disablePlaceBid = {true}
      />
      <EditableTable
        title='My Listings'
        table='listing'
        toggle={toggle}
        fetchData={fetchData}
        columns={columns}
        setBounds={setBounds}
        actions = {[
        {
          icon: () => <AddBoxIcon color = 'primary' ref = {addRef}/>,
          tooltip: 'Add Listing',
          isFreeAction: true,
          onClick: (e, rowData) => {
            setAddListing(true)
            if (tutorialStep === 16)
              setTutorialStep(17)
          }
        },
        {
          icon: () => <InfoIcon color = 'primary'/>,
          tooltip: 'More Info',
          onClick: (e, rowData) => {
            setListingId(rowData.listing_id)
            openInfo(true)
          }
        },
        {
          icon: () => <DeleteOutlineIcon color = 'primary'/>,
          tooltip: 'Delete Listing',
          onClick: (e, rowData) => {
            setListingId(rowData.listing_id)
            setDelete(true)
          }
        }
        ]}
      />
      {tutorialStep === 16 &&
        <TutorialStep refPosition = {addRef.current} text = 'Click here to add Listing'/>
      }
    </div>
  );
}
