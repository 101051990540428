import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  div: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  backdrop: {
    position: 'absolute',
    opacity: .5,
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    'z-index': 1
  },
  container: {
    position: 'relative',
    top: '50%',
    'z-index': 2
  },
  button: {
    width: 150,
    height: 50
  },
  text: {
    marginBottom: 20,
    maxWidth: "90%"
  }
}));

export default function DataCover(){
  const classes = useStyles()

  return (
    <div className = {classes.div}>
      <div className = {classes.backdrop}/>
        <Grid container justify = 'center' className = {classes.container} spacing = {2}>
          <Typography variant = 'h6' className = {classes.text}>
            You must be subscribed to view this data
          </Typography>
        </Grid>
        <Grid container justify = 'center' className = {classes.container} spacing = {2}>
          <Button variant = 'contained' color = 'primary' className = {classes.button} component = {Link} to = '/contactus'>
            Contact Sales
          </Button>
        </Grid>
    </div>
  )
}
