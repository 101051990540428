import React, { useState, useEffect } from 'react'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { useField } from '../../context/context'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: 300
  },
  label: {
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(1.5)
  },
  heading: {
    fontSize: 16
  },
  select: {
    width: 300
  },
  button: {
    margin: theme.spacing(2),
  },
  remove: {
    marginTop: theme.spacing(2)
  },
  textfield: {
    width: 300
  }
}));

export default function AddUser({open, setOpen, user_id, setUser_id, toggle, fetchData}) {
  const classes = useStyles()
  const { auth } = useAuth()
  const { setMessage } = useMessage()
  const [user, setUser] = useState({
      'first_name': '',
      'last_name': '',
      'company': '',
      'username': '',
      'email': '',
      'password': '',
      'category': '',
      'office_phone': '',
      'cell_phone': '',
      'job_title': '',
      'data_sub': 0
  })

  useEffect(() => {
    //set user for editing
    if (open){
      if (user_id !== 0)
      {
        //fetch user
        fetch(`/api/user/${user_id}`, {
          method: 'GET',
          headers: {
            'x-access-token': sessionStorage.getItem('token')
          }
        })
        .then((response) => auth(response))
        .then((response) => {
          setUser(response.data)
        })
        .catch((error) => {
          setMessage({'type': 'error', 'text': 'Error! Unable to fetch user details.'})
          handleClose()
          console.error('Error:', error);
        })
      }
    }
  }, [user_id, open])

  const submit = () => {
    if (user_id === 0){
      //add
      fetch(`/api/user`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
      .then(response => auth(response))
      .then(response => {
        setMessage(response.message);
        if (response.message.type === 'success') {
          handleClose();
        }
        fetchData(!toggle)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to create user'});
        console.error('Error:', error);
      })
    } else {
      //edit
      fetch(`/api/user/${user_id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        setMessage(response.message);
        fetchData(!toggle)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to update user'});
        console.error('Error:', error);
      })
    }
  }


  const handleClose = () => {
    setOpen(false)
    if (user_id !== 0)
      setUser_id(0)
    setUser({
      'first_name': '',
      'last_name': '',
      'company': '',
      'username': '',
      'email': '',
      'password': '',
      'category': '',
      'office_phone': '',
      'cell_phone': '',
      'job_title': '',
      'data_sub': 0
    })

  }

  const updateField = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  return(
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='lg'
    >
      <DialogTitle>
        {user_id !== 0 ? 'Update User' : 'Add User'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item className={classes.root}>
            <TextField
              className={classes.textfield}
              required
              name = "first_name"
              label = "First Name"
              value =  {user.first_name}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              required
              className={classes.textfield}
              name = 'last_name'
              label = 'Last Name'
              value =  {user.last_name}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              required
              className={classes.textfield}
              name = 'username'
              label = 'Username'
              value =  {user.username}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              required
              className={classes.textfield}
              name = 'email'
              label = 'Email Address'
              value =  {user.email}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          {user_id === 0 &&
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'password'
                label = 'Password'
                value =  {user.password}
                onChange={updateField}
                variant="outlined"
              />
            </Grid>
          }
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Permission Level</InputLabel>
              <Select
                className = {classes.select}
                value = {user.category}
                onChange = {updateField}
                name = 'category'
                label = 'Permission Level'
                variant="outlined"
              >
                <MenuItem value = 'Viewer'> Viewer </MenuItem>
                <MenuItem value = 'Trader'> Trader </MenuItem>
                <MenuItem value = 'Terminal'> Terminal </MenuItem>
                <MenuItem value = 'Admin'> Admin </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              className={classes.textfield}
              name = 'cell_phone'
              label = 'Cell Phone'
              value =  {user.cell_phone}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              className={classes.textfield}
              name = 'office_phone'
              label = 'Office Phone'
              value =  {user.office_phone}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              className={classes.textfield}
              name = 'company'
              label = 'Company'
              value =  {user.company}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <TextField
              className={classes.textfield}
              name = 'job_title'
              label = 'Job Title'
              value =  {user.job_title}
              onChange={updateField}
              variant="outlined"
            />
          </Grid>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Data Sub</InputLabel>
              <Select
                className = {classes.select}
                value = {user.data_sub}
                onChange = {updateField}
                name = 'data_sub'
                label = 'Data Sub'
                variant="outlined"
              >
                <MenuItem value = {0}> N </MenuItem>
                <MenuItem value = {1}> Y </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick = {submit} color = 'primary'>
          {user_id !== 0 ? 'Update' : 'Add'}
        </Button>
        <Button
          onClick = {handleClose}
          color = 'primary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>

  )
}
