import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useField } from '../../context/context'
import { useChat } from '../../context/chat'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AccessModeTable from '../display/AccessModeTable'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import FileViewer from '../display/FileViewer'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import InquiryBidForm from '../inquiry/InquiryBidForm'
import Chip from '@material-ui/core/Chip'
import BidFileSelect from '../inputs/BidFileSelect'
import CircularProgress from '@material-ui/core/CircularProgress'
import AlertDialog from '../inputs/AlertDialog'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 20,
    marginBottom:10,
  },
  attachments: {
    top: 20,
    height: 30,
    width: 30
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 20,
    marginLeft: 20,
    marginRight:10,
    color: theme.palette.primary.main
  },
  reason: {
    marginTop: 20,
    marginLeft: 40,
    marginBottom:10,
  },
  text: {
    marginLeft : 20,
    marginTop : 20,
  },
  multiline: {
    marginLeft : 20,
    marginTop : 20,
    width : '77%'
  },
  multiline2: {
    marginTop: 10,
    width: '98%'
  },
  place_bid: {
    marginLeft: 5,
    marginTop: 5,
    display: 'flex',
    'flex-direction': 'row',
  },
  attach: {
    height: 30,
    width: 30,
    top: -5,
    left: 5
  },
  chip: {
    bottom: 20,
    left: 20
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export default function Inquiry({id, disablePlaceBid}){
  const {setMessage} = useMessage();
  const { auth } = useAuth();
  const { storage } = useField();
  const classes = useStyles();
  const [status, setStatus] = useState('');
  //message button
  const [inquire, setInquire] = useState(false)
  const { setChatroom, setCurrentChat, setOpenConversations, openConversations } = useChat()
  //file viewer
  const [viewFile, setViewFile] = useState(false)
  //file select
  const [bidAttach, setBidAttach] = useState(false)
  const [byteArrays, setByteArrays] = useState([])
  const [previewURLs, setPreviewURLs] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [bidToggle, setBidToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [bid, setBid] = useState({
    inquiry_id: '',
    storage_id: '',
    volume: '',
    ask_price: '',
    term: '',
    date_available: new Date(),
    comment: '',
    allow_sublet: 0,
    file: [],
    name: []
  });
  const [inquiryData, setInquiryData] = useState({
    'inquiry_id': '',
    'formatted_address': '',
    'radius': '',
    'region': '',
    'bid_price': '',
    'product': '',
    'min_volume': '',
    'max_volume': '',
    'term': '',
    'start_date': '',
    'comment' : '',
  })

  const handleClose = () => {
    setOpen(false);
    setFileNames([])
    setPreviewURLs([])
    setByteArrays([])
  };

  const send_message = () => {
    setInquire(true)
  }

  const closeInquire = () => {
    setInquire(false)
  }

  const submitInquiry = () => {
    fetch(`/api/message/inquire/inquiry/${inquiryData.inquiry_id}`, {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      },
    })
    .then(response => auth(response))
    .then(response => {
      if (response.message.type === 'success'){
        openConversations.push(response.data)
        setOpenConversations([...openConversations])
        setCurrentChat(response.data)
        setChatroom(true)
      }
      setMessage(response.message)
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to start conversation'})
      console.log('Error', error)
    })
    closeInquire()
  }

  const updateField = e => {
    setBid({
      ...bid,
      [e.target.name]: e.target.value
    });
  };

  const attach = () => {
    setBid({
      ...bid,
      'name': fileNames,
      'file': byteArrays,
    })
  }

  const handleSubmit = () => {
    if(!loading) {
      setLoading(true)
      fetch(`/api/inquiry_bids/${id}`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bid)
      })
      .then(response => auth(response))
      .then(data => {
        if (data.message.type === 'success') {
          handleClose()
        }
        setMessage(data.message);
        setLoading(false)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to submit bid'});
        setLoading(false)
        console.error('Error:', error);
      })
    }
  };

  const chipDelete = (index) => {
    fileNames.splice(index,1)
    byteArrays.splice(index,1)
    previewURLs.splice(index,1)
    setBidToggle(!bidToggle)
  }

  const place_bid = () => {
    setOpen(true)
    setBid({ ...bid, inquiry_id: id });
  }

  useEffect(() => {
    fetch(`/api/inquiry/${id}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.data){
        setStatus(response.data['status'])
        if(!response.data['comment']){
          response.data['comment'] = ''
        }
        setInquiryData(response.data)
      }
      else
        setStatus('Does not exist!')
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch inquiry information.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[ id, setMessage]);

  return(
    <div>
      <FileViewer
        open = {viewFile}
        setOpen = {setViewFile}
        id = {inquiryData['inquiry_id']}
        type = 'inquiry'
      />
      <Dialog
        disableBackdropClick={true}
        maxWidth='md'
        fullWidth={true}
        open={open}
        onClose={handleClose}
        >
        <DialogContent>
          <Grid container spacing = {2} className = {classes.place_bid}>
            <Typography>
              Place Bid
            </Typography>
            <Tooltip title='Attach Files'>
              <IconButton
                onClick = {() => setBidAttach(true)}
                className = {classes.attach}
              >
                <AttachFileIcon color = 'primary'/>
              </IconButton>
            </Tooltip>
            <Grid item xs = {12}>
              {fileNames.length > 0 && fileNames.map((name, index) =>
                <Chip color='primary' key = {index} label={name} onDelete={() => chipDelete(index)}/>
              )}
            </Grid>
          </Grid>
          <InquiryBidForm
            bid={bid}
            updateField={updateField}
            setBid={setBid}
            storage={storage}
          />
          <BidFileSelect
            open = {bidAttach}
            setOpen = {setBidAttach}
            type = 'inquiryBid'
            byteArrays = {byteArrays} setByteArrays = {setByteArrays}
            previewURLs = {previewURLs} setPreviewURLs = {setPreviewURLs}
            fileNames = {fileNames} setFileNames = {setFileNames}
            attach = {attach}
          />
        </DialogContent>
        <DialogActions>
            <div className = {classes.wrapper}>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
              {loading &&
                <CircularProgress size={32} className = {classes.loading}/>
              }
            </div>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
        </DialogActions>
      </Dialog>
    <Grid container>
      <Typography className = {classes.heading} align= "right">
        Status : {status}
      </Typography>
      <Tooltip title='View Attachments'>
        <IconButton
          className = {classes.attachments}
          onClick = {(event) => {
            setViewFile(true)
          }}
        >
          <AttachFileIcon color = 'primary'/>
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid container spacing = {3}>
      <Grid item>
        <TextField
          value = {inquiryData['inquiry_id']}
          label = 'Inquiry Id'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      {inquiryData['place_id'] !== '' &&
        <React.Fragment>
          <Grid item>
            <TextField
              value = {inquiryData['formatted_address']}
              label = 'Location'
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              className = {classes.text}
            />
          </Grid>
          <Grid item>
            <TextField
              value = {inquiryData['radius']}
              label = 'Radius (mi)'
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              className = {classes.text}
            />
          </Grid>
        </React.Fragment>
      }
      {inquiryData['place_id'] === '' &&
        <Grid item>
          <TextField
            value = {inquiryData['region']}
            label = 'Region'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            className = {classes.text}
          />
        </Grid>
      }
      <Grid item>
        <TextField
          value = {inquiryData['bid_price']}
          label = 'Bid Price (USD/BBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {inquiryData['product']}
          label = 'Product'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {inquiryData['min_volume']}
          label = 'Minimum Volume (MBBL)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {inquiryData['term']}
          label = 'Term (Months)'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item>
        <TextField
          value = {inquiryData['start_date']}
          label = 'Start Date'
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          className = {classes.text}
        />
      </Grid>
      <Grid item xs = {6}>
        <TextField
          value = {inquiryData['comment']}
          label = 'Comments'
          variant="outlined"
          multiline
          InputProps={{
            readOnly: true,
          }}
          className = {classes.multiline}
        />
      </Grid>
    </Grid>

    {inquiryData['access_modes'] && inquiryData['access_modes'].length > 0 && (
      <Grid container spacing = {1}>
        <Grid item xs = {12}>
          <Typography className = {classes.heading}>
            Access Modes
          </Typography>
        </Grid>
        <Grid item>
          <AccessModeTable access_modes = {inquiryData['access_modes']}/>
        </Grid>
      </Grid>
    )}

    {status === 'Open' && !disablePlaceBid &&
      <Grid container spacing = {3} justify='center'>
        <Grid item>
          <Button variant="contained" color="primary" className = {classes.button} onClick = {place_bid}>
            Place Bid
          </Button>
        </Grid>
        <Grid item>
          <Button variant = 'contained' color = 'primary' className = {classes.button} onClick = {send_message}>
            Send Message
          </Button>
        </Grid>
      </Grid>
    }
    <AlertDialog
      open = {inquire}
      setOpen = {setInquire}
      title = 'Start Chat'
      content = 'Are you sure you wish to start a chat with the owner of this inquiry?'
      submitText = 'Submit'
      submit = {submitInquiry}
    />
  </div>
  )
}
