import React, {useState, useEffect} from 'react'
import {Copyright} from './Login'
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import AccountTypeInfo from './AccountTypeInfo'
import Privacy from './Privacy'
import Terms from './Terms'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 20,
    marginLeft: 10,
    marginRight:10,

    color: theme.palette.primary.main
  },
  accountType: {
    marginTop: 20,
    marginLeft: 20,
    marginRight:10,
    color: theme.palette.primary.main
  },
  radio: {
    color: theme.palette.primary.main,
    marginTop: 10
  },
  preference: {
    color: theme.palette.primary.main,
    marginLeft: 20,
    marginTop: 20
  },
  selects: {
    width: 215
  },
  icon: {
    width: 17,
    height: 17
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  submit: {
    marginTop: 10,
    marginBottom: 10
  }
}));

export default function SignUp({close}) {
  const classes = useStyles()
  const {setMessage} = useMessage()
  const {auth} = useAuth()
  const [openTerms, setOpenTerms] = useState(false)
  const [openPrivacy, setOpenPrivacy] = useState(false)
  const [regions, setRegions] = useState([])
  const [products, setProducts] = useState([])
  const [form, setState] = useState({
    username:'',
    first_name:'',
    last_name:'',
    email:'',
    cell_phone:'',
    office_phone:'',
    category:'Viewer',
    company:'',
    job_title:'',
    password:'',
    confirmPassword:'',
    preferredRegions: [],
    preferredProducts: []
  })
  const [info, setInfo] = useState(false)

  useEffect(() => {
    fetch('/api/context/sign_up',{
      methods: 'GET'
    })
    .then(response => auth(response))
    .then(data => {
      if (data.regions){
        setRegions(data.regions.map((item) => item.region))
      }
      if (data.product_categories){
        setProducts(data.product_categories.map((item) => item.product_category))
      }
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch context.'})
      console.error('Error:', error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateField = e => {
    setState({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const updateRadio = e => {
    setState({
      ...form,
      category: e.target.value
    })
  }

  const accountTypeInfo = () => {
    setInfo(true)
  }
  const handleCloseInfo = () => {
    setInfo(false)
  }

  const submit = () => {
    fetch('/api/user/sign_up', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form),
    })
    .then(response => auth(response))
    .then(data => {
      if (data.message.type === 'success') {
        if (close)
          close()
      }
      setMessage(data.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to create account.'})
      console.error('Error:', error);
    });
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (form.password === form.confirmPassword){
      setOpenTerms(true)
    } else {
      setMessage({'type': 'error', 'text': 'Error! Passwords must match.'})
    }
  }

  const agreeToTerms = () => {
    setOpenTerms(false)
    setOpenPrivacy(true)
  }
   const agreeToPrivacy = () => {
     setOpenPrivacy(false)
     submit()
   }

  return (
    <div>
      <Privacy open = {openPrivacy} setOpen = {setOpenPrivacy} agree = {agreeToPrivacy}/>
      <Terms open = {openTerms} setOpen = {setOpenTerms} agree = {agreeToTerms}/>
      <Dialog fullWidth={true} maxWidth = {'md'} open={info} onClose={handleCloseInfo}>
        <DialogTitle className = {classes.closeButton} onClick={handleCloseInfo}>
          <IconButton aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AccountTypeInfo/>
        </DialogContent>
      </Dialog>
      <CssBaseline/>
      <form onSubmit={handleSubmit}>
        <Grid container spacing = {3}>
          <Grid item xs = {12}>
            <Typography className = {classes.heading}>
              Request New Account
            </Typography>
          </Grid>
          <Grid item>
            <TextField
            required
            name = "first_name"
            label = "First Name"
            variant="outlined"
            value =  {form.first_name}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            required
            name = "last_name"
            label = "Last Name"
            variant="outlined"
            value =  {form.last_name}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            required
            id = "username"
            name = "username"
            label = "Username"
            variant="outlined"
            value =  {form.username}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            required
            id = "email"
            name = "email"
            label = "Email Address"
            variant="outlined"
            value =  {form.email}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            required
            name = "password"
            label = "Password"
            variant="outlined"
            type = 'password'
            value =  {form.password}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            required
            name = "confirmPassword"
            label = "Confirm Password"
            variant="outlined"
            type = 'password'
            value =  {form.confirmPassword}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            name = "cell_phone"
            label = "Cell Phone"
            variant="outlined"
            value =  {form.cell_phone}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            name = "office_phone"
            label = "Office Phone"
            variant="outlined"
            value =  {form.office_phone}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            name = "company"
            label = "Company"
            variant="outlined"
            value =  {form.company}
            onChange={updateField}
            />
          </Grid>
          <Grid item>
            <TextField
            name = "job_title"
            label = "Job Title"
            variant="outlined"
            value =  {form.job_title}
            onChange={updateField}
            />
          </Grid>
        </Grid>
        <Grid container spacing = {3} style = {{marginTop: 10}}>
          <Grid item xs = {12}>
            <Typography className={classes.preference}>
              Preferences
            </Typography>
          </Grid>
          <Grid item>
            <FormControl variant = 'outlined'>
              <InputLabel>
                Region
              </InputLabel>
              <Select
                multiple
                name = 'preferredRegions'
                value={form.preferredRegions}
                onChange={updateField}
                renderValue={(preferredRegions) => (Array.isArray(preferredRegions) && preferredRegions.join(', '))}
                className = {classes.selects}
              >
                {regions && regions.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked = {form.preferredRegions.indexOf(item) > -1} />
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant = 'outlined'>
              <InputLabel>
                Product Category
              </InputLabel>
              <Select
                multiple
                value = {form.preferredProducts}
                onChange = {updateField}
                name = 'preferredProducts'
                renderValue={(preferredProducts) => (Array.isArray(preferredProducts) && preferredProducts.join(', '))}
                className = {classes.selects}
              >
                {products && products.map((item) => (
                  <MenuItem value = {item} key = {item}>
                    <Checkbox checked = {form.preferredProducts.indexOf(item) > -1} />
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing = {2}>
          <Grid item>
            <Typography className={classes.accountType}>
              Account type
              <IconButton color = 'primary' className = {classes.icon} onClick = {accountTypeInfo}>
                <HelpIcon className = {classes.icon}/>
              </IconButton>
            </Typography>

          </Grid>
          <FormControlLabel
            value="bottom"
            control={
              <Radio
                checked={form.category === 'Viewer'}
                onChange = {updateRadio}
                value = 'Viewer'
                color = 'primary'
                size = 'small'
              />
            }
            label="Viewer"
            labelPlacement="bottom"
            className = {classes.radio}
          />
          <FormControlLabel
            value="bottom"
            control={
              <Radio
                checked={form.category === 'Trader'}
                onChange = {updateRadio}
                value = 'Trader'
                color = 'primary'
                size = 'small'
              />
            }
            label="Trader"
            labelPlacement="bottom"
            className = {classes.radio}
          />
          <FormControlLabel
            value="bottom"
            control={
              <Radio
                checked={form.category === 'Terminal'}
                onChange = {updateRadio}
                value = 'Terminal'
                color = 'primary'
                size = 'small'
              />
            }
            label="Terminal"
            labelPlacement="bottom"
            className = {classes.radio}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs align='center'>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      <Copyright />
    </div>

  );
}
