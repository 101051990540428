import React, { useState } from 'react'
import { useMessage } from '../../context/message';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import FilePreviewer from 'react-file-previewer-v2';

export default function BidFileSelect({open, setOpen, fileNames, setFileNames, byteArrays, setByteArrays, previewURLs, setPreviewURLs, attach}) {
  const { setMessage } = useMessage()
  const [toggle, setToggle] = useState(false)
  const [currentPreview, setCurrentPreview] = useState(0)

  const handleClose = () => {
    setFileNames([])
    setByteArrays([])
    setPreviewURLs([])
    setCurrentPreview(0)
    setOpen(false)
  }

  const handleChange = (e) => {
    const file = e.target.files[0]
    if (fileNames.includes(file['name'])){
      setMessage({'type': 'error', 'text':'Unable to attach file. File is already attached.'})
      document.getElementById('FileSelect').value = ''
      return
    }
    if (file['type'] !== 'application/pdf'){
      setMessage({'type': 'error', 'text':'Unable to attach file. File must be a PDF file.'})
      document.getElementById('FileSelect').value = ''
      return
    }
    fileNames.push(file['name'])
    setFileNames(fileNames)

    const fileReader = new FileReader()
    const byteReader = new FileReader()

    byteReader.onload = fileLoad => {
      const { result } = fileLoad.target
      var arrayBuffer = result,
      array = new Uint8Array(arrayBuffer)
      byteArrays.push(array)
      setByteArrays(byteArrays)
    }
    byteReader.readAsArrayBuffer(file)

    fileReader.onload = fileLoad => {
      const { result } = fileLoad.target
      previewURLs.push({'url':result})
      setPreviewURLs(previewURLs)
      setCurrentPreview(previewURLs.length - 1)
      setToggle(!toggle)
    }
    fileReader.readAsDataURL(file)

    document.getElementById('FileSelect').value = ''
  }

  const attachFiles = () =>
  {
    setOpen(false)
    setCurrentPreview(0)
    attach()
  }

  const chipClick = (index) => {
    setCurrentPreview(index)
    setToggle(!toggle)
  }

  const chipDelete = (index) => {
    if (currentPreview === index) {
        setCurrentPreview(0)
    }
    fileNames.splice(index,1)
    byteArrays.splice(index,1)
    previewURLs.splice(index,1)
    setToggle(!toggle)
  }

  return (
    <Dialog open = {open} onClose = {handleClose}>
      <DialogTitle>
        Attach Files
      </DialogTitle>
      <DialogContent>
        <Grid container spacing = {1}>
          <Grid item xs = {12}>
            <input id='FileSelect' accept='.pdf' type='file' onChange= {handleChange} />
          </Grid>
            {fileNames.length > 0 && fileNames.map((name, index) =>
              <Grid item key={index}>
                <Chip color='primary' label={name} onClick={() => chipClick(index)} onDelete={() => chipDelete(index)}/>
              </Grid>
            )}
          <Grid item xs = {12}>
            {previewURLs.length > 0 && previewURLs[currentPreview] &&
              <FilePreviewer
                hideControls
                file={
                  previewURLs[currentPreview]
                }/>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
          <Button color = 'primary' onClick = {attachFiles}>
            Attach
          </Button>
          <Button color = 'primary' onClick = {handleClose}>
            Cancel
          </Button>
      </DialogActions>
    </Dialog>
  )
}
