import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Label, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { useMessage } from '../../context/message';
import { useAuth } from '../../context/auth';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import DateRangeFilter from '../inputs/DateRangeFilter'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 400
  },
  filter: {
    marginLeft: theme.spacing(2)
  }
}));

const today = new Date();

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export default function Forecast() {
  const theme = useTheme();
  const classes = useStyles();
  const { auth } = useAuth();
  const { setMessage } = useMessage();
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: addDays(today, -183),
    endDate: addDays(today, 182)
  });

  useEffect(() => {
    fetch(`/api/booking/forecast/my?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`, {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setData(response)
    })
    .catch(error => {
      setMessage({'type': 'error', 'text': 'Error! Unable to booking data.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMessage, dateRange])

  return (
    <Paper className={classes.root}>
      <React.Fragment>
        <Grid container justify = 'center'>
          <Typography variant = 'button' style = {{fontSize: 16, marginTop: 10}}>
            Revenue forecast
          </Typography>
        </Grid>
        <Grid container justify = 'center'>
          <FormControlLabel
            className={classes.filter}
            control={<DateRangeFilter
              value = {dateRange}
              onChange = {setDateRange}
              definedRanges = {[
                {
                  label: 'YTD',
                  startDate: new Date(today.getFullYear(), 0, 1),
                  endDate: today
                },
                {
                  label: 'This Month',
                  startDate: new Date(today.getFullYear(), today.getMonth(), 1),
                  endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0)
                },
                {
                  label: 'Next Month',
                  startDate: new Date(today.getFullYear(), today.getMonth() + 1, 1),
                  endDate: new Date(today.getFullYear(), today.getMonth() + 2, 0)
                },
                {
                  label: 'This Quarter',
                  startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4) * 3, 1),
                  endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 0)
                },
                {
                  label: 'Next Quarter',
                  startDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 1) * 3, 1),
                  endDate: new Date(today.getFullYear(), Math.ceil(today.getMonth()/4 + 2) * 3, 0)
                },
                {
                  label: 'This Year',
                  startDate: new Date(today.getFullYear(), 0, 1),
                  endDate: new Date(today.getFullYear(), 12, 0)
                },
                {
                  label: 'Next Year',
                  startDate: new Date(today.getFullYear() + 1, 0, 1),
                  endDate: new Date(today.getFullYear() + 1, 12, 0)
                },
                {
                  label: 'Reset',
                  startDate: addDays(today, -183),
                  endDate: addDays(today, 182)
                },
              ]}
            />}
          />
        </Grid>
        <ResponsiveContainer height = '85%'>
          <BarChart
            data={data}
            margin={{
              top: 16,
              right: 16,
              bottom: 16,
              left: 24,
            }}
            >
            <XAxis
              dataKey="date"
              stroke={theme.palette.text.secondary}
              tickFormatter={unixTime => moment(unixTime).format('MMM YY')}
              domain={['auto', 'auto']}
            />
            <YAxis stroke={theme.palette.text.secondary} >
              <Label
                angle={270}
                position='left'
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                >
                  Revenue / Cost (Thousands USD)
              </Label>
            </YAxis>
            <Legend verticalAlign='top'/>
            <Tooltip cursor={false}/>
            <Bar dataKey="Revenue" fill="#03fc77" />
            <Bar dataKey="Lease Costs" fill={theme.palette.primary.main} />
          </BarChart>
        </ResponsiveContainer>
      </React.Fragment>
    </Paper>
  )
}
