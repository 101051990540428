import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import FilePreviewer from 'react-file-previewer-v2';

export default function FileViewer({open, setOpen, id, type}) {
  const [loading, setLoading] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [currentPreview, setCurrentPreview] = useState(0)
  const [fileNames, setFileNames] = useState([])
  const [previewURLs, setPreviewURLs] = useState([])

  const handleClose = () => {
    setFileNames([])
    setPreviewURLs([])
    setOpen(!open)
  }

  const chipClick = (index) => {
    setCurrentPreview(index)
    setToggle(!toggle)
  }

  useEffect(() => {
    if(open){
      setLoading(true)
      fetch(`/api/files/${type}/${id}`, {
        method: 'GET',
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        },
      })
      .then((response) => response.json())
      .then((response) => {
        var i;
        for(i = 0; i < response.data.length; i++){
          fileNames.push(response.data[i]['name'])
          setFileNames(fileNames)

          previewURLs.push({
            data: response.data[i]['content'],
            mimeType:'application/pdf',
            name: response.data[i]['name']
          })
          setPreviewURLs(previewURLs)
        }
        setLoading(false)
        setToggle(!toggle)
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error', error);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog open = {open} onClose = {handleClose}>
      <DialogTitle>
        View Files
      </DialogTitle>
      <DialogContent>
        <Grid container spacing = {1}>
            {fileNames.length > 0 && fileNames.map((name, index) =>
              <Grid key={index} item>
                <Chip color='primary' label={name} onClick={() => chipClick(index)}/>
              </Grid>
            )}
          <Grid item xs = {12}>
            {loading &&
              <CircularProgress/>
            }
            {previewURLs.length > 0 && previewURLs[currentPreview] &&
              <FilePreviewer
                file={
                  previewURLs[currentPreview]
                }/>
            }
            {previewURLs.length === 0 && !loading &&
              <Typography>
                No files attached.
              </Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
          <Button color = 'primary' onClick = {handleClose}>
            Close
          </Button>
      </DialogActions>
    </Dialog>
  )
}
