import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'

const PipelineComponent = ({ map, maps, pipelineData, strokeColor, visible }) => {
  const [polylines, setPolylines] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (map && maps && pipelineData) {
      // Clear existing polylines when pipelineData changes or component mounts
      polylines.forEach(polyline => polyline.setMap(null)); // Remove existing polylines
      setPolylines([]); // Clear state of polylines

      // Render new polylines if visible is true
      if (visible) {
        const newPolylines = pipelineData.features.map(feature => {
          if (feature.geometry && feature.geometry.type === 'LineString' && feature.geometry.coordinates) {
            const path = feature.geometry.coordinates.map(coord => ({
              lat: coord[1],
              lng: coord[0]
            }));

            const polyline = new maps.Polyline({
              path: path,
              strokeColor: strokeColor || '#FF0000', // Default to red if strokeColor not provided
              strokeOpacity: 1,
              strokeWeight: 2,
              zIndex:2,
              map: map
            });

            // Add event listeners
            polyline.addListener('mouseover', () => {
              polyline.setOptions({ strokeWeight: 4 });
            });
            polyline.addListener('mouseout', () => {
              polyline.setOptions({ strokeWeight: 2 });
            });
            polyline.addListener('click', () => {
              setSelectedPipeline(feature)
              setOpenDialog(true)
            });

            return polyline;
          }
          return null;
        });

        setPolylines(newPolylines.filter(polyline => polyline !== null)); // Update state with new polylines
      }
    }
  }, [map, maps, pipelineData, strokeColor, visible]);

  // Hide or show polylines based on visibility
  useEffect(() => {
    polylines.forEach(polyline => {
      if (visible) {
        polyline.setMap(map);
      } else {
        polyline.setMap(null);
      }
    });
  }, [visible, map, polylines]);

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  return (
    <>
      {/* Material-UI Dialog for displaying pipeline information */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Pipeline Information</DialogTitle>
        <DialogContent>
          {selectedPipeline && (
            <>
              <p><strong>Operator Name:</strong> {selectedPipeline.properties.Opername}</p>
              <p><strong>Pipeline Name:</strong> {selectedPipeline.properties.Pipename}</p>
              {/* Add other properties as needed */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )};

export default PipelineComponent;
