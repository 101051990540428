import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import RefreshIcon from '@material-ui/icons/Refresh';

const columns = [
  {
    'title' : 'First Name',
    'field' : 'first_name',
  },
  {
    'title': 'Last Name',
    'field': 'last_name'
  },
  {
    'title': 'Company',
    'field': 'company'
  },
  {
    'title': 'Username',
    'field': 'username'
  },
  {
    'title': 'Email',
    'field': 'email'
  },
  {
    'title': 'Permission Level',
    'field': 'category'
  },
  {
    'title': 'Office Phone',
    'field': 'office_phone'
  },
  {
    'title': 'Cell Phone',
    'field': 'cell_phone'
  }
]

const circleSize = 48;
const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: (-circleSize / 2),
    marginLeft: (-circleSize / 2),
  },
  accessModes: {
    margin: theme.spacing(3),
    width: '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 10,
  },
}));

export default function PlacedBids(){
  const [pending_users, setPendingUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const classes = useStyles();
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [fetchPendingUsers, setFetchPendingUsers] = useState(false)

  useEffect(() => {
    fetch('/api/user/pending_user', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setPendingUsers(response.data);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch listings.'})
      console.error('Error:', error);
    })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[fetchPendingUsers,setMessage]);


  return(
    <Grid item xs={12} className={classes.wrapper}>
      <MaterialTable
        title = "Pending Users"
        columns = {columns}
        data = {pending_users}
        options={{
          filtering: true,
          draggable: false,
          actionsColumnIndex: 0
        }}
        actions={[
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: (event) => setFetchPendingUsers(!fetchPendingUsers)
          },
          {
            icon: 'check',
            tooltip: 'Accept User',
            onClick: (event,rowData) => {
              fetch(`/api/user/accept/${rowData['pending_user_id']}`, {
                method:'POST',
                headers: {
                  'x-access-token': sessionStorage.getItem('token')
                }
              })
              .then((response) => auth(response))
              .then((response) => {
                setMessage(response.message);
                setFetchPendingUsers(!fetchPendingUsers)
              })
              .catch((error) => {
                setMessage({'type':'error', 'text':'Error! Unable to accept user.'})
                console.error('Error:', error)
              })
            }
          },
          {
            icon: 'cancel',
            tooltip: 'Reject User',
            onClick: (event, rowData) => {
              fetch(`/api/user/reject/${rowData['pending_user_id']}`, {
                method:'DELETE',
                headers: {
                  'x-access-token': sessionStorage.getItem('token')
                }
              })
              .then((response) => auth(response))
              .then((response) => {
                setMessage(response.message);
                setFetchPendingUsers(!fetchPendingUsers)
              })
              .catch((error) => {
                setMessage({'type':'error', 'text':'Error! Unable to accept user.'})
                console.error('Error:', error)
              })
            }
          }
        ]}
      />
      {loading && <CircularProgress size={circleSize} className={classes.buttonProgress}/>}
    </Grid>
  )
}
