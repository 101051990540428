import React, { useState, useEffect } from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";

const useStyles = makeStyles(theme => ({
  table: {
    margin: theme.spacing(3),
    width: '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 10,
  }
}));

export default function ReportsTable({user_id}){
  const [reports, setReports] = useState([])
  const { setMessage } = useMessage()
  const { auth } = useAuth();
  const classes = useStyles()

  useEffect(() => {
    fetch(`/api/report/${user_id}`,{
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setReports(response.data);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch suspend users'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Report ID</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Reporter User ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {reports.length > 0 && reports.map((report) => (
          <TableRow key = {report.report_id}>
            <TableCell>{report.report_id}</TableCell>
            <TableCell>{report.reason}</TableCell>
            <TableCell>{report.description}</TableCell>
            <TableCell>{report.created}</TableCell>
            <TableCell>{report.reporter_id}</TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
