import React, { useEffect, useState } from 'react'
import { useAuth } from "../../context/auth";
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AlertDialog from '../inputs/AlertDialog'

export default function TutorialBar() {
  const { auth, tutorialStep, setTutorialStep, userCategory } = useAuth()
  const [text, setText] = useState('Welcome to the dashboard')
  const [alert, setAlert] = useState(false)
  const disabled = [4,6,8,10,11,13,16,17,20,22,24,27,30,33]
  const skipped = [10,11,16,17,33]

  const nextStep = () => {
    //skip creating storage and listings for traders
    if (tutorialStep === 9 && userCategory === 'Trader'){
      setTutorialStep(13)
    }
    else if (tutorialStep === 15 && userCategory === 'Trader') {
      setTutorialStep(19)
    }
    else if (tutorialStep === 7 && userCategory === 'Viewer') {
      setTutorialStep(30)
    }
    else {
      setTutorialStep(tutorialStep + 1)
    }
  }

  useEffect(() => {
    if (tutorialStep){
      switch(tutorialStep) {
        case 2:
          setText('Welcome to the dashboard')
        break;
        case 3:
          setText('The map shows all current listings and inquiries and can be filtered by product, price, and volume.')
        break;
        case 4:
          setText('Select the Listings page.')
        break;
        case 5:
          setText('This is where you can view and place bids on all available listings.')
        break;
        case 6:
          setText('Select the Inquiries page.')
        break;
        case 7:
          setText('This is where you can view and place bids on all available inquiries.')
        break;
        case 8:
          setText('Select the My Storage page.')
        break;
        case 9:
          if (userCategory === 'Trader'){
            setText('This is where you can view your booked storage locations.')
          }else {
            setText('This is where you can view and create additional storage locations.')
          }
        break;
        case 10:
          setText('Create a new Storage Location.')
        break;
        case 11:
          setText('Fill out all fields.')
        break;
        case 12:
          setText('You have now created a storage location that you can post listings or bid on inquiries with.')
        break;
        case 13:
          setText('Select the My Listings page.')
        break;
        case 14:
          setText('The first table shows your current listings and allows you to add new listings.')
        break;
        case 15:
          setText('In the bottom table you can review and accept bids that have been placed on your listings.')
        break;
        case 16:
          setText('Create a new listing.')
        break;
        case 17:
          setText('Select one of your storage locations and fill out the rest of the fields.')
        break;
        case 18:
          setText('You have posted a new listing! You will be notified when another user places a bid on that listing.')
        break;
        case 19:
          setText('You can also delete the listing or attach any files to the listing that you want bidders to view.')
        break;
        case 20:
          setText('Select the bell to view your notifications.')
        break;
        case 21:
          setText('This will show you notifications when bids are placed, accepted, rejected, etc.')
        break;
        case 22:
          setText('Select the My Inquiries page.')
        break;
        case 23:
          setText('This is where you can view and create new inquiries. It works similarly to the My Listings page.')
        break;
        case 24:
          setText('Select the My Bids page.')
        break;
        case 25:
          setText('This is where you can view and cancel your bids on both listings and inquiries.')
        break;
        case 26:
          setText('This page also shows all available listings and inquiries.')
        break;
        case 27:
          setText('Select the My Bookings page.')
        break;
        case 28:
          setText('This is where you can view bookings on your storage and offers.')
        break;
        case 29:
          setText('This page also allows you to renew bookings and review renewal requests.')
        break;
        case 30:
          setText('Select the Profile page.')
        break;
        case 31:
          setText('This page allows you to update your user info and create preferences.')
        break;
        case 32:
          setText('Preferences pre-filter all listings and inquiries shown based on the selected product category and region.')
        break;
        case 33:
          setText('Create a preference.')
        break;
        case 34:
          setText('You have now completed the walkthrough.')
        break;
        case 35:
          //end tutorial
          setTutorialStep(0)
          fetch('/api/user/tutorial/finish', {
            method: 'PUT',
            headers: {
              'x-access-token': sessionStorage.getItem('token'),
              'Content-Type': 'application/json'
            },
          })
          .then((response) => auth(response))
          .then((response) => {

          })
          .catch((error) => {
            console.error('Error:', error);
          })

        break;
        default:
        setText('My Tank Tiger Tutorial')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorialStep])

  const endTutorial = () => {
    setAlert(true)
  }

  const end = () => {
    setAlert(false)
    setTutorialStep(35)
  }

  const skip = () => {
    if(tutorialStep === 10 || tutorialStep === 11){
      setTutorialStep(13)
    } else if (tutorialStep === 16 || tutorialStep === 17) {
      setTutorialStep(19)
    } else if (tutorialStep === 33) {
      setTutorialStep(34)
    }
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={tutorialStep > 1}
      >
        <SnackbarContent
          style={{backgroundColor: '#fc6c05'}}
          message={
            <div>
              <Typography>
                {text}
              </Typography>
            </div>
          }
          action={
            <div>
              {(tutorialStep !== 34 && tutorialStep !== 0) &&
                <Button disabled = {disabled.indexOf(tutorialStep) >= 0} onClick = {nextStep}>
                  Next
                </Button>
              }
              {skipped.indexOf(tutorialStep) >= 0 &&
                <Button onClick = {skip}>
                  Skip
                </Button>
              }
              <Button onClick = {tutorialStep === 34 ? end : endTutorial}>
                End
              </Button>
            </div>

          }
        />
      </Snackbar>
      <AlertDialog
        open = {alert}
        setOpen = {setAlert}
        title = 'End Tutorial'
        content = 'Ending the tutorial will mark it as completed. Are you sure you want to end tutorial?'
        submitText = 'End tutorial'
        submit = {end}
      />
    </div>
  )
}
