import React, { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import CustomFilter from '../CustomFilter';
import FileViewer from '../display/FileViewer'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

export default function TeamStorageTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [data, setData] = useState([]);
  const [listing_id, setListingId] = useState(0)
  const [viewFile, setViewFile] = useState(false)

  useEffect(() => {
    fetch('/api/listing/team', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then(response => {
      setData(response.data || []);
      setBounds && setBounds(response.bounds || {});
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to fetch storage data'});
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage, setBounds])

  const columns = [
    {
      title: 'Username',
      field: 'username',
      defaultGroupOrder: 0
    },
    {
      title: 'Listing ID',
      field: 'listing_id',
    },
    {
      title: 'Storage Name',
      field: 'storage_name',
    },
    {
      title: 'Working Capacity (MBBL)',
      field: 'working_capacity',
      type: 'numeric',
      bounds: bounds.working_capacity,
      customFilterAndSearch: rangeFilter('working_capacity'),
    },
    {
      title: 'Shell Price (USD/BBL)',
      field: 'ask_price',
      type: 'numeric',
      bounds: bounds.ask_price,
      customFilterAndSearch: rangeFilter('ask_price'),
    },
    {
      title: 'Term (Months)',
      field: 'term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Availability after Commitment (Months)',
      field: 'delay_after_commit',
      type: 'numeric',
      bounds: bounds.delay_after_commit,
      customFilterAndSearch: rangeFilter('delay_after_commit'),
    },
    {
      title: 'Heel Product',
      field: 'heel_product',
    },
    {
      title: 'Start Date',
      field: 'date_available',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('date_available'),
    },
    {
      title: 'Comment',
      field: 'comment',
    },
    {
      title: 'Show City',
      field: 'show_city',
      lookup: {
        0: 'N',
        1: 'Y',
      }
    },
    {
      title: 'Allow Sublet',
      field: 'allow_sublet',
      lookup: {
        0: 'N',
        1: 'Y'
      }
    }
  ];

  return (
    <div>
      <MaterialTable
        title='Our Listings'
        columns={columns}
        data={data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        options={{
          filtering: true,
          draggable: false,
          exportButton: true,
          grouping: true
        }}
        components={{
          Groupbar: () => null,
          FilterRow: props => <CustomFilter {...props} />,
        }}
        actions={[
          {
            icon: () => <RefreshIcon color = 'primary'/>,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: event => fetchData(!toggle)
          },
          {
            icon: () => <AttachFileIcon color = 'primary'/>,
            tooltip: 'View Attachments',
            onClick: (event, rowData) => {
              setListingId(rowData.listing_id)
              setViewFile(true)
            }
          }
        ]}
      />
      <FileViewer open = {viewFile} setOpen = {setViewFile} id = {listing_id} type = 'listing' />
    </div>
  );
}
