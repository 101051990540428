import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'

const MarineComponent = ({ map, maps, marineData, strokeColor, visible }) => {
  const [polylines, setPolylines] = useState([]);
  const [selectedMarine, setSelectedMarine] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (map && maps && marineData) {
      // Clear existing polylines when pipelineData changes or component mounts
      polylines.forEach(polyline => polyline.setMap(null)); // Remove existing polylines
      setPolylines([]); // Clear state of polylines

      // Render new polylines if visible is true
      if (visible) {
        const newPolylines = marineData.features.map(feature => {
          if (feature.geometry && feature.geometry.type === 'LineString' && feature.geometry.coordinates) {

            const path = feature.geometry.coordinates.map(coord => ({
              lat: coord[1],
              lng: coord[0]
            }));
            const polyline = new maps.Polyline({
              path: path,
              strokeColor: strokeColor || '#FF0000', // Default to red if strokeColor not provided
              strokeOpacity: 1,
              strokeWeight: 2,
              map: map
            });

            // Add event listeners
            polyline.addListener('mouseover', () => {
              polyline.setOptions({ strokeWeight: 4 });
            });
            polyline.addListener('mouseout', () => {
              polyline.setOptions({ strokeWeight: 2 });
            });
            polyline.addListener('click', () => {
              setSelectedMarine(feature)
              setOpenDialog(true)
            });

            return polyline;
          }
          return null;
        });

        setPolylines(newPolylines.filter(polyline => polyline !== null)); // Update state with new polylines
      }
    }
  }, [map, maps, marineData, strokeColor, visible]);

  // Hide or show polylines based on visibility
  useEffect(() => {
    polylines.forEach(polyline => {
      if (visible) {
        polyline.setMap(map);
      } else {
        polyline.setMap(null);
      }
    });
  }, [visible, map, polylines]);

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  return (
    <>
      {/* Material-UI Dialog for displaying pipeline information */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Marine Information</DialogTitle>
        <DialogContent>
          {selectedMarine && (
            <>
              <p><strong>OBJECTID:</strong> {selectedMarine.properties.OBJECTID}</p>
              <p><strong>linknum:</strong> {selectedMarine.properties.linknum}</p>
              <p><strong>mh_id:</strong> {selectedMarine.properties.mh_id}</p>
              <p><strong>ww_name:</strong> {selectedMarine.properties.ww_name}</p>
              <p><strong>st_usps:</strong> {selectedMarine.properties.st_usps}</p>
              <p><strong>st_fips:</strong> {selectedMarine.properties.st_fips}</p>
              <p><strong>st_usps_2:</strong> {selectedMarine.properties.st_usps_2}</p>
              <p><strong>st_fips_2:</strong> {selectedMarine.properties.st_fips_2}</p>
              <p><strong>applicant:</strong> {selectedMarine.properties.applicant}</p>
              <p><strong>landside_r:</strong> {selectedMarine.properties.landside_r}</p>
              <p><strong>LINKNUM_1:</strong> {selectedMarine.properties.LINKNUM_1}</p>
              <p><strong>TOTALUP:</strong> {selectedMarine.properties.TOTALUP}</p>
              <p><strong>TOTALDOWN:</strong> {selectedMarine.properties.TOTALDOWN}</p>
              <p><strong>COALUP:</strong> {selectedMarine.properties.COALUP}</p>
              <p><strong>COALDOWN:</strong> {selectedMarine.properties.COALDOWN}</p>
              <p><strong>PETROLUP:</strong> {selectedMarine.properties.PETROLUP}</p>
              <p><strong>PETRODOWN:</strong> {selectedMarine.properties.PETRODOWN}</p>
              <p><strong>CHEMUP:</strong> {selectedMarine.properties.CHEMUP}</p>
              <p><strong>CHEMDOWN:</strong> {selectedMarine.properties.CHEMDOWN}</p>
              <p><strong>CRMATUP:</strong> {selectedMarine.properties.CRMATUP}</p>
              <p><strong>CRMATDOWN:</strong> {selectedMarine.properties.CRMATDOWN}</p>
              <p><strong>MANUUP:</strong> {selectedMarine.properties.MANUUP}</p>
              <p><strong>MANUDOWN:</strong> {selectedMarine.properties.MANUDOWN}</p>
              <p><strong>FARMUP:</strong> {selectedMarine.properties.FARMUP}</p>
              <p><strong>FARMDOWN:</strong> {selectedMarine.properties.FARMDOWN}</p>
              <p><strong>MACHUP:</strong> {selectedMarine.properties.MACHUP}</p>
              <p><strong>MACHDOWN:</strong> {selectedMarine.properties.MACHDOWN}</p>
              <p><strong>WASTEUP:</strong> {selectedMarine.properties.WASTEUP}</p>
              <p><strong>WASTEDOWN:</strong> {selectedMarine.properties.WASTEDOWN}</p>
              <p><strong>UNKWNUP:</strong> {selectedMarine.properties.UNKWNUP}</p>
              <p><strong>UNKWDOWN:</strong> {selectedMarine.properties.UNKWDOWN}</p>
              <p><strong>Shape_Length:</strong> {selectedMarine.properties.Shape_Length}</p>
              {/* Add other properties as needed */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )};

export default MarineComponent;
