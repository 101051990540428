import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from '../../context/message';
import { useAuth } from '../../context/auth';

const useStyles = makeStyles(theme => ({
  accessModes: {
    margin: theme.spacing(3),
    width: '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 10,
  }
}));

export default function AccessModeTable({ access_modes, storage_id, listing_id, inquiry_id }) {
  const classes = useStyles();
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [accessModes, setAccessModes] = useState(access_modes || []);

  useEffect(() => {
    if (storage_id) {
      fetch(`/api/storage/get_modes/${storage_id}`, {
        method: 'GET',
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        }
      })
      .then((response) => auth(response))
      .then((response) => {
        setAccessModes(response.data || []);
      })
      .catch((error) => {
        setMessage({type: 'error', text: 'Error! Unable to fetch Access Modes'})
        console.error('Error', error);
      })
    } else if (listing_id) {
      fetch(`/api/listing/get_modes/${listing_id}`, {
        method: 'GET',
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        }
      })
      .then((response) => auth(response))
      .then((response) => {
        setAccessModes(response.data || []);
      })
      .catch((error) => {
        setMessage({type: 'error', text: 'Error! Unable to fetch Access Modes'})
        console.error('Error', error);
      })
    } else if (inquiry_id) {
      fetch(`/api/inquiry/get_modes/${inquiry_id}`, {
        method: 'GET',
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        }
      })
      .then((response) => auth(response))
      .then((response) => {
        setAccessModes(response.data || []);
      })
      .catch((error) => {
        setMessage({type: 'error', text: 'Error! Unable to fetch Access Modes'})
        console.error('Error', error);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiry_id, setMessage])


  return (
    <TableContainer className={classes.accessModes} >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Access Mode Name</TableCell>
            <TableCell>Inbound / Outbound</TableCell>
            <TableCell>Access Type</TableCell>
            <TableCell>Transfer Fee</TableCell>
            <TableCell>Connection</TableCell>
            <TableCell>Dock Draft (ft)</TableCell>
            <TableCell>LOA (ft)</TableCell>
            <TableCell>Class One Rail</TableCell>
            <TableCell>Rail Spots</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accessModes.length !== 0 ?
            accessModes.map((mode, index) => (
            <TableRow key={index}>
              <TableCell>{mode.mode_name}</TableCell>
              <TableCell>{mode.inbound_outbound}</TableCell>
              <TableCell>{mode.type}</TableCell>
              <TableCell>{mode.pump_over_rate}</TableCell>
              <TableCell>{mode.connection_name}</TableCell>
              <TableCell>{mode.dock_draft}</TableCell>
              <TableCell>{mode.length_overall}</TableCell>
              <TableCell>{mode.class_one_rail}</TableCell>
              <TableCell>{mode.rail_spots}</TableCell>
            </TableRow>
          )) :
          <TableRow>
            <TableCell>No Data Available</TableCell>
          </TableRow>
        }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
