import React, {useState} from 'react'
import { useParams } from "react-router-dom";
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://thetanktiger.com/">
        The Tank Tiger
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 20,
    marginLeft: 10,
    marginRight:10,
    color: theme.palette.primary.main
  },
  text: {
    marginLeft: 20,
    minWidth: 300,
  },
  submit: {
    marginLeft: 20,
    marginTop: 20,
    minWidth: 100
  }
}));

export default function ChangePassword() {
  let { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('')
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const classes = useStyles()

  const handleSubmit = (event) => {
    // submit
    event.preventDefault();
    const form = new FormData(event.target);

    fetch(`/api/user/changePassword/${token}`, {
      method: 'PUT',
      body: form,
    })
    .then((response) => auth(response))
    .then((data) => {
      setMessage(data.message);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  return (

      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"

        >
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              The Tank Tiger
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Paper >
              <form onSubmit = {handleSubmit}>
              <Grid container spacing = {2}>
                <Grid item xs = {12}>
                  <Typography className = {classes.heading}>
                    Please confirm email and enter a new password
                  </Typography>
                </Grid>

                <Grid item xs = {12}>
                  <TextField
                    label='Email Address'
                    variant='outlined'
                    className = {classes.text}
                    value={email}
                    name = 'email'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item >
                  <TextField
                    label='New Password'
                    variant='outlined'
                    className = {classes.text}
                    value={password}
                    name = 'password'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item >
                  <TextField
                    label='Confirm Password'
                    variant='outlined'
                    className = {classes.text}
                    value={confirmPassword}
                    name = 'confirm'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing = {3}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type = 'submit'
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    href= '/'
                  >
                    Return to Login
                  </Button>
                </Grid>
              </Grid>
            </form>
            </Paper>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>

  )
}
