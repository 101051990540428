import React, { useState } from 'react';
import EditableTable from '../inputs/EditableTable';
import AdminMemberTable from './AdminMemberTable';

export default function TeamTable() {
  const [toggle, fetchData] = useState(false)

  const memberTable = (rowData) => {
    return (
      <AdminMemberTable team_id={rowData.team_id} />
    )
  }

  const columns = [
    {
      title: 'Team ID',
      field: 'team_id',
      editable: 'never',
    },
    {
      title: 'Team Name',
      field: 'team_name',
    },
    {
      title: 'Admin',
      field: 'username',
      editable: 'never',
    },
    {
      title: 'Admin ID',
      field: 'admin_id',
    },
    {
      title: 'Max User Count',
      field: 'max_users',
    }
  ];

  return (
    <EditableTable
      title='Teams'
      table='team'
      toggle = {toggle}
      fetchData = {fetchData}
      columns={columns}
      ownership='all'
      detailPanel = {memberTable}
    />
  );
}
