import React, { useState, useEffect } from 'react';
import EditableTable from '../inputs/EditableTable';
import AccessModeTable from '../display/AccessModeTable'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useField } from '../../context/context'
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import AddInquiry from './AddInquiry'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AlertDialog from '../inputs/AlertDialog'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'

export default function MyInquiryTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const { products, fetchFields, setFetchFields } = useField()
  const [product_lookup, setProduct_lookup] = useState({});
  const [inquiry_id, setInquiry_id] = useState(0);
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [open, setOpen] = useState(false)
  const [deleteDialog, setDelete] = useState(false)
  const [addInquiry, setAddInquiry] = useState(false)

  const handleCloseInfo = () => {
    setOpen(false)
    setInquiry_id(0)
  }

  useEffect(() => {
    if (products){
      let temp = {};
      products.map(item => temp[item.product] = item.product);
      setProduct_lookup(temp);
    } else {
      setFetchFields(!fetchFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const deleteInquiry = () => {
    fetch(`/api/inquiry/${inquiry_id}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        fetchData(!toggle)
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to delete inquiry.'})
      console.error('Error:', error);
    })
    setInquiry_id(0)
  }

  const accessModes = (rowData) => {
    return (
      <AccessModeTable inquiry_id={rowData.inquiry_id} />
    )
  }

  const columns = [
    {
      title: 'Inquiry ID',
      field: 'inquiry_id',
      editable: 'never'
    },
    {
      title: 'Location',
      field: 'formatted_address',
    },
    {
      title: 'Radius (mi)',
      field: 'radius',
      type: 'numeric',
      bounds: bounds.radius,
      customFilterAndSearch: rangeFilter('radius'),
    },
    {
      title: 'Product',
      field: 'product',
      lookup: product_lookup,
    },
    {
      title: 'Bid Price (USD/BBL)',
      field: 'bid_price',
      type: 'numeric',
      bounds: bounds.bid_price,
      customFilterAndSearch: rangeFilter('bid_price'),
    },
    {
      title: 'Min Volume (MBBL)',
      field: 'min_volume',
      type: 'numeric',
      bounds: bounds.min_volume,
      customFilterAndSearch: rangeFilter('min_volume'),
    },
    {
      title: 'Max Volume (MBBL)',
      field: 'max_volume',
      type: 'numeric',
      bounds: bounds.max_volume,
      customFilterAndSearch: rangeFilter('max_volume'),
    },
    {
      title: 'Term (Months)',
      field: 'term',
      type: 'numeric',
      bounds: bounds.term,
      customFilterAndSearch: rangeFilter('term'),
    },
    {
      title: 'Start Date',
      field: 'start_date',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('start_date'),
      customSort: (a,b) => {
        var date1 = new Date(a.start_date)
        var date2 = new Date(b.start_date)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.start_date)).toLocaleDateString()}</p>
    },
    {
      title: 'Date Requested',
      field: 'date_placed',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('date_placed'),
      render: rowData => <p>{(new Date(rowData.date_placed)).toLocaleDateString()}</p>
    },
    {
      title: 'Comment',
      field: 'comment',
      render: rowData => (
        rowData.comment && (rowData.comment.length > 30 ? rowData.comment.substring(0,30) + '...' : rowData.comment)
      )
    },
  ];

  return (
    <div>
      <AddInquiry
        open = {addInquiry}
        setOpen = {setAddInquiry}
        toggle = {toggle}
        fetchData = {fetchData}
      />
      <AlertDialog
        open = {deleteDialog}
        setOpen = {setDelete}
        title = 'Delete Inquiry'
        content = 'Are you sure you want to delete this inquiry?'
        submitText = 'Delete'
        submit = {deleteInquiry}
      />
      <InfoDialog
        open = {open}
        onClose = {handleCloseInfo}
        type = 'inquiry_info'
        id = {inquiry_id}
        disablePlaceBid = {true}
      />
      <EditableTable
        title='My Inquiries'
        table='inquiry'
        toggle = {toggle}
        fetchData = {fetchData}
        columns={columns}
        setBounds={setBounds}
        actions={[
          {
            icon: () => <AddBoxIcon color = 'primary'/>,
            tooltip: 'Add Inquiry',
            isFreeAction: 'true',
            onClick: (e, rowData)=> {
              setAddInquiry(true)
            }
          },
          {
            icon: () => <InfoIcon color = 'primary'/>,
            tooltip: 'More Info',
            onClick: (e, rowData) => {
              setInquiry_id(rowData.inquiry_id)
              setOpen(true)
            }
          },
          {
            icon: () => <DeleteOutlineIcon color = 'primary'/>,
            tooltip: 'Delete Inquiry',
            onClick: (e, rowData) => {
              setInquiry_id(rowData.inquiry_id)
              setDelete(true)
            }
          }
        ]}
        detailPanel={accessModes}
      />
    </div>
  );
}
