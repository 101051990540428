import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { useAuth } from "../../context/auth";
import TextField from "@material-ui/core/TextField"
import { useMessage } from "../../context/message";
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'

const useStyles = makeStyles((theme) => ({
  userInfo: {
    margin: theme.spacing(3),
    width: '85%',
    'border-style': 'solid',
    'border-color': theme.palette.primary.main,
    'border-radius': 10,
  },
}));

export default function TeamReceivedRenewals(){
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false)
  //info
  const [info, setInfo] = useState(false)
  const [id, setId] = useState(0)

  useEffect(() => {
    fetch('/api/renew/received/team', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch renewal requests.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage]);

  const handleClose = () => {
    setInfo(false)
    setId(0)
  }

  return (
    <div>
      <MaterialTable
        title = "Renewal Requests"
        columns = {[
          {
            'title': 'Username',
            'field':'receiver_username',
            defaultGroupOrder: 0
          },
          {
            'title' : 'Location',
            'field' : 'address'
          },
          {
            'title' : 'Price (USD/BBL)',
            'field' : 'price',
            'type' : 'numeric',
            'bounds': bounds.price,
            'customFilterAndSearch': rangeFilter('price'),
            'step': .01
          },
          {
            'title' : 'Product',
            'field' : 'product'
          },
          {
            'title' : 'Volume (MBBL)',
            'field' : 'volume',
            'type' : 'numeric',
            'bounds': bounds.volume,
            'customFilterAndSearch': rangeFilter('volume'),
            'step': 1
          },
          {
            'title' : 'Start Date',
            'field' : 'start_date',
            'type' : 'date',
            'customFilterAndSearch' : dateRangeFilter('start_date')
          },
          {
            'title' : 'Term (Months)',
            'field' : 'term',
            'type': 'numeric',
            'bounds': bounds.term,
            'customFilterAndSearch': rangeFilter('term')
          }
        ]}
        data = {data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        actions = {[{
          icon: () => <RefreshIcon color = 'primary'/>,
          tooltip: 'Refresh',
          isFreeAction: true,
          onClick: (event) => fetchData(!toggle)
        },
        {
          icon: () => <InfoIcon color = 'primary' />,
          tooltip: 'More Info',
          onClick: (event, rowData) => {
            setId(rowData.renewal_id)
            setInfo(true)
          }
        },
        ]}
        detailPanel = {
          rowData => {
            return(
            <Grid container spacing = {2} className = {classes.userInfo}>
              <Grid item xs>
                <TextField
                  id="First_Name"
                  label="First Name"
                  value = {rowData.renewer_first_name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="Last_Name"
                  label="Last Name"
                  value = {rowData.renewer_last_name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="Company"
                  label="Company"
                  value = {rowData.renewer_company || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item sm>
                <TextField
                  id="email"
                  label="Email"
                  value = {rowData.renewer_email || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="Office_Phone"
                  label="Office Phone"
                  value = {rowData.renewer_office_phone || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="Cell_Phone"
                  label="Cell Phone"
                  value = {rowData.renewer_cell_phone || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          )
          }
        }
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        options={{
          filtering: true,
          draggable: false,
          actionsColumnIndex: 0,
          grouping: true
        }}
        components={
          {
            Groupbar: () => null,
            FilterRow: props => <CustomFilter {...props} />
          }
        }
      />
      <InfoDialog
        open = {info}
        onClose = {handleClose}
        type = 'renewal'
        id = {id}
      />
    </div>
  )
}
