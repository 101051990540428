import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ListingMap from './ListingMap'
import GeoChart from './GeoChart'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import SimpleSelect from '../inputs/SimpleSelect';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { useField } from '../../context/context'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Legend from './Legend'
import DateRangeFilter from '../inputs/DateRangeFilter'
import { DataFrame } from 'pandas-js'
import { Button } from '@material-ui/core';
import AccessModeFilter from './AccessModeFilter'
import OverlayMenu from './OverlayMenu'

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(1)
  },
  tab: {
    backgroundColor: theme.palette.secondary.secondary,
    },
  tabSelect: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      textColor: theme.palette.secondary.main,
    },
  },
  paper: {
    'marginTop':theme.spacing(15),
  },
  slider: {
    marginTop: 10
  },
  header: {
    flexGrow: 1,
    backgroundColor: '#545454',
  },
  map: {
    marginBottom: theme.spacing(8),
  },
  button: {
    marginTop: 20
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
      </div>
  );
}

export default function Maps() {
  const classes = useStyles()
  const { setMessage } = useMessage();
  const { auth, overlayAccess } = useAuth();
  const { product_categories, fetchFields, setFetchFields } = useField()
  const [product_categories_list, setProduct_categories_list] = useState([]);
  const [listings, setListings] = useState([])
  const [inquirys, setInquiry] = useState([])
  const [map_product_category, setMapProductCategory] = useState('')
  const [map_price, setMapPrice] = useState([0,0])
  const [map_volume, setMapVolume] = useState([0,0])
  const [showListings, setShowListings] = useState(true)
  const [showInquirys, setShowInquirys] = useState(true)
  const [toggle, fetchData] = useState(false)
  const [listingBounds, setListingBounds] = useState({})
  const [inquiryBounds, setInquiryBounds] = useState({})
  const [dateRange, setDateRange] = useState({})
  const [accessModeFilters, setAccessModeFilters] = useState({
    'barge_in' : false,
    'barge_out' : false,
    'pipe_in' : false,
    'pipe_out' : false,
    'rail_in' : false,
    'rail_out' : false,
    'truck_in' : false,
    'truck_out' : false,
    'vessel_in' : false,
    'vessel_out' : false
  })
  const [listingDf, setListingDf] = useState()
  const [inquiryDf, setInquiryDf] = useState()
  //tabs
  const [value, setValue] = useState(0);
  const [response, setResponse] = useState({})
  const maxFilterPrice = 5
  const maxFilterVolume = 1000

  const [overlay, setOverlay] = useState({
    'crude': false,
    'refined': false,
    'padd': false,
    'product': false,
    'bnsf': false,
    'csx': false,
    'up': false,
    'ns': false,
    'marine': false,
    'docks': false,
    'intermodal': false
  })

  const [subOverlay, setSubOverlay] = useState({
    'truck': false,
    'rail': false,
    'water': false,
    'crude': false,
    'refined': false,
    'pet_chem': false,
    'ngl': false,
    'gasoline': false,
    'distillate': false,
    'biodiesel': false,
    'jetfuel': false,
    'ethanol': false
  })

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(product_categories){
      setProduct_categories_list(product_categories.map(function(item) {return {value: item.product_category, label: item.product_category}}))
    } else {
      setFetchFields(!fetchFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[product_categories])

  const accessModeCheck = (df) => {
    var dataframe = df.copy()
    if(accessModeFilters.barge_in){
      dataframe = dataframe.filter(dataframe.get('barge_in').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.barge_out){
      dataframe = dataframe.filter(dataframe.get('barge_out').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.pipe_in){
      dataframe = dataframe.filter(dataframe.get('pipe_in').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.pipe_out){
      dataframe = dataframe.filter(dataframe.get('pipe_out').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.rail_in){
      dataframe = dataframe.filter(dataframe.get('rail_in').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.rail_out){
      dataframe = dataframe.filter(dataframe.get('rail_out').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.truck_in){
      dataframe = dataframe.filter(dataframe.get('truck_in').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.truck_out){
      dataframe = dataframe.filter(dataframe.get('truck_out').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.vessel_in){
      dataframe = dataframe.filter(dataframe.get('vessel_in').where(1, (a,b) => a == b))
    }
    if(accessModeFilters.vessel_out){
      dataframe = dataframe.filter(dataframe.get('vessel_out').where(1, (a,b) => a == b))
    }
    return dataframe
  }

  useEffect(() => {
    if (listingDf){
      var newListingDf = listingDf.copy()
      //volume check
      newListingDf = newListingDf.filter(newListingDf.get('working_capacity').where({map_volume}, (a,b) => (a >= b.map_volume[0] && a <= b.map_volume[1])))
      //price check
      newListingDf = newListingDf.filter(newListingDf.get('ask_price').where({map_price}, (a,b) => ((a >= b.map_price[0] && a <= b.map_price[1]) || a == 'Undisclosed')))
      //product check
      if (map_product_category)
        newListingDf = newListingDf.filter(newListingDf.get('product_category').where(map_product_category, (a,b) => String(a).includes(String(b))))
      //date check
      if(dateRange.startDate) {
        newListingDf = newListingDf.filter(newListingDf.get('date_available').where(dateRange, (a,b) => (new Date(a) >= new Date(b.startDate) && (new Date(a) <= new Date(b.endDate)))))
      }
      //access mode check
      newListingDf = accessModeCheck(newListingDf)
      setListings(newListingDf.to_json({orient:'records'}))
    }
    if (inquiryDf){
      var newInquiryDf = inquiryDf.copy()
      //volume check - 1st. min before max after, 2. min in range, 3. max in range
      newInquiryDf = newInquiryDf.filter(newInquiryDf.get('max_volume').where(newInquiryDf.get('min_volume'), (a,b) => (
        ((b <= map_volume[0] && a >= map_volume[1]) || (b >= map_volume[0] && b <= map_volume[1]) || (a >= map_volume[0] && a <= map_volume[1]))
      )))
      //price check
      newInquiryDf = newInquiryDf.filter(newInquiryDf.get('bid_price').where({map_price}, (a,b) => (a >= b.map_price[0] && a <= b.map_price[1])))
      //product check
      if (map_product_category) {
        newInquiryDf = newInquiryDf.filter(newInquiryDf.get('product_category').where(map_product_category, (a,b) => String(a).includes(String(b))))
      }
      //date check
      if (dateRange.startDate){
        newInquiryDf = newInquiryDf.filter(newInquiryDf.get('start_date').where(dateRange, (a,b) => (new Date(a) >= new Date(b.startDate) && (new Date(a) <= new Date(b.endDate)))))
      }
      //access mode check
      newInquiryDf = accessModeCheck(newInquiryDf)
      setInquiry(newInquiryDf.to_json({orient:'records'}))
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[map_product_category, toggle, setMessage, dateRange, accessModeFilters]);

  useEffect(() => {
    fetch('/api/listing/map', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setResponse(response);
      setListings(response.listings || []);
      setListingBounds(response.listing_bounds || {});
      setListingDf(new DataFrame(response.listings))
      setInquiry(response.inquiries || []);
      setInquiryBounds(response.inquiry_bounds || {});
      setInquiryDf(new DataFrame(response.inquiries))
      if (response.map_settings) {
        setMapPrice([response.map_settings.min_price, response.map_settings.max_price])
        setMapVolume([response.map_settings.min_volume, response.map_settings.max_volume])
        setMapProductCategory(response.map_settings.product_category)
        setShowListings(Boolean(response.map_settings.listing))
        setShowInquirys(Boolean(response.map_settings.inquiry))
        setAccessModesSettings(response.map_settings)
      }
      else {
        setMapPrice([0, maxFilterPrice])
        setMapVolume([0, maxFilterVolume])
      }

    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch listings.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setMessage])

  const setAccessModesSettings = (map_settings) => {
    var temp = {
      'barge_in' : map_settings.barge_in,
      'barge_out' : map_settings.barge_out,
      'pipe_in' : map_settings.pipe_in,
      'pipe_out' : map_settings.pipe_out,
      'rail_in' : map_settings.rail_in,
      'rail_out' : map_settings.rail_out,
      'truck_in' : map_settings.truck_in,
      'truck_out' : map_settings.truck_out,
      'vessel_in' : map_settings.vessel_in,
      'vessel_out' : map_settings.vessel_out
    }
    setAccessModeFilters(temp)
  }

  const changePrice = (event, newValue) => {
    setMapPrice(newValue)
  }

  const changeVolume = (event, newValue) => {
    setMapVolume(newValue)
  }

  const clearFilters = () => {
    setMapProductCategory('')
    setMapPrice([0, maxFilterPrice])
    setMapVolume([0, maxFilterVolume])
    setShowListings(true)
    setShowInquirys(true)
    setDateRange({})
    setAccessModeFilters({
      'barge_in' : false,
      'barge_out' : false,
      'pipe_in' : false,
      'pipe_out' : false,
      'rail_in' : false,
      'rail_out' : false,
      'truck_in' : false,
      'truck_out' : false,
      'vessel_in' : false,
      'vessel_out' : false
    })
  }

  const saveFilters = () => {
    fetch('/api/preference/map', {
      method: 'POST',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({
        product_category: map_product_category,
        min_price: map_price[0],
        max_price: map_price[1],
        min_volume: map_volume[0],
        max_volume: map_volume[1],
        listing: showListings ? 1 : 0,
        inquiry: showInquirys ? 1 : 0,
        barge_in : accessModeFilters.barge_in ? 1 : 0,
        barge_out : accessModeFilters.barge_out ? 1 : 0,
        pipe_in : accessModeFilters.pipe_in ? 1 : 0,
        pipe_out : accessModeFilters.pipe_out ? 1 : 0,
        rail_in : accessModeFilters.rail_in ? 1 : 0,
        rail_out : accessModeFilters.rail_out ? 1 : 0,
        truck_in : accessModeFilters.truck_in ? 1 : 0,
        truck_out : accessModeFilters.truck_out ? 1 : 0,
        vessel_in : accessModeFilters.vessel_in ? 1 : 0,
        vessel_out : accessModeFilters.vessel_out ? 1 : 0
      })
    })
    .then(response => auth(response))
    .then(response => {
      setMessage(response.message);
    })
    .catch(error => {
      setMessage({type: 'error', text: 'Error! Unable to save map filters'});
      console.error('Error:', error);
    })
  }

  return (
    <div>
      <Paper className={classes.root}>
        <Box className={classes.table}>
          <Tabs
            indicatorColor = 'primary'
            textColor = 'primary'
            variant = 'fullWidth'
            onChange = {handleChange}
            value = {value}
            className = {classes.tab}
            >
              <Tab label = 'Volume Map' id = {0} className = {value!==0 ? classes.tabSelect : classes.tab}/>
              <Tab label = 'Heat Map' id = {1} className = {value!==1 ? classes.tabSelect : classes.tab}/>
            </Tabs>
          <TabPanel value={value} index={0}>
            <Grid container direction = 'row' spacing = {3}>
              <Grid item className = {classes.root}>
                <SimpleSelect value = {map_product_category} setValue={setMapProductCategory} label='Product Category' options={product_categories_list}/>
              </Grid>
              <Grid item className = {classes.slider}>
                <Typography>
                  Volume (MBBL)
                </Typography>
                <Slider
                  value = {map_volume}
                  onChange = {changeVolume}
                  onChangeCommitted = {() => fetchData(!toggle)}
                  valueLabelDisplay="auto"
                  className={classes.slider}
                  max = {maxFilterVolume}
                />
              </Grid>
              <Grid item className = {classes.slider}>
                <Typography>
                  Price ($/BBL)
                </Typography>
                <Slider
                  value = {map_price}
                  onChange = {changePrice}
                  onChangeCommitted = {() => fetchData(!toggle)}
                  valueLabelDisplay="auto"
                  className={classes.slider}
                  max = {maxFilterPrice}
                  step = {0.01}
                />
              </Grid>
              <Grid item className = {classes.root}>
                <Legend showInquirys = {showInquirys} showListings = {showListings} setShowInquirys = {setShowInquirys} setShowListings = {setShowListings}/>
              </Grid>
              <Grid item className = {classes.slider}>
                <Typography>
                  Date Range
                </Typography>
                <DateRangeFilter value = {dateRange} onChange = {setDateRange}/>
              </Grid>
              <Grid item>
                <AccessModeFilter filters = {accessModeFilters} setFilters = {setAccessModeFilters}/>
              </Grid>
              {overlayAccess === 1 &&
                <Grid item>
                  <OverlayMenu overlay={overlay} setOverlay= {setOverlay} subOverlay={subOverlay} setSubOverlay={setSubOverlay}/>
                </Grid>
              }
              <Grid item className = {classes.button}>
                <Button
                  variant = 'contained'
                  color='primary'
                  onClick={saveFilters}
                  >
                  Save Filters
                </Button>
              </Grid>
              <Grid item className = {classes.button}>
                <Button
                  variant = 'contained'
                  color='primary'
                  onClick={clearFilters}
                  >
                  Clear Filters
                </Button>
              </Grid>
            </Grid>
            <ListingMap listings = {listings} inquirys = {inquirys} showInquirys = {showInquirys} showListings = {showListings} overlay = {overlay} subOverlay = {subOverlay}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GeoChart/>
          </TabPanel>
        </Box>
      </Paper>
    </div>
  )
}
