import React, { useState, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import CustomFilter from '../CustomFilter';
import { rangeFilter, dateRangeFilter } from '../FilterFunctions';
import RefreshIcon from '@material-ui/icons/Refresh'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AlertDialog from '../inputs/AlertDialog'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import InfoIcon from '@material-ui/icons/Info'
import InfoDialog from '../display/InfoDialog'

export default function MyPlacedInquiryBidsTable() {
  const { setMessage } = useMessage();
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [bounds, setBounds] = useState({});
  const [toggle, fetchData] = useState(false);
  const [open, setOpen] = useState(false)
  const [inquiry_bids_id, setInquiryBidsId] = useState(0)
  const [cancel, setCancel] = useState(false)

  useEffect(() => {
    fetch('/api/inquiry_bids/placed', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setData(response.data || []);
      setBounds(response.bounds || {});
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to fetch placed bids.'})
      console.error('Error:', error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle, setMessage]);

  const columns = [
    {
      title: 'Inquiry Id',
      field: 'inquiry_id',
      editable: 'never',
    },
    {
      title: 'Formatted Address',
      field: 'formatted_address',
      editable: 'never',
    },
    {
      title: 'Bid Price (USD/BBL)',
      field: 'bid_price',
      type: 'numeric',
      editable: 'never',
      bounds: bounds.bid_price,
      customFilterAndSearch: rangeFilter('bid_price'),
    },
    {
      title: 'Ask Price (USD/BBL)',
      field: 'ask_price',
      type: 'numeric',
      bounds: bounds.ask_price,
      customFilterAndSearch: rangeFilter('ask_price'),
    },
    {
      title: 'Start Date',
      field: 'start_date',
      type: 'date',
      customFilterAndSearch: dateRangeFilter('date_available'),
      customSort: (a,b) => {
        var date1 = new Date(a.date_available)
        var date2 = new Date(b.date_available)

        return date1 - date2
      },
      render: rowData => <p>{(new Date(rowData.date_available)).toLocaleDateString()}</p>
    },
    {
      title: 'Volume (MBBL)',
      field: 'volume',
      bounds: bounds.volume,
      customFilterAndSearch: rangeFilter('volume'),
    },
    {
      title: 'Comment',
      field: 'offer_comment',
      render: rowData => (
        rowData.offer_comment && (rowData.offer_comment.length > 30 ? rowData.offer_comment.substring(0,30) + '...' : rowData.offer_comment)
      )
    }
  ];

  const cancelBid = () => {
    fetch(`/api/inquiry_bids/${inquiry_bids_id}/cancel`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to cancel bid.'})
      console.error('Error:', error);
    })
  }

  const handleCloseInfo = () => {
    setOpen(false)
    setInquiryBidsId(0)
  }

  return (
    <div>
      <InfoDialog
        open = {open}
        onClose = {handleCloseInfo}
        type = 'inquiry'
        id = {inquiry_bids_id}
        disablePlaceBid = {true}
      />
      <AlertDialog
        open = {cancel}
        setOpen = {setCancel}
        title = 'Delete Bid'
        content = 'Are you sure you want to delete this bid?'
        submitText = 'Delete'
        submit = {cancelBid}
      />
      <MaterialTable
        title = 'Your Bids on Inquiries'
        columns = {columns}
        data = {data}
        icons={{Export: forwardRef((props, ref) => <VerticalAlignBottomIcon color = 'primary' {...props} ref={ref}/>)}}
        options = {{
          filtering: true,
          draggable: false,
          actionsColumnIndex: 0
        }}
        components = {{
          FilterRow: props => <CustomFilter {...props} />
        }}
        actions={[{
          icon: () => <RefreshIcon color = 'primary'/>,
          tooltip: 'Refresh',
          isFreeAction: true,
          onClick: (event) => fetchData(!toggle)
        },
        {
          icon: () => <InfoIcon color = 'primary'/>,
          tooltip: 'More Info',
          onClick: (e, rowData) => {
            setInquiryBidsId(rowData.inquiry_bids_id)
            setOpen(true)
          }
        },
        {
          icon: () => <DeleteOutlineIcon color = 'primary'/>,
          tooltip: 'Cancel Bid',
          onClick: (event,rowData) => {
            setInquiryBidsId(rowData.inquiry_bids_id)
            setCancel(true)
          }
        }
        ]}
      />
    </div>

  );
}
