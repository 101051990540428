import React, { useState, useEffect } from 'react'
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { useField } from '../../context/context'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    width: 300
  },
  label: {
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(1.5)
  },
  heading: {
    fontSize: 16
  },
  select: {
    width: 300
  },
  button: {
    margin: theme.spacing(2),
  },
  remove: {
    marginTop: theme.spacing(2)
  },
  textfield: {
    width: 300
  }
}));

export default function BidAskForm({open, setOpen, bid_ask_id, setBid_ask_id, toggle, fetchData}) {
  const classes = useStyles()
  const { auth } = useAuth()
  const { setMessage } = useMessage()
  const { product_categories, regions } = useField()
  const [selectedProducts, setSelectedProducts] = useState([])
  const [bid, setBid] = useState({
      'region': '',
      'product_category': '',
      'price': '',
      'volume': '',
      'term': '',
      'bid_ask': '',
      'start_date': Date(0)
  })

  useEffect(() => {
    //set user for editing
    if (open){
      if (bid_ask_id !== 0)
      {
        //fetch user
        fetch(`/api/bid_ask/${bid_ask_id}`, {
          method: 'GET',
          headers: {
            'x-access-token': sessionStorage.getItem('token')
          }
        })
        .then((response) => auth(response))
        .then((response) => {
          setBid(response.data)
        })
        .catch((error) => {
          setMessage({'type': 'error', 'text': 'Error! Unable to fetch bid details.'})
          handleClose()
          console.error('Error:', error);
        })
      }
    }
  }, [bid_ask_id, open])

  const submit = () => {
    if (bid_ask_id === 0){
      //add
      fetch(`/api/bid_ask`, {
        method: 'POST',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bid)
      })
      .then(response => auth(response))
      .then(response => {
        setMessage(response.message);
        if (response.message.type === 'success') {
          handleClose();
        }
        fetchData(!toggle)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to create bid'});
        console.error('Error:', error);
      })
    } else {
      //edit
      fetch(`/api/bid_ask/${bid_ask_id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bid)
      })
      .then(response => auth(response))
      .then(response => {
        if (response.message.type === 'success') {
          handleClose();
        }
        setMessage(response.message);
        fetchData(!toggle)
      })
      .catch(error => {
        setMessage({type: 'error', text: 'Error! Unable to update bid'});
        console.error('Error:', error);
      })
    }
  }


  const handleClose = () => {
    setOpen(false)
    if (bid_ask_id !== 0)
      setBid_ask_id(0)
    setBid({
        'region': '',
        'product_category': '',
        'price': '',
        'volume': '',
        'term': '',
        'bid_ask': '',
        'start_date': Date(0)
    })
    setSelectedProducts([])

  }

  const updateField = e => {
    setBid({
      ...bid,
      [e.target.name]: e.target.value
    });
  };

  const updateProduct = (e) => {
    setSelectedProducts(e.target.value);
    setBid({
      ...bid,
      product_category: e.target.value.join(', ')
    });
  }

  return(
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='lg'
    >
      <DialogTitle>
        {bid_ask_id !== 0 ? 'Edit Bid' : 'Enter Bid'}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item className={classes.root}>
            <FormControl>
              <InputLabel className={classes.label}>Region</InputLabel>
              <Select
                className={classes.select}
                value = {bid.region}
                onChange = {updateField}
                name = 'region'
                label = 'Region'
                variant="outlined"
              >
                {regions && regions.map((item) =>
                  <MenuItem key = {item.region} value = {item.region}> {item.region} </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
            <Grid item className={classes.root}>
              <FormControl>
                <InputLabel className={classes.label}>Product Category</InputLabel>
                <Select
                  className={classes.select}
                  multiple
                  value={selectedProducts}
                  onChange={updateProduct}
                  renderValue={(selectedProducts) => (Array.isArray(selectedProducts) && selectedProducts.join(', '))}
                  variant="outlined"
                  label = 'Product Category'
                >
                  {product_categories && product_categories.map((item) => (
                    <MenuItem key={item.product_category} value={item.product_category}>
                      <Checkbox checked = {selectedProducts.indexOf(item.product_category) > -1} />
                      {item.product_category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'price'
                label = 'Price'
                value =  {bid.price}
                onChange={updateField}
                variant="outlined"
                type='number'
              />
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'volume'
                label = 'Volume'
                value =  {bid.volume}
                onChange={updateField}
                variant="outlined"
                type='number'
              />
            </Grid>
            <Grid item className={classes.root}>
              <TextField
                required
                className={classes.textfield}
                name = 'term'
                label = 'Term'
                value =  {bid.term}
                onChange={updateField}
                variant="outlined"
                type='number'
              />
            </Grid>
            <Grid item className = {classes.root}>
              <FormControl>
                <InputLabel className={classes.label}>Bid/Ask</InputLabel>
                <Select
                  className = {classes.select}
                  value = {bid.bid_ask}
                  onChange = {updateField}
                  name = 'bid_ask'
                  label = 'Bid/Ask'
                  variant = 'outlined'
                >
                  <MenuItem value = 'bid'> Bid </MenuItem>
                  <MenuItem value = 'ask'> Ask </MenuItem>
                  <MenuItem value = 'trade'> Trade </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className = {classes.root}
                format='MM/dd/yyyy'
                margin = "normal"
                label = "Start Date"
                name = "date_available"
                value = {bid.start_date}
                onChange = {(long, short) => setBid({...bid, start_date: short})}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputVariant = 'outlined'
              />
            </MuiPickersUtilsProvider>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick = {submit} color = 'primary'>
          {bid_ask_id !== 0 ? 'Update' : 'Add'}
        </Button>
        <Button
          onClick = {handleClose}
          color = 'primary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>

  )
}
