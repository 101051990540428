import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useAuth } from "../../context/auth";
import { Link } from 'react-router-dom';

export default function TutorialScreen () {
  const { auth, tutorialStep, setTutorialStep } = useAuth()
  const [checked, setChecked] = useState(false)

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  const handleClose = () => {
    setTutorialStep(0)
    if (checked) {
      fetch('/api/user/tutorial/finish', {
        method: 'PUT',
        headers: {
          'x-access-token': sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
      })
      .then((response) => auth(response))
      .then((response) => {

      })
      .catch((error) => {
        console.error('Error:', error);
      })
    }
  }

  const beginTutorial = () => {
    setTutorialStep(2)
  }

  return (
    <div>
      <Dialog open = {tutorialStep === 1}>
        <DialogTitle>
          Welcome to The Tank Tiger Platform
        </DialogTitle>
        <DialogContent>
          {/* improve content*/}
          <Typography>
            Welcome to the Tank Tiger Platform, an online marketplace for storage transactions. Would you like to take a quick tour through the platform?
          </Typography>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            style = {{position: 'relative', left: 10}}
            control = {
              <Checkbox
                checked={checked}
                onChange={handleChange}
                color='primary'
              />
            }
            label = {(<Typography variant='button'>Don't ask again</Typography>)}
          />
          <div style = {{flex: '1 0 0'}}/>
          <Button onClick={handleClose}>
            Not now
          </Button>
          <Button variant = 'contained' color = 'primary' onClick={beginTutorial} component={Link} to='/'>
            Get Started
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
